import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInactiveFor } from "@app/app.slice";
import { useLoginMutation, useLogoutMutation, useRefreshMutation } from "@app/services";
import {
    useDeleteAccountMutation,
    useLazyGetUserDetailsQuery,
    useUpdateAccountMutation
} from "@app/services/modules/user";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import { deleteUser, setEbayState, setLoggedOut, setUser, setUserEmail } from "./user.slice";
import { useLazyGetEbayIntegratedQuery } from "@app/services/formsAPI";

const AuthenticationContext = createContext();

export function useAuthentication() {
    return useContext(AuthenticationContext);
}

export function AuthenticationContextProvider({ children }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const eBayIntegrated = useSelector((state) => state.user.ebayIntegrated);
    const [authenticated, setAuthenticated] = useState(Boolean(user.token));
    const [checkEbayIntegration] = useLazyGetEbayIntegratedQuery();
    const [triggerLogin] = useLoginMutation();
    const [triggerLogout] = useLogoutMutation();
    const [triggerRefresh] = useRefreshMutation();
    const [
        fetchDetails,
        {
            data: detailData,
            isError: isUnauthorized,
            isSuccess: isAuthorized,
            isFetching: isAuthorizing
        }
    ] = useLazyGetUserDetailsQuery();
    const [triggerUpdate, { error: updateError, isSuccess: accountUpdated }] =
        useUpdateAccountMutation();
    const [triggerDelete, { isSuccess: accountDeleted }] = useDeleteAccountMutation();
    const [details, setDetails] = useState(null);

    const logout = useCallback(
        async (message) => {
            await triggerLogout();
            setAuthenticated(false);
            dispatch(
                setLoggedOut({
                    loggedOut: true
                })
            );
            dispatch(deleteUser());
            if (message && typeof message === "string") {
                dispatch(
                    showSnackbarAlert({
                        message,
                        severity: "info"
                    })
                );
            }
            setDetails(null);
        },
        [dispatch, triggerLogout]
    );

    const login = useCallback(
        (email, password, message = "Logged in successfully") => {
            triggerLogin({ email, password }).then((res) => {
                if (res && res.data && res.data.token) {
                    setAuthenticated(true);
                    dispatch(
                        setLoggedOut({
                            loggedOut: false
                        })
                    );
                    dispatch(setUser(res.data));
                    dispatch(setUserEmail({ email }));
                    dispatch(
                        showSnackbarAlert({
                            message,
                            severity: "success"
                        })
                    );

                    checkEbayIntegration({ token: res.data.token })
                        .unwrap()
                        .then((data) => {
                            dispatch(setEbayState({ ebayIntegrated: data.isActive }));
                        });
                } else {
                    dispatch(
                        showSnackbarAlert({
                            message: "Your email or password is incorrect",
                            severity: "error"
                        })
                    );
                }
            });
        },
        [dispatch, triggerLogin, checkEbayIntegration]
    );

    const refresh = useCallback(async () => {
        await triggerRefresh().then((res) => {
            console.log("refresh token response", res);
            if (res && res.data) {
                setAuthenticated(true);
                dispatch(setUser(res.data));
            }
        });
    }, [dispatch, triggerRefresh]);

    const deleteAccount = useCallback(() => {
        triggerDelete();
    }, [triggerDelete]);

    const updateAccount = useCallback(
        ({ name, email, ...additionalData }) => {
            triggerUpdate({ name, email, ...additionalData });
        },
        [triggerUpdate]
    );

    useEffect(() => {
        let sessionTimerId;
        let counter;
        const resetTimer = async () => {
            if (sessionTimerId) {
                clearInterval(sessionTimerId);
            }
            counter = 0;
            dispatch(setInactiveFor({ seconds: counter }));
            sessionTimerId = setInterval(() => {
                dispatch(setInactiveFor({ seconds: counter++ }));
            }, 1000);
        };
        if (authenticated && !sessionTimerId) {
            console.log("creating user activity listeners");
            window.addEventListener("mousemove", resetTimer);
            window.addEventListener("scroll", resetTimer);
            window.addEventListener("keydown", resetTimer);
            window.addEventListener("resize", resetTimer);
            resetTimer();
        }
        return () => {
            console.log("disposing user activity listeners");
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("scroll", resetTimer);
            window.removeEventListener("keydown", resetTimer);
            window.removeEventListener("resize", resetTimer);
            clearInterval(sessionTimerId);
        };
    }, [dispatch, authenticated]);

    useEffect(() => {
        if (accountUpdated) {
            dispatch(
                showSnackbarAlert({
                    message: "Account was updated",
                    severity: "success"
                })
            );
        }
    }, [dispatch, accountUpdated]);

    useEffect(() => {
        const error = updateError;
        if (error) {
            console.log("error occured", error);
            let message;
            switch (error.status) {
                case "FETCH_ERROR":
                    message = "Sorry, we are having technically difficulties";
                    break;
                default:
                    message = error.error;
                    break;
            }
            if (error.data) {
                message = error.data.message;
            }
            if (typeof error.data === "string") {
                message = error.data;
            }
            dispatch(
                showSnackbarAlert({
                    message,
                    severity: "error"
                })
            );
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (accountDeleted) {
            logout("Account deleted");
        }
    }, [accountDeleted, logout]);

    useEffect(() => {
        async function login() {
            if (user && user.token) {
                await fetchDetails();
                checkEbayIntegration({token: user.token})
                    .unwrap()
                    .then((data) => {
                        console.log(data)
                        dispatch(setEbayState({ebayIntegrated: data.isActive}));
                    });
                setAuthenticated(true);
            }
        }
        login();
    }, [fetchDetails, user, checkEbayIntegration]);

    useEffect(() => {
        if (isAuthorized) {
            setDetails({ ...(detailData || {}), eBayIntegrated });
        }
    }, [setDetails, isAuthorized, isAuthorizing, detailData, eBayIntegrated]);

    useEffect(() => {
        if (authenticated && isUnauthorized) {
            logout("Authorization has expired");
        }
    }, [logout, authenticated, isUnauthorized]);

    return (
        <AuthenticationContext.Provider
            value={{
                login,
                logout,
                refresh,
                deleteAccount,
                updateAccount,
                authenticated,
                preventRefresh: user.loggedOut,
                details
            }}>
            {children}
        </AuthenticationContext.Provider>
    );
}
