import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    account: null
};

export const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setAccount: (state, { payload }) => {
            console.log("set wallet state", payload);
            state.account = payload;
        },
        deleteAccount: (state) => {
            state.account = initialState.account;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setAccount } = walletSlice.actions;

export default walletSlice.reducer;
