import React from "react";
import { Card } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
    root: {
        background: "#F5F5F5",
        borderRadius: "25px",
        boxShadow: "none",
        padding: "56px 40px 48px 40px",

        [theme.breakpoints.down("md")]: {
            borderRadius: "0px",
            padding: "3px"
        }
    }
});
// Head of a MUI Card with embedded media and floating ID indicator
function Curvy({ children, classes, ...rest }) {
    return (
        <Card classes={{ root: classes.root }} {...rest}>
            {children}
        </Card>
    );
}

export default withStyles(styles)(Curvy);
