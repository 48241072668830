import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Web3 from "web3";
import config from "@app/config";
import { useGetNetworksQuery } from "@app/services";
import { useInterval } from "../hooks/useInterval";

const Web3Context = createContext();

export function useWeb3() {
    return useContext(Web3Context);
}

const ganacheLocalDevelopmentNetwork = {
    name: "Ganache Dev",
    chain: "ETH",
    icon: "ethereum",
    explorers: [
        {
            name: "etherscan",
            url: "https://etherscan.io",
            standard: "EIP3091"
        }
    ]
};

export function Web3Provider({ children }) {
    const [web3Instance, setWeb3Instance] = useState(); // Web3 instance.
    const [account, setAccount] = useState(); // The current authorized & selected account.
    const [balance, setBalance] = useState(); // Balance of the connected wallet.
    const [chainId, setChainId] = useState(); // Wallets network/chain ID.
    const [network, setNetwork] = useState(); // Current network loaded from chainlist.
    const { data } = useGetNetworksQuery();

    useEffect(() => {
        if (data && chainId) {
            console.log("network id:", chainId);
            setNetwork(data.find((n) => n.networkId === chainId) || ganacheLocalDevelopmentNetwork);
        }
    }, [data, chainId]);

    useEffect(() => {
        const provider = config.web3.staticNetworkRPC
            ? new Web3.providers.WebsocketProvider(config.web3.staticNetworkRPC)
            : Web3.givenProvider || config.web3.defaultNetworkRPC;
        console.log({ provider });

        const web3 = new Web3(provider);
        setWeb3Instance(web3);

        if (!config.web3.staticNetworkRPC) {
            // Detect accounts that have already been authorized..
            web3.eth.getAccounts().then((accounts) => {
                console.log("detected authorized accounts", accounts);
                if (accounts.length > 0) {
                    setAccount(accounts[0].toLowerCase());
                }
            });
        }

        // Detect current network.
        web3.eth.net.getId().then((chainId) => {
            console.log("detected current network", chainId);
            setChainId(chainId);
        });

        const onAccountChanged = (accounts) => {
            console.log("account changed", accounts);
            if (accounts.length > 0) {
                setAccount(accounts[0].toLowerCase());
            } else {
                setAccount();
                setBalance();
            }
        };
        const onChainChanged = (chainId) => {
            const chainIdToNumber = Web3.utils.hexToNumber(chainId);
            console.log("chain changed", chainIdToNumber);
            setChainId(chainIdToNumber);
        };
        const pageLoaded = () => {
            if (typeof onAccountChanged === "function") {
                window.ethereum.on("accountsChanged", onAccountChanged);
            }
            if (typeof onChainChanged === "function") {
                window.ethereum.on("chainChanged", onChainChanged);
            }
        };
        if (window.ethereum) {
            window.addEventListener("load", pageLoaded);
        }
        return () => {
            console.log("disposing listeners - etherium: load, accountsChanged");
            window.removeEventListener("load", pageLoaded);
            window.removeEventListener("accountsChanged", pageLoaded);
            window.removeEventListener("networkChanged", pageLoaded);
        };
    }, [setWeb3Instance]);

    const balancePolling = useCallback(() => {
        if (account) {
            web3Instance.eth
                .getBalance(account)
                .then((balance) => Web3.utils.fromWei(balance, "ether"))
                .then((ether) => {
                    if (balance !== ether) setBalance(ether);
                });
        }
    }, [web3Instance, account, balance]);

    useInterval(balancePolling, 5000);

    const requestAuthorize = useCallback(() => {
        if (!web3Instance) return Promise.reject("no web3 provider");
        return web3Instance.eth.requestAccounts();
    }, [web3Instance]);

    const state = useMemo(() => {
        return { requestAuthorize, web3: web3Instance, account, balance, chainId, network };
    }, [requestAuthorize, web3Instance, account, balance, chainId, network]);

    return <Web3Context.Provider value={state}>{children}</Web3Context.Provider>;
}
