import React from "react";
import { Wrappers } from "@features/components";
import CardList from "./card-list/cardList";
import StepList from "./step-list/stepList";
import LandingFooter from "./landingFooter";
import CollabsibleRow from "@features/components/tables/CollabsibleRow/CollabsibleRow";
import TableDetails from "../card/tableDetails";
import { Box } from "@mui/material";
import HeroSectionInstantCash from "./heroSectionInstantCash";

const IndexInstantCashPage = () => {
    return (
        <Box>
            <Wrappers.Container>
                <HeroSectionInstantCash />
                <CardList />
                <StepList />
                {/* <LandingFooter /> */}
            </Wrappers.Container>
        </Box>
    );
};

export default IndexInstantCashPage;
