import React, { useCallback, useEffect, useState } from "react";
import { Drawer, Box, Toolbar, AppBar, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@features/components/text/typography";
import { Wrappers } from "@features/components";
import { ReactComponent as FilterIcon } from "./img/filterIcon.svg";
import FilterSections from "./filterSections";
import { useSelector } from "react-redux";

function FilterBar() {
    const [draw, setDraw] = useState(false);
    const filteredItemsQuantity = useSelector((state) => state.filter.filteredItemsQuantity);

    const preventScroll = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (draw) {
            document.addEventListener("scroll", preventScroll, { passive: false });
        } else {
            document.removeEventListener("scroll", preventScroll, { passive: false });
        }
    }, [draw]);

    return (
        <Wrappers.LoadingBackdrop loaderFixedToScreen>
            <Box sx={{ flexGrow: 1, mb: 9 }}>
                <AppBar
                    color='background'
                    sx={{
                        boxShadow: "none",
                        position: "inherit",
                        height: "75px",
                        borderBottom: "1px solid #ddd",
                        padding: "16px 24px",
                        display: {
                            xs: "block",
                            md: "none"
                        }
                    }}
                >
                    <Box>
                        <Typography variant='h5' sx={{ color: "#757575" }}>
                            Search results for
                        </Typography>
                        <Typography variant='h4'>search term ({filteredItemsQuantity})</Typography>
                    </Box>
                </AppBar>
                <AppBar
                    color='background'
                    sx={{
                        boxShadow: "none",
                        position: "inherit",
                        height: {
                            xs: "56px",
                            md: "88px"
                        },
                        borderBottom: "1px solid #ddd"
                    }}
                >
                    <Toolbar
                        sx={{
                            height: "100%",
                            justifyContent: "space-between",
                            mx: { xs: 1, md: 3 }
                        }}
                    >
                        <Stack
                            sx={{
                                display: {
                                    xs: "none",
                                    md: "block"
                                }
                            }}
                        >
                            <Box>
                                <Typography variant='h5' sx={{ color: "#757575" }}>
                                    Search results for
                                </Typography>
                                <Typography variant='h4'>
                                    search term ({filteredItemsQuantity})
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "fit-content"
                                }
                            }}
                        >
                            <Box
                                display='flex'
                                flexDirection='row'
                                sx={{
                                    width: {
                                        xs: "100%",
                                        md: "fit-content"
                                    },
                                    justifyContent: "space-between"
                                }}
                            >
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    flexDirection='row'
                                    sx={{
                                        cursor: "pointer",
                                        mr: 3.5
                                    }}
                                >
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            mr: 1.5
                                        }}
                                    >
                                        Sort
                                    </Typography>
                                    <KeyboardArrowDownIcon fontSize='medium' />
                                </Box>
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    flexDirection='row'
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setDraw(!draw)}
                                >
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            mr: 1.5
                                        }}
                                    >
                                        {draw ? "Close" : "Filter"}
                                    </Typography>
                                    <FilterIcon />
                                </Box>
                            </Box>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Drawer
                    anchor='left'
                    variant='persistent'
                    open={draw}
                    onClose={() => setDraw(false)}
                    sx={{
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            boxSizing: "border-box",
                            width: 392,
                            maxWidth: "100vw",
                            top: { xs: "224px", md: 0 }
                        }
                    }}
                >
                    <FilterSections />
                </Drawer>
            </Box>
        </Wrappers.LoadingBackdrop>
    );
}

export default FilterBar;
