import config from "../config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: config.services.golang.baseurl,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const user = getState().user;
        // Add authorization header to the relevant endpoints
        if (
            user &&
            user?.token &&
            ["burnLazyToken", "addListing", "buyListing", "deleteListing"].includes(endpoint)
        ) {
            headers.set("Authorization", `Bearer ${user.token}`);
        }
        // Add a header used to set the base return address on the authentication server.
        // Only add X-Email-Return-Address when using a route that send emails, or may send one in the future.
        if (["lazyMintToken", "buyListing"].includes(endpoint)) {
            headers.set("x-basename", config.server.basePath);
        }
        return headers;
    }
});

export default createApi({
    reducerPath: "golangAPI",
    tagTypes: ["ClaimedTokens", "BlacklistedTokens", "LazyTokens", "LazyListings"],
    baseQuery,
    endpoints: () => ({})
});
