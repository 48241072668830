import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useWeb3 } from "../web3";
import FVToken from "../../contracts/FVToken.json";
import { useDispatch } from "react-redux";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import config from "@app/config";

const FVTokenContext = createContext();

export function useFVToken() {
    return useContext(FVTokenContext);
}

export function FVTokenContractProvider({ children }) {
    const dispatch = useDispatch();
    const { web3, account, network } = useWeb3();
    const [contract, setContract] = useState();

    useEffect(() => {
        if (network) {
            console.log("FVToken getting deployed network");
            let deployedNetwork = FVToken.networks[config.web3.defaultChainID];
            deployedNetwork.address = config.web3.contracts.fvtoken.address;
            if (!deployedNetwork) {
                setContract();
                dispatch(
                    showSnackbarAlert({
                        message: `contract is not available on ${network.title}`,
                        severity: "error"
                    })
                );
                return;
            }
            try {
                console.log("FVToken setting contract instance");
                const contractInstance = new web3.eth.Contract(
                    FVToken.abi,
                    deployedNetwork && deployedNetwork.address,
                    {
                        from: account
                    }
                );
                setContract(contractInstance);
            } catch (e) {
                dispatch(
                    showSnackbarAlert({
                        message: "Failed to initialize contract",
                        severity: "error"
                    })
                );
            }
        }
    }, [network, web3, account, dispatch]);

    const approve = useCallback(
        (to, tokenId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["approve"](to, tokenId).send({
                from: account
            });
        },
        [contract, account]
    );

    const renounceOwnership = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["renounceOwnership"]().send({
            from: account
        });
    }, [contract, account]);

    const safeTransferFrom = useCallback(
        (from, to, tokenId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["safeTransferFrom"](from, to, tokenId).send({
                from: account
            });
        },
        [contract, account]
    );

    const setApprovalForAll = useCallback(
        (operator, approved) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["setApprovalForAll"](operator, approved).send({
                from: account
            });
        },
        [contract, account]
    );

    const transferFrom = useCallback(
        (from, to, tokenId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["transferFrom"](from, to, tokenId).send({
                from: account
            });
        },
        [contract, account]
    );

    const transferOwnership = useCallback(
        (newOwner) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["transferOwnership"](newOwner).send({
                from: account
            });
        },
        [contract, account]
    );

    const mintToken = useCallback(
        (tokenURI) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["mintToken"](tokenURI).send({
                from: account
            });
        },
        [contract, account]
    );

    const mintTokenFor = useCallback(
        (wallet, tokenURI) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["mintTokenFor"](wallet, tokenURI).send({
                from: account
            });
        },
        [contract, account]
    );

    const addTokenRoyalty = useCallback(
        (tokenId, reciever, feeNumerator) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["addTokenRoyalty"](tokenId, reciever, feeNumerator).send({
                from: account
            });
        },
        [contract, account]
    );

    const updateTokenRoyalty = useCallback(
        (tokenId, reciever, feeNumerator) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["updateTokenRoyalty"](tokenId, reciever, feeNumerator).send({
                from: account
            });
        },
        [contract, account]
    );

    const updateToken = useCallback(
        (tokenId, tokenURI) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["updateToken"](tokenId, tokenURI).send({
                from: account
            });
        },
        [contract, account]
    );

    const burnToken = useCallback(
        (tokenId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["burnToken"](tokenId).send({
                from: account
            });
        },
        [contract, account]
    );

    const approveDefaultMarketPlace = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["approveDefaultMarketPlace"]().send({
            from: account
        });
    }, [contract, account]);

    const pause = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["pause"]().send({
            from: account
        });
    }, [contract, account]);

    const unpause = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["unpause"]().send({
            from: account
        });
    }, [contract, account]);

    const balanceOf = useCallback(
        (owner) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["balanceOf"](owner).call();
        },
        [contract]
    );

    const getApproved = useCallback(
        (tokenId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["getApproved"](tokenId).call();
        },
        [contract]
    );

    const isApprovedForAll = useCallback(
        (owner, operator) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["isApprovedForAll"](owner, operator).call();
        },
        [contract]
    );

    const name = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["name"]().call();
    }, [contract]);

    const owner = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["owner"]().call();
    }, [contract]);

    const ownerOf = useCallback(
        (tokenId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["ownerOf"](tokenId).call();
        },
        [contract]
    );

    const paused = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["paused"]().call();
    }, [contract]);

    const royaltyInfo = useCallback(
        (_tokenId, _salePrice) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["royaltyInfo"](_tokenId, _salePrice).call();
        },
        [contract]
    );

    const symbol = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["symbol"]().call();
    }, [contract]);

    const tokenURI = useCallback(
        (tokenId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["tokenURI"](tokenId).call();
        },
        [contract]
    );

    const getTokenIds = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["getTokenIds"]().call();
    }, [contract]);

    const listTokens = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["listTokens"]().call();
    }, [contract]);

    const getDefaultMarketplaceAddress = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["getDefaultMarketplaceAddress"]().call();
    }, [contract]);

    const isDefaultMarketApproved = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["isDefaultMarketApproved"]().call();
    }, [contract]);

    const supportsInterface = useCallback(
        (interfaceId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["supportsInterface"](interfaceId).call();
        },
        [contract]
    );

    const address = useMemo(() => {
        return contract !== undefined ? contract._address : null;
    }, [contract]);

    const hasContract = useMemo(() => {
        return contract !== undefined;
    }, [contract]);

    return (
        <FVTokenContext.Provider
            value={{
                approve,
                renounceOwnership,
                safeTransferFrom,
                setApprovalForAll,
                transferFrom,
                transferOwnership,
                mintToken,
                mintTokenFor,
                addTokenRoyalty,
                updateTokenRoyalty,
                updateToken,
                burnToken,
                approveDefaultMarketPlace,
                pause,
                unpause,
                balanceOf,
                getApproved,
                isApprovedForAll,
                name,
                owner,
                ownerOf,
                paused,
                royaltyInfo,
                symbol,
                tokenURI,
                getTokenIds,
                listTokens,
                getDefaultMarketplaceAddress,
                isDefaultMarketApproved,
                supportsInterface,
                contract,
                address,
                hasContract
            }}
        >
            {children}
        </FVTokenContext.Provider>
    );
}

export function useApprovalEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.Approval event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("Approval", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic Approval", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["Approval"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.Approval", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.Approval events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useApprovalForAllEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.ApprovalForAll event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("ApprovalForAll", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic ApprovalForAll", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["ApprovalForAll"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.ApprovalForAll", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.ApprovalForAll events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useOwnershipTransferredEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.OwnershipTransferred event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("OwnershipTransferred", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic OwnershipTransferred", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["OwnershipTransferred"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.OwnershipTransferred", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.OwnershipTransferred events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function usePausedEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.Paused event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("Paused", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic Paused", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["Paused"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.Paused", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.Paused events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useTokenBurnedEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.TokenBurned event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("TokenBurned", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic TokenBurned", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["TokenBurned"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.TokenBurned", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.TokenBurned events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useTokenCreatedEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.TokenCreated event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("TokenCreated", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic TokenCreated", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["TokenCreated"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.TokenCreated", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.TokenCreated events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useTokenUpdatedEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.TokenUpdated event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("TokenUpdated", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic TokenUpdated", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["TokenUpdated"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.TokenUpdated", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.TokenUpdated events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useTransferEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.Transfer event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("Transfer", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic Transfer", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["Transfer"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.Transfer", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.Transfer events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useUnpausedEvents() {
    const { contract } = useFVToken();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVToken.Unpaused event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("Unpaused", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic Unpaused", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["Unpaused"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVToken.Unpaused", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVToken.Unpaused events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}
