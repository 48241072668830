import React, { useEffect, useState } from "react";

import { useRestoreStateOnAuthenticatedOrRedirect } from "../../router";
import { Wrappers } from "@features/components";
import { UserCard } from "./userCard";
import { CustomCard } from "./customCard";
import { LOGIN, MY_PROFILE, PROFILE_ORDERS } from "../../pages.constants";

const LoginPage = () => {
    const [lmuid, setLmuid] = useState("");

    useEffect(() => {
        const items = JSON.parse(localStorage?.getItem("lmuid"));
        if (items) {
            setLmuid(items);
        }
    }, [lmuid]);

    useRestoreStateOnAuthenticatedOrRedirect(`${MY_PROFILE}/${PROFILE_ORDERS}`);
    return (
        <Wrappers.Container>
            {lmuid ? <UserCard lmuid={lmuid} url={LOGIN} /> : <CustomCard url={LOGIN} />}
        </Wrappers.Container>
    );
};

export default LoginPage;
