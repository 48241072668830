export const styles = (theme) => ({
    container: {
        padding: "0"
        // [theme.breakpoints.up("md")]: {
        //    height: '100vh'
        // }
    },
    gridGallery: {
        paddingTop: "8px !important",
        // height: "90vh",
        borderRight: "1px solid #DDDDDD",
        [theme.breakpoints.down("md")]: {
            border: "none",
            paddingTop: "24px !important"
        }
    },
    leftSideContainer: {
        width: "100%"
    },
    mainImageSellerContainer: {
        height: "658px",
        margin: "40px 40px 40px 0",

        [theme.breakpoints.down("md")]: {
            margin: "16px 0 24px 0"
        },
        [theme.breakpoints.down("sm")]: {
            height: "390px"
        }
    },
    cardMediaWrapper: {
        overflow: "scroll",

        [`&::-webkit-scrollbar`]: {
            display: "none"
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none"
    },
    mainImageSeller: {
        width: "100%",
        height: "658px",
        objectFit: "contain",
        background: "#fff",

        [theme.breakpoints.down("sm")]: {
            height: "390px",
            padding: "16px 0 24px 0"
        }
    },
    mainImage: {
        height: "658px",
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.down("md")]: {
            marginBottom: "40px"
        },

        [theme.breakpoints.down("sm")]: {
            height: "390px"
        }
    },
    imageMaxHeight: {
        objectFit: "contain",

        [theme.breakpoints.down("md")]: {
            height: "658px"
        },

        [theme.breakpoints.down("sm")]: {
            maxHeight: "390px"
        }
    },
    mobilePadding: {
        [theme.breakpoints.down("md")]: {
            padding: "0 24px"
        }
    },
    cardMediaWrapper: {
        overflow: "scroll",

        [`&::-webkit-scrollbar`]: {
            display: "none"
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none"
    },
    cardMediaContainer: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: "8px",
        rowGap: "8px",

        [theme.breakpoints.down("md")]: {
            justifyContent: "center"
        }
    },
    mainCardMedia: {
        minWidth: "284px",
        maxWidth: "284px",
        height: "308px",
        borderRadius: "8px",
        cursor: "pointer",
        border: "1px solid #DDDDDD",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        [theme.breakpoints.down("md")]: {
            minWidth: "200px",
            maxWidth: "200px",
            height: "214px"
        }
    },
    cardMedia: {
        position: "relative",
        width: "154px",
        borderRadius: "8px",
        cursor: "pointer",
        border: "1px solid #DDDDDD",
        height: "148px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
            width: "97px",
            height: "97px"
        }
    },
    uploadButton: {
        width: "100%",
        height: "100%"
    },
    smallUploadButton: {
        "& h5": {
            fontSize: "10px"
        },
        [theme.breakpoints.down("md")]: {
            '& h5': {
                fontSize: "8px"
            }
        }
    },
    deleteIcon: {
        position: "absolute",
        top: "12px",
        right: "12px",
        width: "14px"
    },
    chooseImageIcon: {
        fill: "#DDD",
        width: "168px",
        height: "138px",
        marginBottom: "32px"
    },
    uploadZone: {
        border: "1px dashed var(--Primary-Grey-C, #7E7E7E)",
        borderRadius: "16px",
        padding: "32px 171px 21px",
        margin: "0 64px",

        [theme.breakpoints.down("md")]: {
            margin: "0 12px",
            padding: "12px 54px"
        }
    },
    smallUploadZone: {
        display: "flex",
        alignItems: "center",
        height: "148px",
        width: "154px",
        padding: "12px",
        margin: 0,

        "& h5": {
            fontSize: "10px"
        },

        [theme.breakpoints.down("md")]: {
            width: "97px",
            height: "97px",
            padding: "4px 12px",

            "& h5": {
                fontSize: "9px"
            }
        }
    },
    uploadButton: {
        width: "100%",
        height: "40px"
    }
});
