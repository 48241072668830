import React from "react";
import { TableCell, TableRow, TableContainer, Table, TableBody, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Text } from "..";
const styles = {
    root: {
        borderBottom: "none",
        width: "30%"
        // position:'relative'
    },
    row: {
        borderLeft: "3px solid black"
    },
    firstColumn: {
        color: "#878181"
    },
    secondColumn: {
        color: "#5a5757"
    },
    dotIcon: {
        height: "15px",
        width: "15px",
        backgroundColor: "black",
        borderRadius: "50%",
        display: "inline-block",
        position: "absolute",
        left: "2px"
    },
    lastRow: {
        paddingBottom: "0"
    },
    firstRow: {
        paddingTop: "0"
    }
};

function StyledTable(props) {
    const { classes } = props;

    return (
        <Box p={1} style={{ position: "relative" }} mt={3} mb={2}>
            <TableContainer>
                <Table aria-label='caption table' sx={{ tableLayout: "auto" }}>
                    <TableBody>
                        <TableRow classes={{ root: classes.row }}>
                            <TableCell
                                classes={{ root: classes.root }}
                                className={classes.firstRow}
                            >
                                <Box className={classes.dotIcon}></Box>
                                <Text.Typography variant='h3'>Price</Text.Typography>
                            </TableCell>
                            <TableCell
                                classes={{ root: classes.root }}
                                className={classes.firstRow}
                            >
                                <Text.Typography variant='h3'>Date</Text.Typography>
                            </TableCell>
                            <TableCell
                                classes={{ root: classes.root }}
                                className={classes.firstRow}
                            >
                                <Text.Typography variant='h3'>Username</Text.Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow classes={{ root: classes.row }}>
                            <TableCell classes={{ root: classes.root }}>
                                <Box className={classes.dotIcon}></Box>
                                <Text.Typography variant='h3'>Price</Text.Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.root }}>
                                <Text.Typography variant='h3'>Date</Text.Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.root }}>
                                <Text.Typography variant='h3'>Username</Text.Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow classes={{ root: classes.row }}>
                            <TableCell classes={{ root: classes.root }}>
                                <Box className={classes.dotIcon}></Box>
                                <Text.Typography variant='h3'>Price</Text.Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.root }}>
                                <Text.Typography variant='h3'>Date</Text.Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.root }}>
                                <Text.Typography variant='h3'>Username</Text.Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow classes={{ root: classes.row }}>
                            <TableCell classes={{ root: classes.root }} className={classes.lastRow}>
                                <Box sx={{ bottom: "8px" }} className={classes.dotIcon} />
                                <Text.Typography variant='h3'>Price</Text.Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.root }} className={classes.lastRow}>
                                <Text.Typography variant='h3'>Date</Text.Typography>
                            </TableCell>
                            <TableCell classes={{ root: classes.root }} className={classes.lastRow}>
                                <Text.Typography variant='h3'>Username</Text.Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default withStyles(styles)(StyledTable);
