import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup,
    FormHelperText,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import useValidation from "../../hooks/useValidation";

function TransferNFTDialog({ open, onClose, onSubmit, ...props }) {
    const [wallet, setWallet, walletValid, walletHelperText] = useValidation({
        minLen: 42,
        maxLen: 42
    });

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>Transfer NFT</DialogTitle>
            <DialogContent>
                <Stack padding={1}>
                    <FormGroup>
                        <TextField
                            label='Address'
                            placeholder='Enter wallet address'
                            value={wallet}
                            color={!walletValid ? "error" : "success"}
                            onChange={(e) => setWallet(e.target.value)}
                        />
                        <FormHelperText>
                            <Typography variant='p' color='error'>
                                {walletHelperText || ""}
                            </Typography>
                        </FormHelperText>
                    </FormGroup>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSubmit({ wallet, ...props })}>Transfer</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TransferNFTDialog;
