import config from "@app/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: config.services.card.baseUrl,
    prepareHeaders: (headers) => {
        return headers;
    }
});

export default createApi({
    reducerPath: "uploadImageAPI",
    baseQuery,
    endpoints: () => ({})
});
