import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Stack,
    Box,
    TableContainer,
    Table,
    TableBody,
    CardMedia,
    CircularProgress
} from "@mui/material";
import LazyNFTCard from "@features/fvtoken/lazyNFTCard";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { withStyles } from "@mui/styles";

import { useAuthentication } from "@features/user";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";

import {
    useGetProductByImuidQuery,
    useGetLazyTokenQuery,
    useLazyGetAllImagesQuery,
    useGetProductHistoryQuery,
    useGetUserStripeAccountQuery,
    useLazyGetProductByImuidQuery
} from "@app/services";
import { constants, utils } from "@app/utils";
import { Cards, Text, Button, Tables, Wrappers, Gallery } from "@features/components";
import { styles } from "./card.style";
import ResponsiveDialog from "@features/components/modal";
import TableDetails from "./tableDetails";
import useDimensions from "../index/hooks/useMobile";
import {
    useDeleteFormMutation,
    useGetEbayListingQuery,
    useLazyGetProductAttributesByImuidQuery,
    useLazyGetProductUpdatedAttributesByImuidQuery,
    usePostFormMutation
} from "@app/services/formsAPI";
import ImageUploader from "./imageUploader";
import { getCategoryId, getCategoryPath, getDynamicAttributes } from "./utils";
import config from "@app/config";

const tabsTitles = [{ label: "Original Images", value: "1" }];

const CardSellerPage = ({ classes }) => {
    const [open, setOpen] = useState(false);
    const [listFormButtonLoading, setListFormButtonLoading] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { search } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile } = useDimensions();

    const { details } = useAuthentication();
    const userId = details?.cuid;

    // Get data about stripe acc
    const [isFetching, setIsFetching] = useState(false);
    const [fieldsWithError, setFieldsWithError] = useState({});
    const [NFTImage, setNFTImage] = useState(null);
    const {
        data: stripeAccountData,
        isLoading: getStripeAcc,
        isError: getAccError,
        error: getAccErrorData
    } = useGetUserStripeAccountQuery({ userId }, { skip: !isFetching });
    const lmuid = useMemo(() => new URLSearchParams(search).get("lmuid"), [search]);

    // Get data needs for history table
    const historyData = useGetProductHistoryQuery({ lmuid }, { skip: !lmuid });
    const [postFormTrigger] = usePostFormMutation();
    const [deleteFormTrigger] = useDeleteFormMutation();
    const [getUpdatedFormsAttributesTrigger] = useLazyGetProductUpdatedAttributesByImuidQuery();
    const ebayListing = useGetEbayListingQuery({ lmuid });
    const [getFormsAttributesTrigger] = useLazyGetProductAttributesByImuidQuery();
    const userToken = useSelector((state) => state.user.token);
    const { data, error, isLoading } = useGetLazyTokenQuery({ lmuid }, { skip: !lmuid });
    const itemData = useGetProductByImuidQuery(lmuid, {
        skip: !lmuid
    });
    const [getItemDataTrigger] = useLazyGetProductByImuidQuery();
    const [getAllImagesTrigger] = useLazyGetAllImagesQuery();

    useEffect(() => {
        itemData.refetch();
    }, []);
    //refactor
    useEffect(() => {
        if (data?.ipfs === "") {
            console.log("unable to find token with lmuid", lmuid);
            dispatch(
                showSnackbarAlert({
                    message: "We are unable to find the requested token",
                    severity: "error"
                })
            );
            navigate("/");
            return;
        }
    }, [data]);

    useEffect(() => {
        if (itemData?.data?.data) {
            localStorage?.setItem("uid", JSON.stringify(itemData?.data?.data));
        }
    }, [itemData?.isSuccess]);

    // open stripe popup
    useEffect(() => {
        if (getAccErrorData?.data?.message) {
            handleClickOpen();
        }
    }, [getAccErrorData?.data?.message]);

    // case whe user didn't verified stripe acc.
    useEffect(() => {
        if (data?.data?.stripeAccountLink) {
            window.location.href = data?.data?.stripeAccountLink;
        }
    }, [data?.data?.stripeAccountLink]);

    const cardOptions = useMemo(
        () => utils.getCardAttributes(data?.metadata.attributes),
        [data?.metadata.attributes]
    );
    useEffect(() => {
        if (stripeAccountData?.data?.account?.stripeVerified && lmuid) {
            navigate(`/edit-card?lmuid=${lmuid}`);
        }
    }, [stripeAccountData?.data?.account?.stripeVerified]);

    const customOnChange = useCallback(
        async (tabValue) => {
            if (tabValue === "2") {
                const imagesData = await getAllImagesTrigger(lmuid).unwrap();
                setAllImages(imagesData?.data);
            }
        },
        [getAllImagesTrigger]
    );

    const getItemListingPrice = useCallback((updatedItemData, formsAttributes) => {
        const parsedInformation = updatedItemData?.data?.parsedInformation;
        if (!parsedInformation?.sellingPrice || !parsedInformation?.shippingPrice) {
            return formsAttributes.listingPrice;
        }

        return `${
            Number.parseInt(parsedInformation.sellingPrice) +
            Number.parseInt(parsedInformation.shippingPrice)
        }`;
    }, []);

    const filterHistoryData = useCallback((historyData) => {
        if (!historyData) {
            return;
        }

        const validTypes = ["SOLD", "CLAIMED_LAZY"];

        return historyData.filter((el) => {
            if (validTypes.includes(el?.type)) {
                return true;
            }
            return false;
        });
    }, []);

    const listFormOnEbay = useCallback(async () => {
        if (!details?.eBayIntegrated && details) {
            const EBAY_INTEGRATION_CALLBACK = `${document.location.origin}/my-account/dashboard`;
            window.location.href = `${config.services.forms.baseUrl}ebay/oauth2?token=${userToken}&callback=${EBAY_INTEGRATION_CALLBACK}`;
            return;
        }

        setListFormButtonLoading(true);

        let formsAttributes = await getFormsAttributesTrigger({
            lmuid,
            token: userToken
        }).unwrap();
        // copy an object in order to make properties writable

        const updatedItemData = await getItemDataTrigger(lmuid).unwrap();

        const categoryPath = getCategoryPath(
            updatedItemData?.data?.parsedInformation?.categoryPath,
            formsAttributes,
            itemData
        );

        const categoryId = getCategoryId(formsAttributes, categoryPath);

        const errorFields = (getDynamicAttributes(categoryId, formsAttributes) || [])
            .map((el) => {
                let key;
                if (Object.keys(constants.PRODUCT_ATTRIBUTES_TEXT).includes(el?.name)) {
                    key = constants.PRODUCT_ATTRIBUTES_TEXT[el.name];
                } else {
                    key = el?.name;
                }
                console.log(updatedItemData?.data, updatedItemData?.data?.parsedInformation?.[key]);
                if (!updatedItemData?.data?.parsedInformation?.[key] && !el?.selectedValue) {
                    return key;
                }
                return null;
            })
            .filter((el) => el !== null);

        if (
            !formsAttributes?.productDescription &&
            !updatedItemData?.data?.parsedInformation?.nameOfProduct
        ) {
            errorFields.push(constants.PRODUCT_ATTRIBUTES_TEXT["nameOfProduct"]);
        }
        if (
            !formsAttributes?.productDescription &&
            !updatedItemData?.data?.parsedInformation?.productDescription
        ) {
            errorFields.push(constants.PRODUCT_ATTRIBUTES_TEXT["productDescription"]);
        }
        if (!updatedItemData?.data?.parsedInformation?.postcode) {
            errorFields.push(constants.SALE_LISTING_ATTRIBUTES_TEXT["postcode"]);
        }
        if (!updatedItemData?.data?.parsedInformation?.condition) {
            errorFields.push(constants.SALE_LISTING_ATTRIBUTES_TEXT["condition"]);
        }
        if (!updatedItemData?.data?.parsedInformation?.sellingPrice) {
            errorFields.push(constants.SALE_LISTING_ATTRIBUTES_TEXT["sellingPrice"]);
        }
        if (!updatedItemData?.data?.parsedInformation?.shippingPrice) {
            errorFields.push(constants.SALE_LISTING_ATTRIBUTES_TEXT["shippingPrice"]);
        }

        if (errorFields.length > 0) {
            setFieldsWithError(errorFields.map((el) => ({ [el]: true }))[0]);
            setListFormButtonLoading(false);
            return;
        }

        formsAttributes = { ...formsAttributes };

        formsAttributes.productName =
            updatedItemData?.data?.parsedInformation?.nameOfProduct || formsAttributes.productName;

        formsAttributes.productDescription =
            updatedItemData?.data?.parsedInformation?.productDescription ||
            formsAttributes.productDescription;

        formsAttributes.listingPrice = getItemListingPrice(updatedItemData, formsAttributes);

        formsAttributes.aspects = (formsAttributes.aspects || []).map((el, index) => {
            if (!formsAttributes?.categoryAspectIndex[categoryId].includes(index)) {
                return el;
            }

            let key;
            if (Object.keys(constants.PRODUCT_ATTRIBUTES_TEXT).includes(el?.name)) {
                key = constants.PRODUCT_ATTRIBUTES_TEXT[el.name];
            } else {
                key = el?.name;
            }

            if (updatedItemData?.data?.parsedInformation?.[key]) {
                return {
                    ...el,
                    selectedValue: updatedItemData?.data?.parsedInformation?.[key]
                };
            }
            return { ...el, selectedValue: el?.selectedValue };
        });

        formsAttributes.publish = false;

        postFormTrigger({
            lmuid,
            clientId: "29f5b183-7293-11ee-9e95-5a154ab6a032",
            data: formsAttributes
        })
            .unwrap()
            .then(() => {
                dispatch(
                    showSnackbarAlert({
                        message: "Your item has been listed on Ebay",
                        severity: "success"
                    })
                );
                navigate(0);
            })
            .catch(() => {
                dispatch(
                    showSnackbarAlert({
                        message: "Failed, please make sure you have filled in all fields",
                        severity: "error"
                    })
                );
            })
            .finally(() => {
                setListFormButtonLoading(false);
            });
        ebayListing.refetch();
    }, [getFormsAttributesTrigger, postFormTrigger, lmuid, userToken, details]);

    const deleteForm = useCallback(() => {
        deleteFormTrigger({ lmuid })
            .unwrap()
            .then(() => {
                dispatch(
                    showSnackbarAlert({
                        message: "Item was successfully deleted from eBay",
                        severity: "success"
                    })
                );
                navigate(0);
            })
            .catch(() => {
                dispatch(
                    showSnackbarAlert({
                        message: "Something went wrong",
                        severity: "error"
                    })
                );
            });
    }, [lmuid]);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            <Box mt={isMobile ? 0 : 5}>
                {isMobile && (
                    <Stack
                        direction='column'
                        justifyContent='space-between'
                        mb={4}
                        mt={2}
                        className={classes.mobilePadding}>
                        <Box
                            display='flex'
                            alignItems='center'
                            mb={1}
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate("/my-account/dashboard")}>
                            <span>&lt;</span>
                            <Text.Typography variant='h5' style={{ textDecoration: "underline" }}>
                                {" "}
                                Back to profile
                            </Text.Typography>
                        </Box>
                        <Text.Typography variant='h2'>{data?.metadata.name}</Text.Typography>
                    </Stack>
                )}
                <Grid container justifyContent='space-between' spacing={5}>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        pt={2}
                        classes={{ root: classes.gridGallery }}
                        sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                            className={classes.leftSideContainer}
                            sx={{ margin: { xs: "24px 0", md: "235px 0 0 0" } }}>
                            <Stack
                                direction='column'
                                spacing={{ xs: 6, md: 12 }}
                                alignItems='center'>
                                <LazyNFTCard
                                    externalImage={NFTImage}
                                    scaled={isMobile ? true : false}
                                    clickable
                                    hideIndicator
                                    NFTData={data?.metadata}
                                    onClick={() => navigate(`/card-seller?lmuid=${item.lmuid}`)}
                                />
                                <ImageUploader setImage={setNFTImage} />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        p={{
                            xs: 0,
                            md: 5
                        }}>
                        {!isMobile && (
                            <Stack
                                direction='column'
                                justifyContent='space-between'
                                mb={4}
                                mt={5}
                                className={classes.mobilePadding}>
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    mb={1}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate("/my-account/dashboard")}>
                                    <span>&lt;</span>
                                    <Text.Typography
                                        variant='h5'
                                        style={{ textDecoration: "underline" }}>
                                        {" "}
                                        Back to profile
                                    </Text.Typography>
                                </Box>
                                <Text.Typography variant='h2'>
                                    {data?.metadata.name}
                                </Text.Typography>
                            </Stack>
                        )}
                        <Box mt={isMobile ? -6 : 0}>
                            <TableDetails
                                lmuid={lmuid}
                                itemData={itemData}
                                refetchItemData={itemData.refetch}
                                fieldsWithError={fieldsWithError}
                                setFieldsWithError={setFieldsWithError}
                            />
                        </Box>
                        <Grid
                            container
                            justifyContent='space-between'
                            mt={3}
                            mb={3}
                            spacing={3}
                            className={classes.mobilePadding}>
                            <Grid item xs={12} md={12} lg={12}>
                                <ResponsiveDialog handleClose={handleClose} open={open} />
                                <Stack>
                                    {ebayListing?.data ? (
                                        <Stack direction='row' spacing={2}>
                                            <Button
                                                onClick={() =>
                                                    window.open(
                                                        `https://www.ebay.co.uk/itm/${ebayListing.data?.listing?.listingId}`
                                                    )
                                                }
                                                variant='contained'
                                                color='primary'
                                                size='large'
                                                style={{
                                                    width: "100%",
                                                    textTransform: "inherit",
                                                    marginTop: "10px"
                                                }}>
                                                See on eBay
                                            </Button>
                                            <Button
                                                onClick={deleteForm}
                                                variant='contained'
                                                color='error'
                                                size='large'
                                                style={{
                                                    width: "100%",
                                                    textTransform: "inherit",
                                                    marginTop: "10px"
                                                }}>
                                                Remove from eBay
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <Button
                                            onClick={listFormOnEbay}
                                            loading={itemData.isLoading || listFormButtonLoading}
                                            variant='contained'
                                            color='primary'
                                            size='large'
                                            style={{
                                                width: "100%",
                                                textTransform: "inherit",
                                                marginTop: "10px"
                                            }}>
                                            List for sale
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>

                        {/* <Box className={classes.mobilePadding}>
                            <Tables.CollabsibleTable data={filterHistoryData(historyData?.currentData)} />
                        </Box> */}
                    </Grid>
                </Grid>
            </Box>
        </Wrappers.Container>
    );
};

export default withStyles(styles)(CardSellerPage);
