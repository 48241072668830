import { Box, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { Cards, Text } from "@features/components";
import config from "../config";
import MainNavigation from "../navigations";

function AboutPage() {
    return (
        <MainNavigation>
            <Box p={4}>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={6}>
                        <Cards.Curvy>
                            <CardContent>
                                <Box px={4} py={2}>
                                    <Text.DashHeader color='black'>
                                        NFT Platform v{config.version}
                                    </Text.DashHeader>
                                    <Typography variant='body1' color='text.secondary' mb={1}>
                                        Marketplace app with royalty payments that allows both Web2
                                        and Web3 users to create NFTs, mint, buy and sell them.
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Cards.Curvy>
                    </Grid>
                </Grid>
            </Box>
        </MainNavigation>
    );
}

export default AboutPage;
