import { Button, Text, Wrappers } from "@features/components";
import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import useButton from "./hooks/useButton";
import useDimensions from "./hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    section: {
        height: "415px",
        width: "100vw",
        marginLeft: "-24px",
        // background: "#F7F7F7",
        borderRadius: 0,
        padding: "0 152px",
        // marginTop: "200px",
        marginTop: "64px",
        
        [theme.breakpoints.down("sm")]: {
            // position: "relative",
            height: "365px",
            // margin: "80px 0 0 -16px",
            margin: "-300px 0 0 -16px",
            padding: "0 16px",
            zIndex: 0
        }
    },
    textWrapper: {
        height: "100%",
        justifyContent: "space-between",

        [theme.breakpoints.down("sm")]: {
            height: "325px"
        }
    },
    textContainer: {
        alignItems: "center",
        paddingTop: "80px",

        [theme.breakpoints.down("sm")]: {
            paddingTop: "40px"
        }
    },
    button: {
        height: "61px",
        width: "178px",
        fontSize: "18px",

        [theme.breakpoints.down("sm")]: {
            height: "50px",
            fontSize: "16px"
        }
    },
    bottomContainer: {
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid #DDD",
        padding: "24px 0"
    },
    title: {
        fontSize: "29px !important",
        textAlign: "center",

        [theme.breakpoints.down("sm")]: {
            fontSize: "19px !important"
        }
    }
}));

function LandingFooter() {
    const classes = useStyles();
    const { isMobile } = useDimensions();
    const handleClick = useButton();

    return (
        <Wrappers.Container classes={{ root: classes.section }}>
            <Stack className={classes.textWrapper}>
                <Stack spacing={5} className={classes.textContainer}>
                    {/* <Text.Typography variant='h3' className={classes.title}>
                        Start organising your purchases
                        {isMobile ? <br /> : " "}
                        now for free and
                        {!isMobile ? <br /> : " "}
                        get them ready to resell
                    </Text.Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleClick}
                        classes={{ root: classes.button }}>
                        Start for free
                    </Button> */}
                </Stack>
                <Box className={classes.bottomContainer}>
                    <Text.Typography variant='h5' sx={{ fontWeight: 600 }}>
                        © unlimitid 2023
                    </Text.Typography>
                </Box>
            </Stack>
        </Wrappers.Container>
    );
}

export default LandingFooter;
