import React from "react";
import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Text } from "@features/components";

const styles = () => ({
    root: {
        padding: "8px 12px 8px 0",
        borderBottom: "1px solid #DDD",
        cursor: "pointer",

        '&:last-child': {
            borderBottom: 'none'
        }
    }
});

function OptionsListItem(props) {
    const { classes, option, onSelect } = props;

    return (
        <Box className={classes.root} onClick={() => onSelect(option)}>
            <Text.Typography variant='h5'>{option}</Text.Typography>
        </Box>
    );
}

export default withStyles(styles)(OptionsListItem);
