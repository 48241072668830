import React, { useState } from "react";
import { Box, MenuItem } from "@mui/material";
import { useAuthentication } from "@features/user";
import { useDispatch } from "react-redux";
import UserCard from "@features/components/profile/userCard";
import Photo from "@features/components/profile/img/user.svg";
import { Button, FormInputs, Wrappers } from "@features/components";
import { withStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";

const styles = (theme) => ({
    button: {
        marginLeft: "24px",
        width: "132px",

        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            width: "100%",
            height: "53px"
        }
    },
    input: {
        [`& .MuiOutlinedInput-notchedOutline`]: {
            borderColor: "#ddd"
        }
    },
    descriptionInput: {
        [`& .MuiInputBase-root`]: {
            height: "90px"
        }
    }
});

const countries = ["United Kingdom"];

const ProfilePage = ({ classes }) => {
    const [location, setLocation] = useState(countries[0]);

    const dispatch = useDispatch();
    const { details, deleteAccount, updateAccount } = useAuthentication();
    const {
        formState: { errors },
        handleSubmit,
        control
    } = useForm({ mode: "onChange" });
    const onSubmit = (data) => console.log(data);

    return (
        <Box>
            {/* <UserCard /> */}
            {/* <UserCard />
            <Box mx={{xs: 1.5, md: 15}} mt={{xs: 5, md: 9}} sx={{
                maxWidth: {
                    xs: "none",
                    md: "700px"
                }
            }}>
                <Wrappers.WithTitle
                    titleVariant="h3"
                    titleText="Profile picture"
                    mb={6}
                >
                    <Box sx={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Box
                            sx={{
                                display: {
                                    xs: "none",
                                    sm: "flex"
                                }
                            }}
                        >
                            <img 
                                src={Photo}
                                alt='userPhoto'
                                width={"150px"}
                            />
                        </Box>
                        <Button
                            variant='outlined'
                            color='secondary'
                            className={classes.button}
                        >
                            Upload image
                        </Button>
                    </Box>
                </Wrappers.WithTitle>
                <Wrappers.WithTitle
                    titleVariant="h3"
                    titleText="Your info"
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: "Please enter full name"
                            }}
                            render={({ field }) => (
                                <FormInputs.InputField
                                    {...field}
                                    name='full_name'
                                    label='Full name'
                                    helperText={errors?.full_name?.message}
                                    placeholder='Please enter full name'
                                    fullwidth={true}
                                />
                            )}
                            name='full_name'
                        />
                        <Controller
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: "Please enter username"
                            }}
                            render={({ field }) => (
                                <FormInputs.InputField
                                    {...field}
                                    name='username'
                                    label='Username'
                                    helperText={errors?.username?.message}
                                    placeholder='Please enter username'
                                    fullwidth={true}
                                    mt={3}
                                />
                            )}
                            name='username'
                        />
                        <Controller
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: "Please enter description"
                            }}
                            render={({ field }) => (
                                <FormInputs.InputField
                                    {...field}
                                    name='description'
                                    label='Description'
                                    helperText={errors?.description?.message}
                                    placeholder='description'
                                    fullwidth={true}
                                    mt={3}
                                    className={`${classes.descriptionInput} ${classes.input}`}
                                />
                            )}
                            name='description'
                        />
                        <Controller
                            control={control}
                            defaultValue={location}
                            render={({ field }) => (
                                <FormInputs.Dropdown
                                    {...field}
                                    mt={3}
                                    label='Location'
                                    fullwidth={true}
                                    defaultValue={location}
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    className={classes.input}
                                >
                                    {countries.map((item) => (
                                        <MenuItem value={item} key={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </FormInputs.Dropdown>
                            )}
                            name='location'
                        />
                        <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            type='submit'
                            sx={{
                                width: "100%",
                                mt: 5
                            }}
                        >
                            Save info
                        </Button>
                    </form>
                </Wrappers.WithTitle>
            </Box> */}
        </Box>
    );
};

export default withStyles(styles)(ProfilePage);
