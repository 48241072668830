import chainListAPI from "../../chainListAPI";

export const chainListService = chainListAPI.injectEndpoints({
    endpoints: (build) => ({
        getNetworks: build.query({
            query: () => ({
                url: "/chains.json"
            })
        })
    }),
    overrideExisting: true
});

export const { useGetNetworksQuery } = chainListService;
