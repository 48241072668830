import React, { useEffect, useState } from "react";
import {
    Backdrop,
    Button,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { useWeb3 } from "../web3";
import useCreateERC721Token from "./hooks/useCreateERC721Token";
import useValidation from "../hooks/useValidation";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import { useMintTokenMutation } from "@app/services";
import { Cards, Text } from "../components";
import useNetworkExplorer from "../web3/hooks/useNetworkExplorer";

function NFTCreateForm() {
    const dispatch = useDispatch();
    const { account } = useWeb3();
    const { transactionHashToEtherscanAddress } = useNetworkExplorer();
    const { createNFT } = useCreateERC721Token();
    const [mintToken, { error }] = useMintTokenMutation();
    const [name, setName, nameValid, nameHelperText] = useValidation({ minLen: 2, maxLen: 50 });
    const [description, setDescription, descriptionValid, descriptionHelperText] = useValidation({
        minLen: 2,
        maxLen: 200
    });
    const [useConnectedWallet, setUseConnectedWallet] = useState(false);
    const [wallet, setWallet, walletInvalid, walletHelperText] = useValidation({
        minLen: 42,
        maxLen: 42
    });
    const [disabled, setDisabled] = useState(false);
    const [file, setFile] = useState();
    const [tx, setTx] = useState("");
    const [snackbar, setSnackbar] = useState(false);

    const handleFileSelect = () => {
        const input = document.createElement("input");
        input.hidden = "hidden";
        input.type = "file";
        input.onchange = (e) => setFile(e.target.files[0]);
        input.click();
    };

    const handleMintToken = async () => {
        console.log("uploading image", {
            name,
            description,
            file,
            wallet
        });
        const form = new FormData();
        form.append("file", file, file.name);
        form.append("name", name);
        form.append("description", description);
        form.append("wallet", wallet);

        setDisabled(true);
        try {
            if (useConnectedWallet) {
                await createNFT(name, description, file, []).then(({ transactionHash }) => {
                    setTx(transactionHash);
                    dispatch(
                        showSnackbarAlert({
                            message: `Transaction was accepted`,
                            severity: "success"
                        })
                    );
                });
            } else {
                await mintToken({ form }).then(({ data }) => {
                    console.log("mintToken response:", data);
                    setTx(data);
                    dispatch(
                        showSnackbarAlert({
                            message: `Transaction was accepted`,
                            severity: "success"
                        })
                    );
                });
            }
            setSnackbar(true);
        } catch (e) {
            console.error(e);
            dispatch(
                showSnackbarAlert({
                    message: `Transaction was rejected`,
                    severity: "info"
                })
            );
        }
        setDisabled(false);

        // Clear out form
        setName("");
        setWallet("");
        setDescription("");
        setFile("");
    };

    useEffect(() => {
        if (error) {
            console.error(error);
            dispatch(
                showSnackbarAlert({
                    message: `Sorry, we are unable to mint tokens at the moment`,
                    severity: "error"
                })
            );
        }
    }, [dispatch, error]);

    return (
        <React.Fragment>
            <Cards.Curvy sx={{ position: "relative" }}>
                <CardContent>
                    <Text.DashHeader>Create NFT</Text.DashHeader>
                    <Stack spacing={1} sx={{ width: "100%" }}>
                        <TextField
                            label='Name'
                            placeholder='Enter NFT name'
                            disabled={disabled}
                            value={name}
                            color={!nameValid ? "error" : "success"}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FormHelperText>
                            <Typography variant='p' color='error'>
                                {nameHelperText || ""}
                            </Typography>
                        </FormHelperText>

                        <TextField
                            label='Description'
                            variant='outlined'
                            placeholder='Enter description'
                            value={description}
                            disabled={disabled}
                            multiline
                            minRows={4}
                            maxRows={4}
                            color={!descriptionValid ? "error" : "success"}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <FormHelperText>
                            <Typography variant='p' color='error'>
                                {descriptionHelperText || ""}
                            </Typography>
                        </FormHelperText>
                        {account ? (
                            <FormGroup>
                                <FormControlLabel
                                    disabled={disabled}
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                setUseConnectedWallet(e.target.checked);
                                                setWallet(e.target.checked ? account : "");
                                            }}
                                            checked={useConnectedWallet}
                                            color='success'
                                        />
                                    }
                                    label='Use connected wallet'
                                    labelPlacement='end'
                                />
                            </FormGroup>
                        ) : (
                            ""
                        )}
                        {!useConnectedWallet ? (
                            <FormGroup>
                                <TextField
                                    label='Address'
                                    placeholder='Enter wallet address'
                                    disabled={disabled || useConnectedWallet}
                                    value={wallet}
                                    color={walletInvalid ? "error" : "success"}
                                    onChange={(e) => setWallet(e.target.value)}
                                />
                                <FormHelperText>
                                    <Typography variant='p' color='error'>
                                        {walletHelperText || ""}
                                    </Typography>
                                </FormHelperText>
                            </FormGroup>
                        ) : (
                            ""
                        )}
                        <Button
                            variant='outlined'
                            component='label'
                            disabled={disabled}
                            onClick={handleFileSelect}
                        >
                            {file ? file.name : "Upload media"}
                        </Button>
                        <FormHelperText>
                            <Typography variant='p' color='error'></Typography>
                        </FormHelperText>
                        <Button
                            variant='contained'
                            onClick={handleMintToken}
                            disabled={disabled || !nameValid || !descriptionValid || !file}
                        >
                            Mint
                        </Button>
                    </Stack>
                    {disabled ? (
                        <Backdrop
                            sx={{
                                backgroundColor: "rgba(0,0,0,.2)",
                                position: "absolute",
                                zIndex: (theme) => theme.zIndex.drawer + 1
                            }}
                            open={disabled}
                        >
                            <CircularProgress color='primary' size='5rem' />
                        </Backdrop>
                    ) : (
                        ""
                    )}
                </CardContent>
            </Cards.Curvy>
            <Snackbar
                open={snackbar}
                autoHideDuration={25000}
                onClose={() => setSnackbar(false)}
                message='View last transaction'
                action={
                    <Button href={transactionHashToEtherscanAddress(tx)} target='_blank'>
                        View Transaction
                    </Button>
                }
            />
        </React.Fragment>
    );
}

// export const nameValidation = (v) => {
//     return v.match(/[A-Za-z ]/g).length === v.length || 'must contain only letters and spaces.'
// }

export default NFTCreateForm;
