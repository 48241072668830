import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null, // jwt token from server, either from basic or biometric authentication.
    expiresIn: null, // how long until token expires..
    email: null, // user's email
    loggedOut: false, // whether user is logged out or not
    ebayIntegrated: false // whether user has integrated eBay or not
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            console.log("setting user", payload);
            state.token = payload.token || state.token;
            state.expiresIn = payload.expiresIn || state.expiresIn;
        },
        setLoggedOut: (state, { payload: {loggedOut} }) => {
            state.loggedOut = loggedOut
        },
        deleteUser: (state) => {
            console.log("deleting user");
            state.token = initialState.token;
            state.expiresIn = initialState.expiresIn;
        },
        setUserEmail: (state, { payload: { email } }) => {
            console.log(email);
            state.email = email;
        },
        setEbayState: (state, { payload: { ebayIntegrated } }) => {
            state.ebayIntegrated = ebayIntegrated
        }
    }
});

export const { setUser, deleteUser, setUserEmail, setLoggedOut, setEbayState } = userSlice.actions;

export default userSlice.reducer;
