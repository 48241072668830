import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useWeb3 } from "../web3";
import FVMarketplace from "../../contracts/FVMarketplace.json";
import { useDispatch } from "react-redux";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import config from "@app/config";

const FVMarketplaceContext = createContext();

export function useFVMarketplace() {
    return useContext(FVMarketplaceContext);
}

export function FVMarketplaceContractProvider({ children }) {
    const dispatch = useDispatch();
    const { web3, account, network } = useWeb3();
    const [contract, setContract] = useState();

    useEffect(() => {
        if (network) {
            console.log("FVMarketplace getting deployed network");
            let deployedNetwork = FVMarketplace.networks[config.web3.defaultChainID];
            deployedNetwork.address = config.web3.contracts.fvmarketplace.address;
            if (!deployedNetwork) {
                setContract();
                dispatch(
                    showSnackbarAlert({
                        message: `contract is not available on ${network.title}`,
                        severity: "error"
                    })
                );
                return;
            }
            try {
                console.log("FVMarketplace setting contract instance");
                const contractInstance = new web3.eth.Contract(
                    FVMarketplace.abi,
                    deployedNetwork && deployedNetwork.address,
                    {
                        from: account
                    }
                );
                setContract(contractInstance);
            } catch (e) {
                dispatch(
                    showSnackbarAlert({
                        message: "Failed to initialize contract",
                        severity: "error"
                    })
                );
            }
        }
    }, [network, web3, account, dispatch]);

    const renounceOwnership = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["renounceOwnership"]().send({
            from: account
        });
    }, [contract, account]);

    const transferOwnership = useCallback(
        (newOwner) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["transferOwnership"](newOwner).send({
                from: account
            });
        },
        [contract, account]
    );

    const addListing = useCallback(
        (_token, _tokenId, _price) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["addListing"](_token, _tokenId, _price).send({
                from: account
            });
        },
        [contract, account]
    );

    const cancelListing = useCallback(
        (_listingId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["cancelListing"](_listingId).send({
                from: account
            });
        },
        [contract, account]
    );

    const buy = useCallback(
        (_listingId, _amount) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["buy"](_listingId).send({
                from: account,
                value: _amount
            });
        },
        [contract, account]
    );

    const pause = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["pause"]().send({
            from: account
        });
    }, [contract, account]);

    const unpause = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["unpause"]().send({
            from: account
        });
    }, [contract, account]);

    const withdrawPayments = useCallback(
        (payee) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["withdrawPayments"](payee).send({
                from: account
            });
        },
        [contract, account]
    );

    const owner = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["owner"]().call();
    }, [contract]);

    const paused = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["paused"]().call();
    }, [contract]);

    const payments = useCallback(
        (dest) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["payments"](dest).call();
        },
        [contract]
    );

    const getRoyaltyInfo = useCallback(
        (_listingId) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["getRoyaltyInfo"](_listingId).call();
        },
        [contract]
    );

    const getTotalRoyaltiesEarned = useCallback(
        (_seller) => {
            if (!contract)
                return Promise.reject("cannot call issue as contract has not initialized");
            return contract.methods["getTotalRoyaltiesEarned"](_seller).call();
        },
        [contract]
    );

    const getListings = useCallback(() => {
        if (!contract) return Promise.reject("cannot call issue as contract has not initialized");
        return contract.methods["getListings"]().call();
    }, [contract]);

    const address = useMemo(() => {
        return contract !== undefined ? contract._address : null;
    }, [contract]);

    const hasContract = useMemo(() => {
        return contract !== undefined;
    }, [contract]);

    return (
        <FVMarketplaceContext.Provider
            value={{
                renounceOwnership,
                transferOwnership,
                addListing,
                cancelListing,
                buy,
                pause,
                unpause,
                withdrawPayments,
                owner,
                paused,
                payments,
                getRoyaltyInfo,
                getTotalRoyaltiesEarned,
                getListings,
                contract,
                address,
                hasContract
            }}
        >
            {children}
        </FVMarketplaceContext.Provider>
    );
}

export function useListingAddedEvents() {
    const { contract } = useFVMarketplace();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVMarketplace.ListingAdded event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("ListingAdded", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic ListingAdded", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["ListingAdded"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVMarketplace.ListingAdded", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVMarketplace.ListingAdded events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useListingRemovedEvents() {
    const { contract } = useFVMarketplace();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVMarketplace.ListingRemoved event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("ListingRemoved", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic ListingRemoved", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["ListingRemoved"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVMarketplace.ListingRemoved", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVMarketplace.ListingRemoved events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useListingSoldEvents() {
    const { contract } = useFVMarketplace();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVMarketplace.ListingSold event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("ListingSold", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic ListingSold", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["ListingSold"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVMarketplace.ListingSold", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVMarketplace.ListingSold events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useOwnershipTransferredEvents() {
    const { contract } = useFVMarketplace();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVMarketplace.OwnershipTransferred event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("OwnershipTransferred", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic OwnershipTransferred", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["OwnershipTransferred"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVMarketplace.OwnershipTransferred", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVMarketplace.OwnershipTransferred events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function usePausedEvents() {
    const { contract } = useFVMarketplace();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVMarketplace.Paused event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("Paused", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic Paused", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["Paused"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVMarketplace.Paused", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVMarketplace.Paused events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useRoyaltiesPaidEvents() {
    const { contract } = useFVMarketplace();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVMarketplace.RoyaltiesPaid event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("RoyaltiesPaid", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic RoyaltiesPaid", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["RoyaltiesPaid"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVMarketplace.RoyaltiesPaid", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVMarketplace.RoyaltiesPaid events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}

export function useUnpausedEvents() {
    const { contract } = useFVMarketplace();
    const [events, setEvents] = useState([]);

    function onNewEvent(error, event) {
        if (!error) {
            setEvents((state) => {
                if (
                    state.find(({ transactionHash }) => transactionHash === event.transactionHash)
                ) {
                    return state;
                }
                console.log("adding new FVMarketplace.Unpaused event", { event });
                return [...state, event];
            });
        }
    }

    useEffect(() => {
        if (contract) {
            contract
                .getPastEvents("Unpaused", { fromBlock: "earliest", toBlock: "latest" })
                .then((events) => {
                    console.log("event: historic Unpaused", events);
                    setEvents(events);
                });
            const eventEmitter = contract.events["Unpaused"](onNewEvent).on(
                "connected",
                (subscriptionId) => {
                    console.log("subscribed FVMarketplace.Unpaused", subscriptionId);
                }
            );
            return () => {
                eventEmitter.unsubscribe((_, success) => {
                    if (success) {
                        console.log("unsubscribed from FVMarketplace.Unpaused events");
                    }
                });
                setEvents([]);
            };
        }
    }, [contract]);

    return events;
}
