import React from "react";
import { withStyles } from "@mui/styles";
import { Box } from "@mui/material";
const styles = (theme) => ({
    root: {
        borderTop: "1px solid #DDDDDD"
        // background: "#F7F7F7",
        // height:'100%'
    }
});

export const Footer = withStyles(styles)((props) => {
    const { classes, loading, ...restProps } = props;
    return <Box className={classes.root}></Box>;
});
