import { useState } from "react";
import FormData from "form-data";
import { useFVToken } from "..";
import { useLazyPinFileQuery, useLazyUnpinFileQuery, useUploadFileMutation } from "@app/services";

function useCreateERC721Token() {
    const [ipfsUpload] = useUploadFileMutation();
    const [pin] = useLazyPinFileQuery();
    const [unpin] = useLazyUnpinFileQuery();
    const { mintToken, updateToken } = useFVToken();
    const [isLoading, setLoading] = useState(false);

    const createImageHash = async (file) => {
        const form = new FormData();
        form.append("file", file, file.name);

        const { data, error } = await ipfsUpload(form);

        if (error) {
            throw error.message || JSON.stringify(data);
        }
        console.log("got image hash", data.Hash);
        return data.Hash;
    };

    const createNFTTokenURI = async (name, description, image, attributes = []) => {
        const metaBlob = new Blob(
            [
                JSON.stringify({
                    name,
                    description,
                    image,
                    attributes
                })
            ],
            { type: "text/plain" }
        );
        const metaFile = new FormData();
        metaFile.append("file", metaBlob, `${name.replace(/ /g, "-").toLowerCase()}.json`);

        // Upload: Metadata file.
        const { data, error } = await ipfsUpload(metaFile);
        if (error) {
            throw error.message || JSON.stringify(data);
        }

        // The token URI is the ipfs file we created for the metadata.
        // The metadata contains the image which is also a ipfs file.
        console.log("got metadata hash", data.Hash);
        return {
            tokenURI: `ipfs://${data.Hash}`,
            metadataCID: data.Hash
        };
    };

    const createNFT = async (name, description, file, attributes = []) => {
        setLoading(true);
        console.log("uploading image", { name, description, file, attributes });
        const imageCID = await createImageHash(file);
        console.log("created ipfs image hash", imageCID);
        const { tokenURI, metadataCID } = await createNFTTokenURI(
            name,
            description,
            `ipfs://${imageCID}`,
            attributes
        );
        console.log("created ipfs metadata hash", metadataCID, "with tokenURI", tokenURI);
        return mintToken(tokenURI)
            .then(async (tx) => {
                await Promise.all([pin(imageCID), pin(metadataCID)]);
                return tx;
            })
            .finally(() => setLoading(false));
    };

    const updateNFT = async (tokenId, name, description, image, file, attributes = []) => {
        setLoading(true);
        console.log("updating nft", { tokenId, name, description, image, file, attributes });
        let imageCID = image.split("/").pop();
        if (file) {
            imageCID = await createImageHash(file);
        }
        const imageIpfs = `ipfs://${imageCID}`;
        const { tokenURI, metadataCID } = await createNFTTokenURI(
            name,
            description,
            imageIpfs,
            attributes
        );
        return updateToken(tokenId, tokenURI)
            .then(async (tx) => {
                const pins = [];

                // If file was updated, pin new image and unpin old.
                if (file) {
                    pins.push(pin(await createImageHash(file)));
                    pins.push(unpin(imageCID));
                }
                pins.push(pin(metadataCID));

                // efficiency - await all requests asyncronously
                await Promise.all(pins);
                return tx;
            })
            .finally(() => setLoading(false));
    };

    return { createNFT, updateNFT, isLoading };
}

export default useCreateERC721Token;
