import React from "react";
import { withStyles } from "@mui/styles";
import { Typography as MuiTypography } from "@mui/material";

const styles = (theme) => ({
    h1: {
        fontSize: "58px",
        lineHeight: "120%",
        fontWeight: 500,

        [theme.breakpoints.down("sm")]: {
            fontSize: "30px",
            lineHeight: "140%"
        }
    },
    h2: {
        fontSize: "36px",
        lineHeight: "140%",
        fontWeight: 500,

        [theme.breakpoints.down("sm")]: {
            fontSize: "27px"
        }
    },
    h3: {
        fontSize: "26px",
        lineHeight: "140%",
        fontWeight: 500,

        [theme.breakpoints.down("sm")]: {
            fontSize: "19px"
        }
    },
    h4: {
        fontWeight: 500,
        fontSize: "23px",
        lineHeight: "140%",

        [theme.breakpoints.down("md")]: {
            fontWeight: 600,
            fontSize: "13px"
        }
    },
    h5: {
        fontSize: "14px",
        lineHeight: "170%",

        [theme.breakpoints.down("md")]: {
            fontSize: "11px"
        }
    },
    h6: {
        fontSize: "12px",
        fontWeight: 600
    },
    body1: {
        fontSize: "16px",
        lineHeight: "160%",

        [theme.breakpoints.down("md")]: {
            fontSize: "12px"
        }
    },
    body2: {
        fontSize: "18px",
        lineHeight: "160%",

        [theme.breakpoints.down("md")]: {
            fontSize: "13px"
        }
    }
});

function Typography(props) {
    const { children, classes, ...restProps } = props;

    return (
        <MuiTypography
            classes={{
                h1: classes.h1,
                h6: classes.h6,
                body2: classes.body2,
                body1: classes.body1,
                h3: classes.h3,
                h2: classes.h2,
                h4: classes.h4,
                h5: classes.h5
            }}
            {...restProps}
        >
            {children}
        </MuiTypography>
    );
}

export default withStyles(styles)(Typography);
