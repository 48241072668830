import React, { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAuthentication } from "@features/user";
import UserCard from "@features/components/profile/userCard";
import LazyNFTCard from "@features/fvtoken/lazyNFTCard";
import { useGetClaimedTokensQuery } from "@app/services";
import InfoItem from "./infoItem";
import moment from "moment";

const ProfileOrdersPage = () => {
    const auth = useAuthentication();
    const navigate = useNavigate();

    const data = useGetClaimedTokensQuery(
        { cuid: auth?.details?.cuid },
        { skip: !auth?.details?.cuid }
    );
    
    const sortedData = useMemo(() => {
        if(!data?.currentData) return []

        return [...data.currentData].sort((a, b) => {
            if(moment(a.UpdatedAt).isAfter(b.UpdatedAt)) {
                return -1
            }
            return 1
        })
    }, [data])

    return (
        <Box>
            {/* <UserCard /> */}
            <Box
                sx={{
                    padding: {
                        xs: 0,
                        md: 3
                    }
                }}>
                <InfoItem />
                <Grid container spacing={2} justifyContent='center'>
                    {sortedData &&
                        [...sortedData]?.reverse().map((item) => (
                            <Grid
                                item
                                key={`lazy_card_${item.lmuid}`}
                                sx={{ justifyContent: "center", alignItems: "center" }}>
                                <LazyNFTCard
                                    clickable
                                    hideIndicator
                                    NFTData={item.metadata}
                                    onClick={() => navigate(`/card-seller?lmuid=${item.lmuid}`)}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ProfileOrdersPage;
