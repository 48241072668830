import { useCallback } from "react";
import { ipfsURIToPublicServer, stripCIDFromURI } from "@app/utils";
import { useLazyFetchQuery } from "@app/services";

function useFetchTokenMetadata() {
    const [fetch, status] = useLazyFetchQuery();
    const fetchToken = useCallback(
        async ({ tokenId, tokenURI, ...any }) => {
            const tokenMeta = await fetch(stripCIDFromURI(tokenURI), true).then(({ data }) => data);
            if (!tokenMeta) {
                return;
            }
            return {
                tokenId,
                ...any,
                ...tokenMeta,
                image: ipfsURIToPublicServer(tokenMeta.image)
            };
        },
        [fetch]
    );
    return [fetchToken, status];
}

export default useFetchTokenMetadata;
