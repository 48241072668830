import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack
} from "@mui/material";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../user";
import { useCallback } from "react";

function InactivityTimeoutDialog() {
    const {
        timeoutInSeconds,
        showTimeoutDialogWhenTimeoutReached,
        timeoutDialogAdditionalTimeInSeconds
    } = useSelector((state) => state.preferences.inactivity);
    const { logout, authenticated } = useAuthentication();
    const inactiveFor = useSelector((state) => state.app.inactivity);
    const [open, setOpen] = useState(false);
    const [logoutTimerId, setLogoutTimerId] = useState();
    const [timeLeft, setTimeLeft] = useState();

    const logoutWithTimeoutMessage = useCallback(
        () => logout("You've been logged out due to inactivity"),
        [logout]
    );

    useEffect(() => {
        if (authenticated && inactiveFor === timeoutInSeconds) {
            console.log("starting logout due to inactivity");
            if (showTimeoutDialogWhenTimeoutReached) {
                if (!open) {
                    console.log("show timeout dialog");
                    let countdown = timeoutDialogAdditionalTimeInSeconds;
                    let intervalId;
                    setTimeLeft(countdown);
                    setOpen(true);
                    intervalId = setInterval(() => {
                        countdown--;
                        if (countdown > 0) {
                            console.log("logout countdown", countdown);
                            setTimeLeft(countdown);
                            return;
                        }
                        setOpen(false);
                        clearInterval(intervalId);
                        logoutWithTimeoutMessage();
                    }, 1000);
                    setLogoutTimerId(intervalId);
                }
            } else {
                logoutWithTimeoutMessage();
            }
        }
    }, [
        open,
        authenticated,
        inactiveFor,
        timeoutInSeconds,
        timeoutDialogAdditionalTimeInSeconds,
        showTimeoutDialogWhenTimeoutReached,
        logoutWithTimeoutMessage
    ]);

    const handleClose = () => {
        clearInterval(logoutTimerId);
        setOpen(false);
    };
    const handleLogout = () => {
        handleClose();
        logout("Goodbye!");
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='inactivity-dialog-title'
            aria-describedby='inactivity-dialog-description'
            fullWidth
        >
            <DialogTitle id='inactivity-dialog-title'>Are you still here?</DialogTitle>
            <DialogContent>
                <DialogContentText id='inactivity-dialog-description'>
                    Due to inactivity we are about to log you out, if you're still here click below.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction='row'>
                    <Button onClick={handleLogout} color='error' variant='contained'>
                        Logout {timeLeft}s
                    </Button>
                    <Button onClick={handleClose} color='success' variant='contained'>
                        I'm still here
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default InactivityTimeoutDialog;
