import dressImage from "../../../../assets/landing-static-cards/dress-image.png";
import arketImage from "../../../../assets/landing-static-cards/arket-image.png";
import stussyImage from "../../../../assets/landing-static-cards/stussy-image.png";
import newBalanceImage from "../../../../assets/landing-static-cards/new-balance-image.png";
import commeImage from "../../../../assets/landing-static-cards/comme-image.png";
import seamlessSculptImage from "../../../../assets/landing-static-cards/seamless-sculpt-image.png";

export const dummyData = [
    {
        brand: "Sony",
        description: "COU COU THE PICOT DRESS",
        image: dressImage
    },
    {
        brand: "Arket",
        description: "Corduroy Overall",
        image: arketImage
    },
    {
        brand: "Stussy",
        description: "Basic Stock Low Pro Cap",
        image: stussyImage
    },
    {
        brand: "New Balance",
        description: "2002R 'Protection Pack - Black...",
        image: newBalanceImage
    },
    {
        brand: "Comme des Garçons",
        description: "PLAY Alternating Hearts Long-S...",
        image: commeImage
    },
    {
        brand: "Seamless-Sculpt",
        description: "Mid Thigh Short",
        image: seamlessSculptImage
    }
];
