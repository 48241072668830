import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "@app/config";

export const formsApi = createApi({
    reducerPath: "formsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: config.services.forms.baseUrl }),
    endpoints: (build) => ({
        getProductAttributesByImuid: build.query({
            query: ({ lmuid, token }) => `forms/listToEbay/${lmuid}?token=${token}`
        }),
        getProductUpdatedAttributesByImuid: build.query({
            query: ({ lmuid, categoryId, token }) =>
                `forms/listToEbay?lmuid=${lmuid}&categoryId=${categoryId}&token=${token}`
        }),
        getEbayIntegrated: build.query({
            query: ({ token }) => `integrations/ebay?token=${token}`
        }),
        getEbayListing: build.query({
            query: ({ lmuid }) => `ebay/listing/${lmuid}`
        }),
        postForm: build.mutation({
            query: (body) => ({
                method: "post",
                url: `forms/listToEbay`,
                body: body.data
            })
        }),
        deleteForm: build.mutation({
            query: ({ lmuid }) => ({
                method: "delete",
                url: `ebay/listing/${lmuid}`
            })
        })
    })
});

export const {
    useGetProductAttributesByImuidQuery,
    useLazyGetProductAttributesByImuidQuery,
    useLazyGetProductUpdatedAttributesByImuidQuery,
    useGetEbayListingQuery,
    usePostFormMutation,
    useDeleteFormMutation,
    useLazyGetEbayIntegratedQuery,
    useGetEbayIntegratedQuery
} = formsApi;
