import React, { useEffect, useState } from "react";

import { Wrappers } from "@features/components";
import { useRestoreStateOnAuthenticatedOrRedirect } from "../router";
import { UserCard } from "./login/userCard";
import { CustomCard } from "./login/customCard";
import { REGISTER, MY_PROFILE, PROFILE_ORDERS } from "../pages.constants";

const RegisterPage = () => {
    useRestoreStateOnAuthenticatedOrRedirect(`${MY_PROFILE}/${PROFILE_ORDERS}`);

    const [lmuid, setLmuid] = useState("");
    useEffect(() => {
        const items = JSON.parse(localStorage?.getItem("lmuid"));
        if (items) {
            setLmuid(items);
        }
    }, [lmuid]);

    return (
        <Wrappers.Container>
            {lmuid ? <UserCard lmuid={lmuid} url={REGISTER} /> : <CustomCard url={REGISTER} />}
        </Wrappers.Container>
    );
};

export default RegisterPage;
