import React, { useEffect, useState } from "react";
import {
    TableCell,
    TableRow,
    Box,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as SaveIcon } from "../save-icon.svg";
import OptionsList from "./OptionsList";
import { Text } from "@features/components";

const styles = (theme) => ({
    editableRow: {
        borderBottom: '1px solid #DDD',

        '& .MuiAccordionSummary-content': {
            maxWidth: "calc(100vw - 39px)"
        },

        [theme.breakpoints.down("md")]: {
            padding: "0 16px"
        }
    },
    error: {
        borderBottom: "1px solid red"
    },
    root: {
        padding: "16px 32px 16px 0px",

        [theme.breakpoints.down("md")]: {
            paddingRight: "16px"
        }
    },
    firstColumn: {
        maxWidth: "180px",
        fontWeight: 600,

        [theme.breakpoints.down("350")]: {
            minWidth: "fit-content"
        }
    },
    secondColumn: {
        maxWidth: '422px'
    },
    lastColumn: {
        padding: "16px 0",
        display: "flex",
        justifyContent: "end"
    }
});

function CollabsibleRow(props) {
    const { classes, secondColumnCell, firstColumnCell, type, handleClick, options, error, resetError } = props;

    const [value, setValue] = useState(secondColumnCell);
    const [opened, setOpened] = useState(false);

    const updateProductOption = () => {
        setOpened(false);

        if(value && value !== 'No value') {
            resetError(firstColumnCell)
        }

        if (handleClick) {
            handleClick(type, value === 'No value' ? '' : value);
        }
    };

    useEffect(() => {
        setValue(secondColumnCell || 'No value')
    }, [secondColumnCell])


    return (
        <Grid container key={type} className={`${classes.editableRow} ${error ? classes.error : ""}`}>
            <Box
                sx={{
                    display: "flex",
                    flexGrow: 2,

                    "& .MuiAccordion-root": {
                        background: "transparent",
                        display: "flex",
                        flexGrow: 2,
                        flexDirection: "column",
                        boxShadow: "none"
                    },
                    "& .MuiAccordionSummary-root": {
                        padding: 0,
                        display: "flex",
                        flexGrow: 2
                    },
                    "& .MuiAccordionSummary-content": {
                        margin: "0 !important"
                    },
                    "& .MuiAccordionDetails-root": {
                        padding: 0
                    }
                }}>
                <Accordion expanded={opened} square>
                    <AccordionSummary>
                        <Grid item xs={4} classes={{ root: classes.root }}>
                            <Text.Typography variant='body2' className={classes.firstColumn}>
                                {firstColumnCell}
                            </Text.Typography>
                        </Grid>
                        <Grid item xs={6} md={7} classes={{ root: classes.root }}>
                            <Text.Typography variant='body2' className={classes.secondColumn}>
                                <Tooltip title={value} arrow>
                                    <div
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap"
                                        }}>
                                        {value}
                                    </div>
                                </Tooltip>
                            </Text.Typography>
                        </Grid>
                        <Grid item xs={2} md={1} classes={{ root: classes.lastColumn }}>
                            {opened ? (
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                        paddingTop: "0 !important"
                                    }}>
                                    <Text.Typography
                                        variant='body2'
                                        onClick={() => updateProductOption()}
                                        sx={{ mr: 1, textDecoration: "underline" }}>
                                        save
                                    </Text.Typography>
                                    <SaveIcon onClick={() => updateProductOption()} />
                                </Box>
                            ) : (
                                <Box
                                    style={{
                                        cursor: "pointer",
                                        ml: 5,
                                        paddingLeft: "50px",
                                        paddingTop: 0,
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    onClick={() => setOpened(true)}>
                                    <ArrowDown />
                                </Box>
                            )}
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <OptionsList options={options} onSelect={setValue} />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Grid>
    );
}

 export default withStyles(styles)(CollabsibleRow);
