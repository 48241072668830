import React from "react";
import { withStyles } from "@mui/styles";
import { InputLabel, Select, Box } from "@mui/material";

const styles = (theme) => ({
    label: {
        fontSize: "14px",
        color: "#111111",
        lineHeight: "170%",
        fontWeight: 400
    }
});

function Dropdown(props) {
    const {
        children,
        classes,
        label,
        helperText,
        onChange,
        defaultValue,
        value,
        disabled,
        fullwidth,
        ...restProps
    } = props;

    return (
        <Box sx={{ width: "fill-available" }} {...restProps}>
            <div>
                <InputLabel shrink classes={{ root: classes.label }}>
                    {label}
                </InputLabel>
            </div>
            <div>
                <Select
                    disabled={disabled}
                    fullWidth={fullwidth}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={onChange}
                    inputProps={{ "aria-label": "Without label" }}
                >
                    {children}
                </Select>
            </div>
        </Box>
    );
}

export default withStyles(styles)(Dropdown);
