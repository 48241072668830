import { configureStore, combineReducers, createSlice } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";

// Notification center slices
import snackbarAlertReducer from "@features/notifications/popups/snackbar.slice";
import walletReducer from "@features/wallet/wallet.slice";

// Feature slices
import preferencesReducer from "../preferences.slice";
import filterSlice from "../filter.slice";
import cardSlice from "../card.slice";
import userReducer from "@features/user/user.slice";
import appReducer from "../app.slice";

// FetchBasedQuerys - RTK API Slices

import { uploadProductApi } from "@app/services";
import { cardHistoryApi } from "@app/services";

// FetchBasedQuerys - RTK API Slices
import { userAPI } from "@app/services";
import { alertsAPI } from "@app/services";
import authenticationAPI from "@app/services/authenticationAPI";
import chainListAPI from "@app/services/chainListAPI";
import ipfsAPI from "@app/services/ipfsAPI";
import tokenAPI from "@app/services/tokenAPI";
import golangAPI from "@app/services/golangAPI";
import cardAPI from "@app/services/cardAPI";
import uploadImageAPI from "@app/services/uploadImageAPI";
import orderAPI from "@app/services/orderAPI";

import { stripeApi } from "@app/services";
import config from "../config";
import { formsApi } from "@app/services/formsAPI";

const versionReducer = createSlice({
    name: "version",
    initialState: config.version
}).reducer;

const reducers = combineReducers({
    preferences: preferencesReducer,
    app: appReducer,
    filter: filterSlice,
    card: cardSlice,
    user: userReducer,
    snackbarAlert: snackbarAlertReducer,
    wallet: walletReducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [authenticationAPI.reducerPath]: authenticationAPI.reducer,
    [alertsAPI.reducerPath]: alertsAPI.reducer,
    [chainListAPI.reducerPath]: chainListAPI.reducer,
    [ipfsAPI.reducerPath]: ipfsAPI.reducer,
    [tokenAPI.reducerPath]: tokenAPI.reducer,
    [golangAPI.reducerPath]: golangAPI.reducer,
    [uploadImageAPI.reducerPath]: uploadImageAPI.reducer,
    [uploadProductApi.reducerPath]: uploadProductApi.reducer,
    [cardHistoryApi.reducerPath]: cardHistoryApi.reducer,
    [cardAPI.reducerPath]: cardAPI.reducer,
    [orderAPI.reducerPath]: orderAPI.reducer,
    version: versionReducer,
    [stripeApi.reducerPath]: stripeApi.reducer
});

export const RESET = "reset";
const rootReducer = (state, action) => {
    if (action.type === RESET) state = undefined;

    return reducers(state, action);
};

const combinedMiddlewares = [
    authenticationAPI.middleware,
    alertsAPI.middleware,
    chainListAPI.middleware,
    formsApi.middleware,
    ipfsAPI.middleware,
    tokenAPI.middleware,
    golangAPI.middleware,
    uploadImageAPI.middleware,
    cardAPI.middleware,
    uploadProductApi.middleware,
    orderAPI.middleware,
    cardHistoryApi.middleware,
    stripeApi.middleware
];

const persistConfig = {
    key: "rt-nft-mp", // react-template-nft-marketplace
    storage,
    whitelist: [
        "preferences", // persist user preferences
        "user", // persist user session (jwt)
        // authenticationAPI.reducerPath,            // persist user data
        chainListAPI.reducerPath, // persist results from chainlist network list
        // tokenAPI.reducerPath,                     // persist results from ipfs - needs a little work to refetch if response errors out.
        // ipfsAPI.reducerPath,
        "version",
        "token",
        "card"
    ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            },
            ignoredPaths: ["ipfsAPI.queries.uploadFile({}).originalArgs"]
        }).concat(combinedMiddlewares);
        return middlewares;
    }
});
const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };
