import React, { useEffect } from "react";
import { Box, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Cards } from "../components";
import Web3 from "web3";
import { useWeb3 } from "../web3";
import { useState } from "react";

function RoyaltiesEarnedTable({ topAdornment, royalties, hideBuyer, showRecipient }) {
    const { network } = useWeb3();
    const [explorer, setExplorer] = useState(null);

    useEffect(() => {
        if (network && network.explorers && network.explorers.length > 0) {
            setExplorer(network.explorers[0]);
        }
    }, [network]);

    console.log({ network });
    return (
        <Cards.Curvy style={{ padding: 0, margin: 0 }}>
            <CardContent style={{ padding: 0 }}>
                <Box p={4}>{topAdornment}</Box>
                <Table aria-label='Royalty Table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>TokenId</TableCell>
                            {!hideBuyer ? <TableCell>Buyer Address</TableCell> : ""}
                            {showRecipient ? <TableCell>Recipient Address</TableCell> : ""}
                            <TableCell>Royalties Paid</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Etherscan</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {royalties.map(
                            (
                                { tokenId, buyer, reciever, amount, timestamp, transactionHash },
                                i
                            ) => (
                                <TableRow key={`royalty_${timestamp}_${i}`}>
                                    <TableCell component='th' scope='row'>
                                        {tokenId}
                                    </TableCell>
                                    {!hideBuyer ? <TableCell>{buyer}</TableCell> : ""}
                                    {showRecipient ? <TableCell>{reciever}</TableCell> : ""}
                                    <TableCell>{Web3.utils.fromWei(amount, "ether")}</TableCell>
                                    <TableCell>
                                        {new Date(parseInt(timestamp, 10) * 1000).toISOString()}
                                    </TableCell>
                                    {explorer ? (
                                        <TableCell>
                                            <a
                                                href={`${explorer.url}/tx/${transactionHash}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                View Transaction
                                            </a>
                                        </TableCell>
                                    ) : (
                                        ""
                                    )}
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Cards.Curvy>
    );
}

export default RoyaltiesEarnedTable;
