import { Text } from "@features/components";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Heading from "../heading";
import useDimensions from "../hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    container: {
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "0 auto",
        gap: "140px",

        "@media screen and (max-width: 1435px)": {
            gap: "80px",
        },

        [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse",
            background: "#F5F5F5",
            gap: "24px",
            padding: "48px 16px",
            borderRadius: "16px"
        },

        "&:first-child": {
            "& img": {
                marginRight: "-21px"
            }
        }
    },
    image: {
        maxWidth: "600px",
        maxHeight: "600px",

        [theme.breakpoints.down("md")]: {
            maxWidth: "calc(100vw - 64px)"
        }
    },
    textContainer: {
        [theme.breakpoints.down("sm")]: {
            alignItems: "center"
        }
    },
    title: {
        fontSize: "46px !important",

        "@media screen and (max-width: 1455px)": {
            fontSize: "42px !important"
        },

        "@media screen and (max-width: 1160px)": {
            fontSize: "34px !important"
        },

        [theme.breakpoints.down("md")]: {
            fontSize: "28px !important"
        }
    }
}));

function StepItem(props) {
    const classes = useStyles();
    const { isMobile } = useDimensions();
    const { title, description, image, step, reversed } = props;

    return (
        <Stack direction={reversed ? "row-reverse" : "row"} className={classes.container}>
            <img src={image} alt={step} className={classes.image} />
            <Stack spacing={4}>
                <Stack spacing={1} className={classes.textContainer}>
                    <Text.Typography variant='body2'>Step {step}</Text.Typography>
                    <Heading
                        title={title}
                        description={description}
                        subheading
                        leftAlignment={!isMobile}
                        titleClassName={classes.title}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}

export default StepItem;
