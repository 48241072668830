import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    description: "",
    price: 0,
    imagePath: "",
    lmuid: ""
};

const cardSlice = createSlice({
    name: "card",
    initialState,
    reducers: {
        setName: (state, { payload: { name } }) => {
            state.name = name;
        },
        setDescription: (state, { payload: { description } }) => {
            state.description = description;
        },
        setPrice: (state, { payload: { price } }) => {
            state.price = price;
        },
        setImagePath: (state, { payload: { imagePath } }) => {
            state.imagePath = imagePath;
        },
        setLmuid: (state, { payload: { lmuid } }) => {
            state.lmuid = lmuid;
        },
        setSellerId: (state, { payload: { cuid } }) => {
            state.cuid = cuid;
        }
    }
});

export const { setName, setDescription, setPrice, setImagePath, setLmuid, setSellerId } =
    cardSlice.actions;
export default cardSlice.reducer;
