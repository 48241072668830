import React, { useCallback } from "react";
import { withStyles } from "@mui/styles";
import { OutlinedInput, InputLabel, FormControl, Box, FormHelperText } from "@mui/material";

const styles = (theme) => ({
    root: {
        height: "61px",

        [`& .MuiOutlinedInput-notchedOutline`]: {
            borderColor: "#ddd"
        },
        "& input[type=number]": {
            "-moz-appearance": "textfield"
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        }
    },
    label: {
        fontSize: "14px",
        color: "#111111",
        lineHeight: "170%",
        fontWeight: 400
    },
    innerLabel: {
        fontSize: "18px",
        color: "#7E7E7E",
        lineHeight: "170%",
        position: "absolute",
        top: "8px",
        left: "16px"
    }
});

function InputField(props) {
    const {
        children,
        classes,
        label,
        helperText,
        innerLabel,
        fullwidth,
        onlyLabel,
        type,
        ...restProps
    } = props;

    const validateNumbers = useCallback((e) => {
        if (["e", "-", "+", ","].includes(e.key)) {
            e.preventDefault();
        } else if (!e.target.value.length && e.key === ".") {
            e.preventDefault();
        }
    }, []);

    return (
        <Box
            sx={{
                width: "fill-available",
                position: innerLabel ? "relative" : null,
                height: "inherit"
            }}
            {...restProps}
        >
            <InputLabel shrink classes={{ root: innerLabel ? classes.innerLabel : classes.label }}>
                {label}
            </InputLabel>
            {!onlyLabel ? (
                <OutlinedInput
                    className={classes.root}
                    fullWidth={fullwidth}
                    sx={{
                        pt: innerLabel ? "16px" : 0
                    }}
                    type={type}
                    onKeyPress={type === "number" && validateNumbers}
                    {...restProps}
                >
                    {children}
                </OutlinedInput>
            ) : (
                <>{children}</>
            )}
            {helperText && (
                <FormHelperText id='my-helper-text' sx={{ color: "#d32f2f" }}>
                    {helperText}
                </FormHelperText>
            )}
        </Box>
    );
}

export default withStyles(styles)(InputField);
