import React from "react";
import {
    TableRow,
    TableCell,
    Table,
    Collapse,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as DropdownIcon } from "./icondropdown.svg";
import { Text } from "..";

const styles = {
    root: {
        border: "1px solid #DDDDDD",
        boxShadow: "none"
    },
    firstColumn: {
        fontWeight: 600
    },
    grayBg: {
        backgroundColor: "#F7F7F7"
    },
    caption: {
        display: "flex",
        padding: "16px",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #DDDDDD"
    }
};

function CollapsibleTable(props) {
    const { classes, data, ...restProps } = props;
    const [open, setOpen] = React.useState(true);
    return (
        <TableContainer component={Paper} {...restProps} classes={{ root: classes.root }}>
            <caption className={classes.caption}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DropdownIcon width={"28px"} />
                    <Text.Typography variant='h4' ml={2}>
                        {" "}
                        History
                    </Text.Typography>
                </Box>

                <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                    sx={{ color: "#111111" }}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </caption>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Price</TableCell>
                            <TableCell align='center'>Date</TableCell>
                            <TableCell align='center'>Username</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody aria-label='collapsible table'>
                        {data?.map((item, i) => {
                            const time = item?.data?.timestamp || item?.timestamp;
                            const priceAndCurrency = item?.data?.price?.value
                                ? `${item?.data?.price?.value}
                             ${item?.data?.price?.unit}`
                                : "";
                            const userName =
                                item?.data?.by?.username || item?.data?.seller?.username;
                            const date = new Date(time * 1000).toLocaleDateString(undefined, {
                                day: "2-digit",
                                month: "short",
                                year: "numeric"
                            });

                            return (
                                <TableRow
                                    key={item?.csuid}
                                    className={`${i % 2 === 0 ? classes.grayBg : ""}`}
                                >
                                    <TableCell>
                                        <b>{priceAndCurrency}</b>
                                    </TableCell>
                                    <TableCell align='center'>{date}</TableCell>
                                    <TableCell align='center'>{userName}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Collapse>
        </TableContainer>
    );
}

export default withStyles(styles)(CollapsibleTable);
