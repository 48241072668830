import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categoryID: 0,
    searchString: "",
    filteredItemsQuantity: 0
};

const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setCategoryID: (state, { payload: { categoryID } }) => {
            state.categoryID = categoryID;
        },
        setSearchString: (state, { payload: { searchString } }) => {
            state.searchString = searchString;
        },
        setFilteredItemsQuantity: (state, { payload: { filteredItemsQuantity } }) => {
            state.filteredItemsQuantity = filteredItemsQuantity;
        }
    }
});

export const { setCategoryID, setSearchString, setFilteredItemsQuantity } = filterSlice.actions;
export default filterSlice.reducer;
