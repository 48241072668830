import { useEffect, useRef } from "react";

// For setting intervals and clearing them automatically
export function useInterval(callback, delay) {
    const savedCalledback = useRef();
    // Remeber the latest callback.
    useEffect(() => {
        savedCalledback.current = callback;
    }, [callback]);

    // Setup the interval.
    useEffect(() => {
        function tick() {
            savedCalledback.current();
        }
        if (delay !== null) {
            tick();
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
}
