import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    CardContent,
    Table,
    TableBody,
    TableContainer,
    CardActions,
    Stack
} from "@mui/material";

import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { useAuthentication } from "@features/user";
import { addLoading, removeLoading, clearLoginReturn } from "../../app.slice";
import { useClaimLazyMintMutation, useClaimLazyMutation } from "@app/services";
import { MY_PROFILE, PROFILE_ORDERS } from "../../pages.constants";
import { Cards, Text, Button, Tables } from "@features/components";
import { utils } from "../../utils";
import { useCreateCardMutation } from "@app/services";

function ClaimForm({ NFTData, setStatus, blockedStatus }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [createCardTrigger] = useCreateCardMutation();

    const { search } = useLocation();
    const { details } = useAuthentication();

    const { tokenData } = NFTData;
    const [loadingStatus, setLoadingStatus] = useState(false);

    const [
        claimMintToken,
        { isLoading: isMintLoad, isSuccess: claimMintedSuccess, error: claimMintError }
    ] = useClaimLazyMintMutation();
    const [
        lazyClaimToken,
        { isLoading: isLazyMintLoad, isSuccess: lazyClaimSuccess, error: lazyClaimError }
    ] = useClaimLazyMutation();

    const lmuid = useMemo(() => new URLSearchParams(search).get("lmuid"), [search]);
    const errorStatus = claimMintError || lazyClaimError;
    const successStatus = lazyClaimSuccess || claimMintedSuccess;

    const addClaimedCard = useCallback(() => {
        const cardData = {
            lmuid,
            userId: details.cuid,
            title: tokenData?.custodial?.name
        };

        createCardTrigger(JSON.stringify(cardData))
            .unwrap()
            .catch(() => {
                dispatch(
                    showSnackbarAlert({
                        message:
                            "Sorry, We've been unable to claim this token right now. Please try again",
                        severity: "error"
                    })
                );
            });
    }, [details, lmuid, createCardTrigger]);

    const handleClaimAndMint = async () => {
        if (details?.cuid) {
            dispatch(addLoading({ id: "page" }));
            await claimMintToken({ lmuid, cuid: details.cuid });
            dispatch(removeLoading({ id: "page" }));
            dispatch(clearLoginReturn());

            addClaimedCard();
        }
    };

    const handleLazyClaim = async () => {
        if (details?.cuid) {
            dispatch(addLoading({ id: "page" }));
            await lazyClaimToken({ lmuid, cuid: details.cuid });
            dispatch(removeLoading({ id: "page" }));
            dispatch(clearLoginReturn());

            addClaimedCard();
        }
    };

    useEffect(() => {
        if (!tokenData.custodial.status.toLowerCase() === "CREATED" || successStatus) {
            setStatus(false);
        }
    }, [tokenData, claimMintedSuccess, claimMintError, lazyClaimSuccess, lazyClaimError]);

    useEffect(() => {
        setLoadingStatus(isMintLoad || isLazyMintLoad);
    });

    useEffect(() => {
        const err = claimMintError || lazyClaimError;
        if (err) {
            console.log(err);
            let message =
                "Sorry, We've been unable to claim this token right now. Please try again";
            if (err.data && err.data.cause) {
                message = err.data.cause;
            }
            dispatch(
                showSnackbarAlert({
                    message,
                    severity: "error"
                })
            );
        }
        if (claimMintedSuccess || lazyClaimSuccess) {
            dispatch(
                showSnackbarAlert({
                    message: "You've successfully claimed your token!",
                    severity: "success"
                })
            );
        }
    }, [dispatch, navigate, claimMintError, lazyClaimError, claimMintedSuccess, lazyClaimSuccess]);

    const cardOptions = useMemo(
        () => utils.getCardAttributes(tokenData.metadata.attributes),
        [tokenData.metadata.attributes]
    );

    return (
        <>
            <CardContent>
                <Text.Typography variant='h2' mb={2}>
                    {tokenData.metadata.name || ""}
                </Text.Typography>
                <Box
                    pt={4}
                    pb={2}
                    sx={{
                        borderColor: "#DDDDDD",
                        borderStyle: "solid",
                        borderWidth: "1px 0"
                    }}
                >
                    <Text.Typography variant='h4' mb={2}>
                        Details
                    </Text.Typography>
                    <TableContainer>
                        <Table
                            aria-label='caption table'
                            sx={{ tableLayout: "auto", width: "max-content" }}
                        >
                            <TableBody>
                                {cardOptions?.map(({ trait_type, value }) => (
                                    <Tables.RowTable
                                        key={trait_type}
                                        secondColumnCell={value}
                                        firstColumnCell={trait_type}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box>
                    <Text.Typography variant='h3' mt={3} mb={3}>
                        Unlimitid Wallet
                    </Text.Typography>
                    <Box my={4}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur.
                    </Box>
                </Box>
            </CardContent>

            <CardActions
                sx={{
                    padding: "0 16px",
                    mb: {
                        xs: 9,
                        md: 0
                    }
                }}
            >
                <Stack direction='row' spacing={2} width='100%'>
                    {blockedStatus || errorStatus ? (
                        <>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleClaimAndMint}
                                loading={loadingStatus}
                                size='large'
                                style={{ width: "100%", textTransform: "inherit" }}
                            >
                                Claim Digital Card
                            </Button>

                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleLazyClaim}
                                loading={loadingStatus}
                                size='large'
                                style={{ width: "100%", textTransform: "inherit" }}
                            >
                                Lazy Claim
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            onClick={() => navigate(`${MY_PROFILE}/${PROFILE_ORDERS}`)}
                            style={{ width: "100%", textTransform: "inherit" }}
                        >
                            Go to Profile
                        </Button>
                    )}
                </Stack>
            </CardActions>
        </>
    );
}

export default ClaimForm;
