import { useSelector } from "react-redux";

function useCardData() {
    const cardName = useSelector((state) => state.card.name);
    const cardDescription = useSelector((state) => state.card.description);
    const cardPrice = useSelector((state) => +state.card.price);
    const cardImagePath = useSelector((state) => state.card.imagePath);
    const cardSeller = useSelector((state) => state.card.cuid);

    return {
        cardName,
        cardDescription,
        cardPrice,
        cardImagePath,
        cardSeller
    };
}

export default useCardData;
