import React from "react";
import { Wrappers } from "@features/components";
import HeroSection from "./heroSection";
import CardList from "./card-list/cardList";
import StepList from "./step-list/stepList";
import LandingFooter from "./landingFooter";
import CollabsibleRow from "@features/components/tables/CollabsibleRow/CollabsibleRow";
import TableDetails from "../card/tableDetails";
import { Box } from "@mui/material";

const IndexPage = () => {
    return (
        <Box>
            <Wrappers.Container>
                <HeroSection />
                <CardList />
                <StepList />
                {/* <LandingFooter /> */}
            </Wrappers.Container>
        </Box>
    );
};

export default IndexPage;
