import config from "../../../config";
import uploadImageAPI from "../../uploadImageAPI";

const uploadImageServiceWithTag = uploadImageAPI.enhanceEndpoints({ addTagTypes: ["Image"] });

export const uploadImageService = uploadImageServiceWithTag.injectEndpoints({
    endpoints: (build) => ({
        getAllImages: build.query({
            query: (cardId) => ({
                method: "get",
                url: `/item/${cardId}/image/all`
            }),
            providesTags: ["Image"]
        }),
        getPolicy: build.query({
            query: () => ({
                method: "get",
                url: `/aws/policy`
            })
        }),
        uploadToAWS: build.mutation({
            query: (body) => ({
                method: "post",
                url: config.services.card.aws_s3_bucket,
                body
            })
        }),
        saveImage: build.mutation({
            query: (body) => ({
                method: "post",
                url: `/item/${body.cardId}/image/save`,
                body: body.data
            }),
            invalidatesTags: ["Image"]
        }),
        deleteImage: build.mutation({
            query: (body) => ({
                method: "post",
                url: `/item/${body.cardId}/image/${body.uuid}/delete`,
                body: body.data
            }),
            invalidatesTags: ["Image"]
        })
    }),
    overrideExisting: true
});

export const {
    useGetAllImagesQuery,
    useLazyGetAllImagesQuery,
    useLazyGetPolicyQuery,
    useGetPolicyQuery,
    useUploadToAWSMutation,
    useSaveImageMutation,
    useDeleteImageMutation
} = uploadImageService;
