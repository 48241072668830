import { Grid, Stack, TablePagination, Card } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setResultsPerPage } from "@app/preferences.slice";
import useDialog from "../components/dialogs/useDialog";
import LazyNFTCard from "./lazyNFTCard";
import { NFTCardDetailDialog } from "./NFTCard";
import { setFilteredItemsQuantity } from "@app/filter.slice";
import { useGetListingsV2Query } from "@app/services";

export function PaginationGallery() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { resultsPerPage } = useSelector((state) => state.preferences.pagination);
    const categoryID = useSelector((state) => state.filter.categoryID);
    const searchString = useSelector((state) => state.filter.searchString);
    const [page, setPage] = useState(0);
    const { open, closeDialog, openDialog, data } = useDialog();
    const { data: marketListings } = useGetListingsV2Query(null);

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            setResultsPerPage({
                resultsPerPage: parseInt(event.target.value, 10)
            })
        );
        setPage(0);
    };

    const handlePageChange = (_, page) => {
        setPage(page);
    };

    const results = useMemo(() => {
        const filtered = marketListings?.filter(
            ({ price, tokenData }) => tokenData?.custodial?.lmuid || price
        );

        if (searchString && marketListings) {
            const filteredNFTs = marketListings.filter((el) => {
                if (!el?.tokenData?.custodial || el?.price) {
                    return false;
                }

                let { name, description } = el.tokenData.custodial;

                name = name.toLowerCase();
                description = description.toLowerCase();

                if (
                    name.includes(searchString.toLowerCase()) ||
                    description.includes(searchString.toLowerCase())
                ) {
                    return true;
                }

                return false;
            });

            const slicedNFTs = filteredNFTs.slice(
                resultsPerPage * page,
                resultsPerPage * (page + 1)
            );

            dispatch(
                setFilteredItemsQuantity({
                    filteredItemsQuantity: slicedNFTs.length
                })
            );

            return slicedNFTs;
        }
        return filtered?.slice(resultsPerPage * page, resultsPerPage * (page + 1));
    }, [marketListings, resultsPerPage, page, searchString]);

    return (
        <Stack direction='column' spacing={2} py={5}>
            <Grid container spacing={2} justifyContent='center'>
                {results?.map((NFTData) => (
                    <Grid item key={`paginated_nfts_${NFTData.tokenData.lmuid}`}>
                        <LazyNFTCard
                            hideIndicator
                            NFTData={NFTData.tokenData.metadata}
                            clickable
                            onClick={() => {
                                navigate(`/card?lmuid=${NFTData?.tokenData?.lmuid}`);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <TablePagination
                component='div'
                count={marketListings?.length}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={resultsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 20, 25, 50, 75, 100]}
                sx={{
                    overflow: "initial !important",
                    [`& .MuiTablePagination-toolbar`]: {
                        maxWidth: {
                            xs: "100vw",
                            md: "initial"
                        },
                        flexWrap: {
                            xs: "wrap",
                            md: "initial"
                        },
                        justifyContent: {
                            xs: "center",
                            md: "initial"
                        }
                    }
                }}
            />
            <NFTCardDetailDialog {...data} open={open} onClose={closeDialog} fullWidth />
        </Stack>
    );
}
