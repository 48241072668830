import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link, Divider, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

import { Text, Button } from "@features/components";
import CardField from "./cardField";
import DeliveryField from "./deliveryField";
import useCardData from "@features/fvtoken/hooks/useCardData";
import { useAuthentication } from "@features/user";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import {
    useGetListStatusByIdQuery,
    useBuyListingMutation,
    useCreateOrderMutation
} from "@app/services";
import { MY_PROFILE, PROFILE_ORDERS } from "@app/pages.constants";

import { styles } from "./checkout.styles";

const FormContainer = ({ classes, lmuid, cardData }) => {
    const { details } = useAuthentication();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [cardError, setCardError] = useState("");
    const [createOrderTrigger, { error, isLoading: loadingOrderTrigger }] =
        useCreateOrderMutation();
    const { cardPrice, cardSeller } = useCardData();
    // get list for sale nft status
    const { data: marketListingsStatus } = useGetListStatusByIdQuery({ lmuid }, { skip: !lmuid });
    const [lazyBuy, { isLoading: loadBuyNFT, isSuccess }] = useBuyListingMutation();

    const {
        formState: { errors, isDirty, isValid },
        handleSubmit,
        control
    } = useForm({ mode: "onChange" });

    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        if (isSuccess) {
            navigate(`${MY_PROFILE}/${PROFILE_ORDERS}`);
        }
    }, [isSuccess]);

    const handleBuy = async (lmluid, price) => {
        return await lazyBuy({ lmluid, price }).catch((error) => {
            console.log(error);
        });
    };

    const secure = async (clientSecret) => {
        const card = elements.getElement(CardNumberElement);

        const { paymentIntent, error } = await stripe.confirmCardPayment(
            clientSecret, // The client secret of the PaymentIntent that was created on the server
            {
                payment_method: { card: card }
            },
            {
                handleActions: true // Set to false to handle the 3D Secure authentication flow manually
            }
        );

        if (error.message) {
            dispatch(
                showSnackbarAlert({
                    message: error.message,
                    severity: "error"
                })
            );
        }

        if (paymentIntent.status === "succeeded") {
            const { lmluid } = marketListingsStatus;
            handleBuy(lmluid, cardPrice);
        }
    };

    const onSubmit = async (data) => {
        const card = elements.getElement(CardNumberElement);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: card
        });
        if (!card || error) {
            setCardError(error || "");
            console.error("Card element is not mounted");
            return;
        }
        stripe
            .createToken(card)
            .then((token) => {
                console.log("Received Stripe token:", token);
            })
            .catch((error) => {
                console.error("Error creating Stripe token:", error);
            });

        if (!cardData) {
            dispatch(
                showSnackbarAlert({
                    message: "Failed to get token information",
                    severity: "error"
                })
            );
        }

        const createOrderData = {
            checksum: cardPrice,
            total: {
                final: cardPrice
            },
            sellerUuid: cardSeller,
            buyerUuid: details?.cuid,
            firstName: data?.first_name,
            lastName: data?.last_name,
            addressLineOne: data?.first_address,
            addressLineTwo: data?.second_address,
            town: data?.city,
            postcode: data?.postcode,
            countryCode: "gb",
            currency: "gbp",
            paymentMethodId: paymentMethod.id,
            itemUuid: cardData?.data?.uuid,
            itemPrice: cardPrice,
            shippingPrice:
                +cardData?.data?.money?.internationalShippingPrice +
                    +cardData?.data?.money?.domesticShippingPrice || 0
        };
        createOrderTrigger({
            body: createOrderData,
            userId: details?.cuid
        })
            .unwrap()
            .then(() => {
                const { lmluid } = marketListingsStatus;
                handleBuy(lmluid, cardPrice);
            })
            .catch((error) => {
                if (error?.data?.extra?.clientSecret) secure(error?.data?.extra?.clientSecret);
                if (!error?.data?.extra?.clientSecret) {
                    dispatch(
                        showSnackbarAlert({
                            message: error.data.message,
                            severity: "error"
                        })
                    );
                }
            });
    };

    return (
        <form id='payment-form' onSubmit={handleSubmit(onSubmit)}>
            <Text.Typography variant='h3' mt={4}>
                Delivery Details
            </Text.Typography>
            <Divider sx={{ marginTop: "16px", marginBottom: "40px" }} />
            {/* Delivery card */}
            <DeliveryField control={control} errors={errors} />
            {/* Payment card */}
            <Text.Typography variant='h3' mt={4} className={classes.mobilePadding}>
                Payment Details
            </Text.Typography>
            <Divider sx={{ marginTop: "16px", marginBottom: "40px" }} />
            <FormGroup>
                <div id='card-element' />
                <div id='card-errors' role='alert' />
                <CardField cardError={cardError} errors={errors} control={control} />
                <FormControlLabel
                    sx={{ marginTop: "18px", marginBottom: "18px" }}
                    control={<Checkbox />}
                    label={
                        <Text.Typography variant='h5'>
                            Billing address is the same as delivery
                        </Text.Typography>
                    }
                />
                <FormControlLabel
                    sx={{ marginBottom: "18px" }}
                    control={<Checkbox />}
                    label={
                        <Text.Typography variant='h5'>
                            I agree to unlimitid’s <Link href='/'>Terms of Use</Link> and{" "}
                            <Link href='/'>Terms of Purchase</Link>.
                        </Text.Typography>
                    }
                />
            </FormGroup>
            <Button
                loading={loadingOrderTrigger || loadBuyNFT}
                disabled={!isDirty || !isValid}
                type='submit'
                variant='contained'
                color='primary'
                size='large'
                className={classes.confirmButton}
            >
                Complete purchase
            </Button>
        </form>
    );
};

export default withStyles(styles)(FormContainer);
