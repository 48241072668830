export const styles = (theme) => ({
    container: {
        padding: "0 152px",

        [theme.breakpoints.down("md")]: {
            padding: 0
        }
    },
    cardCurvy: {
        padding: "40px",
        minWidth: "fit-content",

        [theme.breakpoints.down("md")]: {
            padding: "25px"
        }
    },
    cardMedia: {
        width: "192px",
        borderRadius: "8px",
        cursor: "pointer",
        border: "1px solid #DDDDDD",
        height: "192px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "64px",
            height: "64px"
        }
    },
    mobilePadding: {
        paddingRight: "56px",

        [theme.breakpoints.down("md")]: {
            padding: "0 4%"
        }
    }
});
