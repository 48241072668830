import config from "../config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: config.stripe.stripeAPIOrderUrl,
    prepareHeaders: (headers) => {
        return headers;
    }
});

export default createApi({
    reducerPath: "orderAPI",
    baseQuery,
    endpoints: () => ({})
});
