import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { useFVMarketplace, useRoyaltiesPaidEvents } from "@features/fvmarketplace";
import RoyaltiesEarnedTable from "@features/royalties/royaltiesEarnedTable";
import WalletConnectButton from "@features/wallet/walletConnectButton";
import { useWeb3 } from "@features/web3";
import MainNavigation from "../../navigations";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { Text } from "@features/components";

export function FVWalletRoyaltyPage() {
    return (
        <MainNavigation>
            <FVRoyaltyTable />
        </MainNavigation>
    );
}

export function UserWalletRoyaltyPage() {
    return (
        <MainNavigation>
            <UserRoyaltyTable />
        </MainNavigation>
    );
}
export function AllRoyaltyTransactionsPage() {
    return (
        <MainNavigation>
            <AllRoyaltyTransactionsTable />
        </MainNavigation>
    );
}

export function AllRoyaltiesPage() {
    return (
        <MainNavigation>
            <AllRoyaltiesTable />
        </MainNavigation>
    );
}

function UserRoyaltyTable() {
    const dispatch = useDispatch();
    const { account } = useWeb3();
    const { hasContract, payments, withdrawPayments, getTotalRoyaltiesEarned } = useFVMarketplace();
    const [totalEarned, setTotalEarned] = useState();
    const [balanceAvailable, setBalanceAvailable] = useState();
    const [royalties, setRoyalties] = useState([]);
    const royaltiesPaidEvents = useRoyaltiesPaidEvents();

    useEffect(() => {
        if (hasContract && account) {
            getTotalRoyaltiesEarned(account).then(setTotalEarned);
            if (royaltiesPaidEvents) {
                setRoyalties(
                    royaltiesPaidEvents
                        .map(({ returnValues, transactionHash }) => ({
                            ...returnValues,
                            transactionHash
                        }))
                        .filter(({ reciever }) => reciever.toLowerCase() === account.toLowerCase())
                );
            }
            payments(account).then(setBalanceAvailable);
        }
    }, [hasContract, account, payments, getTotalRoyaltiesEarned, royaltiesPaidEvents]);

    const { earned, available } = useMemo(() => {
        const earned = totalEarned || "0";
        const available = balanceAvailable || "0";

        return {
            earned: Web3.utils.fromWei(earned, "ether"),
            available: Web3.utils.fromWei(available, "ether")
        };
    }, [totalEarned, balanceAvailable]);

    const withdrawFunds = () => {
        withdrawPayments(account).then(() => {
            dispatch(
                showSnackbarAlert({
                    message: `${available} was withdrawn to ${account}.`,
                    severity: "success"
                })
            );
            setBalanceAvailable();
        });
    };

    if (!account) {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80vh"
                }}
            >
                <Stack spacing={2} textAlign='center'>
                    <Typography variant='body1' color='text.secondary'>
                        Wallet is disconnected
                    </Typography>
                    <WalletConnectButton color='error' size='xl' sx={{ paddingX: 5 }} />
                </Stack>
            </Box>
        );
    }

    return (
        <Stack spacing={2}>
            <RoyaltiesEarnedTable
                royalties={royalties}
                topAdornment={
                    <React.Fragment>
                        <Text.DashHeader variant='h5' color='black'>
                            My Royalties
                        </Text.DashHeader>
                        <Stack spacing={2}>
                            <Typography variant='body2' color='text.secondary'>
                                Royalties within: ({account})
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                Total: {earned}
                            </Typography>
                        </Stack>
                    </React.Fragment>
                }
            />
            <Typography variant='body2' textAlign='center'>
                Total available for withdrawal (inc. sales): {available}
            </Typography>
            {available && available > 0 ? (
                <Box py={5} alignSelf='center'>
                    <Button onClick={withdrawFunds} variant='outlined'>
                        Withdraw
                    </Button>
                </Box>
            ) : (
                ""
            )}
        </Stack>
    );
}

function FVRoyaltyTable() {
    const { hasContract, owner: getOwner, getTotalRoyaltiesEarned } = useFVMarketplace();
    const [owner, setOwner] = useState();
    const [totalEarned, setTotalEarned] = useState();
    const [royalties, setRoyalties] = useState([]);
    const royaltiesPaidEvents = useRoyaltiesPaidEvents();

    useEffect(() => {
        if (hasContract) {
            getOwner().then((owner) => {
                setOwner(owner);
                getTotalRoyaltiesEarned(owner).then((total) => {
                    setTotalEarned(Web3.utils.fromWei(total, "ether"));
                });
                if (royaltiesPaidEvents) {
                    setRoyalties(
                        royaltiesPaidEvents
                            .map(({ returnValues, transactionHash }) => ({
                                ...returnValues,
                                transactionHash
                            }))
                            .filter(({ reciever }) => reciever === owner)
                    );
                }
            });
        }
    }, [hasContract, getOwner, getTotalRoyaltiesEarned, royaltiesPaidEvents]);

    return (
        <RoyaltiesEarnedTable
            royalties={royalties}
            topAdornment={
                <React.Fragment>
                    <Text.DashHeader variant='h5' color='black'>
                        Unlimitid Royalites
                    </Text.DashHeader>
                    <Stack spacing={2}>
                        <Typography variant='body2' color='text.secondary'>
                            Royalties earned by the Unlimitid wallet: ({owner})
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            Total: {totalEarned}
                        </Typography>
                    </Stack>
                </React.Fragment>
            }
        />
    );
}

function AllRoyaltyTransactionsTable() {
    const { hasContract } = useFVMarketplace();
    const [royalties, setRoyalties] = useState([]);
    const royaltiesPaidEvents = useRoyaltiesPaidEvents();

    useEffect(() => {
        if (hasContract) {
            if (royaltiesPaidEvents) {
                setRoyalties(
                    royaltiesPaidEvents.map(({ returnValues, transactionHash }) => ({
                        ...returnValues,
                        transactionHash
                    }))
                );
            }
        }
    }, [hasContract, royaltiesPaidEvents]);

    const total = useMemo(
        () =>
            Web3.utils.fromWei(
                royalties
                    .map(({ amount }) => amount)
                    .reduce((a, i) => a.add(Web3.utils.toBN(i)), Web3.utils.toBN(0))
                    .toString(),
                "ether"
            ),
        [royalties]
    );

    return (
        <RoyaltiesEarnedTable
            royalties={royalties}
            showRecipient
            topAdornment={
                <React.Fragment>
                    <Text.DashHeader variant='h5' color='black'>
                        All Royalty transactions
                    </Text.DashHeader>
                    <Stack spacing={2}>
                        <Typography variant='body2' color='text.secondary'>
                            A list of all royalties transactions, detailing the payee and the payer
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            Total: {total}
                        </Typography>
                    </Stack>
                </React.Fragment>
            }
        />
    );
}

function AllRoyaltiesTable() {
    const { hasContract } = useFVMarketplace();
    const [royalties, setRoyalties] = useState([]);
    const royaltiesPaidEvents = useRoyaltiesPaidEvents();

    useEffect(() => {
        if (hasContract) {
            if (royaltiesPaidEvents) {
                setRoyalties(
                    royaltiesPaidEvents.map(({ returnValues, transactionHash }) => ({
                        ...returnValues,
                        transactionHash
                    }))
                );
            }
        }
    }, [hasContract, royaltiesPaidEvents]);

    const total = useMemo(
        () =>
            Web3.utils.fromWei(
                royalties
                    .map(({ amount }) => amount)
                    .reduce((a, i) => a.add(Web3.utils.toBN(i)), Web3.utils.toBN(0))
                    .toString(),
                "ether"
            ),
        [royalties]
    );

    return (
        <RoyaltiesEarnedTable
            royalties={royalties}
            hideBuyer
            showRecipient
            topAdornment={
                <React.Fragment>
                    <Text.DashHeader variant='h5' color='black'>
                        All Royalties
                    </Text.DashHeader>
                    <Stack spacing={2}>
                        <Typography variant='body2' color='text.secondary'>
                            A list of all royalties by who received them
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            Total: {total}
                        </Typography>
                    </Stack>
                </React.Fragment>
            }
        />
    );
}
