export const rangeMarks = [
    {
        value: 0,
        label: "0"
    },
    {
        value: 1250,
        label: "1250"
    },
    {
        value: 2500,
        label: "2500"
    },
    {
        value: 3750,
        label: "3750"
    },
    {
        value: 5000,
        label: "5000"
    }
];

export const sizes = ["XS", "S", "M", "L", "XL"];

export const brands = ["Nike", "Rolex", "Palace", "Louis Vuitton", "Prada", "Supreme"];

export const colours = [
    {
        colour: "#000",
        label: "Black"
    },
    {
        colour: "#1790C8",
        label: "Blue"
    },
    {
        colour: "#825D41",
        label: "Brown"
    },
    {
        colour: "#7BBA3C",
        label: "Green"
    },
    {
        colour: "#808080",
        label: "Grey"
    },
    {
        colour: "linear-gradient(180deg, #FF0000 0%, #FFF500 18.52%, #00FF0A 40.91%, #00E0FF 61.75%, #2400FF 79.46%, #FA00FF 99.77%)",
        label: "Multi"
    },
    {
        colour: "#F36B26",
        label: "Orange"
    },
    {
        colour: "#F0728F",
        label: "Pink"
    },
    {
        colour: "#8D429F",
        label: "Purple"
    },
    {
        colour: "#E7352B",
        label: "Red"
    },
    {
        colour: "#fff",
        label: "White"
    },
    {
        colour: "#FED533",
        label: "Yellow"
    }
];

export const gender = ["Mens", "Womens", "Unisex"];

export const availability = ["Listed for Sale", "Not Listed for Sale"];
