import config from "./config";
import { MY_PROFILE, PROFILE_ORDERS } from "./pages.constants";

export const NAV_TYPE_SELECT = "SELECT";
export const NAV_TYPE_BUTTON = "BUTTON";

const navigation = {
    links: [
        {
            text: "My Profile",
            type: NAV_TYPE_BUTTON,
            location: `${MY_PROFILE}/${PROFILE_ORDERS}`,
            authorizedOnly: true
        },
        ...(config.features.enableMarketplace &&
        (config.features.web3.allowMint || config.features.web2.allowMint)
            ? [
                  {
                      text: "Mint",
                      type: NAV_TYPE_SELECT,
                      options: [
                          ...(config.features.web3.allowMint
                              ? [
                                    {
                                        text: "Mint",
                                        location: "/mint/web3"
                                    }
                                ]
                              : []),
                          ...(config.features.web2.allowMint
                              ? [
                                    {
                                        text: "Lazy mint",
                                        location: "/mint/web2"
                                    },
                                    {
                                        text: "Mint with attributes",
                                        location: "/mint/attributes"
                                    }
                                ]
                              : [])
                      ]
                  }
              ]
            : []),
        ...(config.features.enableMarketplace && config.features.showRoyalties
            ? [
                  {
                      text: "Royalties",
                      type: NAV_TYPE_SELECT,
                      options: [
                          ...(config.features.web3.allowMetamask
                              ? [
                                    {
                                        text: "My royalties",
                                        location: "/royalties/my-royalties"
                                    }
                                ]
                              : []),
                          {
                              text: `${config.branding.company} royalties`,
                              location: `/royalties/${config.branding.company.toLowerCase()}`
                          },
                          {
                              text: "All royalty transactions",
                              location: "/royalties/transactions"
                          },
                          {
                              text: "All royalties",
                              location: "/royalties"
                          }
                      ]
                  }
              ]
            : []),
        ...(config.features.enableMarketplace
            ? [
                  {
                      text: "Marketplace",
                      type: NAV_TYPE_BUTTON,
                      location: "/marketplace"
                  },
                  {
                      // Currently disable about page if marketplace is disabled
                      text: "About",
                      type: NAV_TYPE_BUTTON,
                      location: "/about"
                  }
              ]
            : [])
    ]
};

console.log({ navigation });

export default navigation;
