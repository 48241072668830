import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import MainNavigation from "../navigations";
import { useAuthentication } from "@features/user";
import { useNavigate } from "react-router-dom";
import ForgotPasswordForm from "@features/user/ForgotPasswordForm";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const { authenticated } = useAuthentication();

    useEffect(() => {
        if (authenticated) {
            navigate("/");
        }
    }, [navigate, authenticated]);

    return (
        <Box>
            <Grid container justifyContent='center' alignItems='center' sx={{ minHeight: "60vh" }}>
                <Grid item xs={12} md={6}>
                    <ForgotPasswordForm />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ForgotPasswordPage;
