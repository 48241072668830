import golangAPI from "../../golangAPI";

// TODO:
// We can split these down into smaller slices
// for example: blacklist endpoints can be there own module.

export const golangWeb3Service = golangAPI.injectEndpoints({
    endpoints: (build) => ({
        // ADMIN ENDPOINTS
        getBlacklistedTokens: build.query({
            query: () => ({
                method: "get",
                url: "/api/v1/admin/blacklist"
            }),
            providesTags: ["BlacklistedTokens"]
        }),
        blackListToken: build.mutation({
            query: ({ tokenId }) => ({
                method: "post",
                url: "/api/v1/admin/blacklist",
                body: {
                    tokenId
                }
            }),
            invalidatesTags: ["BlacklistedTokens"]
        }),
        unblacklistToken: build.mutation({
            query: ({ tokenId }) => ({
                method: "delete",
                url: "/api/v1/admin/blacklist",
                body: {
                    tokenId
                }
            }),
            invalidatesTags: ["BlacklistedTokens"]
        }),
        // END ADMIN ENDPOINTS
        // START MARKET
        getListings: build.query({
            query: () => ({
                method: "get",
                url: "/api/v1/market/listing"
            }),
            providesTags: ["LazyListings"]
        }),
        addListing: build.mutation({
            query: ({ lmuid, price }) => ({
                method: "post",
                url: "/api/v2/market/listing",
                body: { lmuid, price }
            }),
            invalidatesTags: ["LazyListings"]
        }),
        // TODO: obviously this buying is just going to change ownership of the token
        // There is no means of accepting payment at the moment.
        // This method should.
        //   - Transfer ownership to the buyer (set cuid in database).
        //   - Remove the marketlisting.
        //   - Mint the token to custodial wallet.
        buyListing: build.mutation({
            query: ({ lmluid, price }) => ({
                method: "put",
                url: "/api/v2/market/listing",
                body: {
                    lmluid,
                    price
                }
            }),
            invalidatesTags: ["buyListing"]
        }),
        deleteListing: build.mutation({
            query: ({ lmluid }) => ({
                method: "delete",
                url: "/api/v1/market/listing",
                body: {
                    lmluid
                }
            }),
            invalidatesTags: ["LazyListings"]
        }),
        // END MARKET
        getTokens: build.query({
            query: () => ({
                method: "get",
                url: "/api/v1/nft"
            }),
            providesTags: ["LazyTokens"]
        }),
        getLazyToken: build.query({
            query: ({ lmuid }) => ({
                method: "get",
                url: `/api/v2/nft?lmuid=${lmuid}`
            })
        }),
        transferLazyToken: build.mutation({
            query: ({ to, lmuid }) => ({
                method: "post",
                url: "/api/v2/nft",
                body: {
                    lmuid,
                    to
                }
            }),
            invalidatesTags: ["LazyTokens"]
        }),
        burnLazyToken: build.mutation({
            query: ({ lmuid }) => ({
                method: "delete",
                url: `/api/v1/nft/lazy-token?lmuid=${lmuid}`
            }),
            invalidatesTags: ["LazyTokens"]
        }),
        getClaimedTokens: build.query({
            query: ({ cuid }) => ({
                method: "get",
                url: `/api/v1/nft/claimed-tokens?cuid=${cuid}`
            }),
            providesTags: ["ClaimedTokens"]
        }),
        mintToken: build.mutation({
            query: ({ form }) => ({
                method: "post",
                url: "/api/v2/nft/mint",
                body: form
            })
        }),
        lazyMintToken: build.mutation({
            query: ({ form }) => ({
                method: "post",
                url: "/api/v3/nft/lazy-mint",
                body: form
            })
        }),
        claimLazyMint: build.mutation({
            query: ({ lmuid, cuid }) => ({
                method: "post",
                url: `/api/v1/nft/claim-lazy-mint?lmuid=${lmuid}&cuid=${cuid}`
            }),
            invalidatesTags: ["ClaimedTokens"]
        }),
        claimLazy: build.mutation({
            query: ({ lmuid, cuid }) => ({
                method: "post",
                url: `/api/v1/nft/claim-lazy?lmuid=${lmuid}&cuid=${cuid}`
            }),
            invalidatesTags: ["ClaimedTokens"]
        }),
        getListingsV2: build.query({
            query: () => ({
                method: "get",
                url: "/api/v3/market/listing"
            }),
            providesTags: ["LazyListings"]
        }),
        getListStatusById: build.query({
            query: ({ lmuid }) => ({
                method: "get",
                url: `/api/v3/market/listing?lmuid=${lmuid}`
            }),
            providesTags: ["LazyListings"]
        })
    }),
    overrideExisting: true
});

export const {
    useGetBlacklistedTokensQuery,
    useLazyGetBlacklistedTokensQuery,
    useBlackListTokenMutation,
    useUnblacklistTokenMutation,
    useGetListingsQuery,
    useLazyGetListingsQuery,
    useAddListingMutation,
    useBuyListingMutation,
    useDeleteListingMutation,
    useGetTokensQuery,
    useLazyGetTokensQuery,
    useGetLazyTokenQuery,
    useLazyGetLazyTokenQuery,
    useTransferLazyTokenMutation,
    useBurnLazyTokenMutation,
    useGetClaimedTokensQuery,
    useLazyGetClaimedTokensQuery,
    useMintTokenMutation,
    useLazyMintTokenMutation,
    useClaimLazyMintMutation,
    useClaimLazyMutation,
    useGetListingsV2Query,
    useGetListStatusByIdQuery
} = golangWeb3Service;
