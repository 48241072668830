import { useState } from "react";

function useDialog() {
    const [open, setOpen] = useState(false);
    const [data, setForm] = useState();

    const openDialog = (data) => {
        setForm(data);
        setOpen(true);
    };

    const closeDialog = () => {
        setForm();
        setOpen(false);
    };

    return {
        open,
        openDialog,
        closeDialog,
        data
    };
}

export default useDialog;
