import { createApi, fetchBaseQuery, queryFn } from "@reduxjs/toolkit/query/react";

import config from "../config";

export const cardHistoryApi = createApi({
    reducerPath: "cardHistory",
    baseQuery: fetchBaseQuery({ baseUrl: config.services.uploadCard.cardHistory }),
    endpoints: (build) => ({
        getProductHistory: build.query({
            query: ({ lmuid }) => `api/v2/nft/history?lmuid=${lmuid}`
        })
    })
});

export const { useGetProductHistoryQuery } = cardHistoryApi;
