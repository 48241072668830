import Typography from "@features/components/text/typography";
import { Alert, AlertTitle, Box, Collapse, Snackbar, Stack, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as CopyIcon } from "./copy-icon.svg";
import { ReactComponent as InfoIcon } from "./info-icon.svg";
import { useSelector } from "react-redux";
import useDimensions from "@app/pages/index/hooks/useMobile";
import { useLazyGetUserDetailsQuery } from "@app/services";

const styles = (theme) => ({
    container: {
        position: "relative",
        background: "#F5F5F5",
        padding: "16px",
        borderRadius: "8px"
    },
    separator: {
        width: "1px",
        height: "19px",
        background: "#7E7E7E"
    },
    boldText: {
        fontWeight: 600
    },
    imageContainer: {
        height: "57px",
        width: "57px",
        padding: "16px",

        "& svg": {
            height: "100%",
            width: "100%"
        },

        [theme.breakpoints.down("md")]: {
            padding: "4px"
        }
    },
    snackbarRoot: {
        marginLeft: "0 !important"
    }
});

function InfoItem({ classes }) {
    const [alertOpened, setAlertOpened] = useState(false);
    const [tooltipOpened, setTooltipOpened] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const user = useSelector((state) => state.user);
    const { isMobile } = useDimensions();
    const [fetchDetails] = useLazyGetUserDetailsQuery();

    const copyToKeyboard = useCallback(() => {
        navigator.clipboard.writeText("unlimitid@io.parsio.io");
        setAlertOpened(true);
    }, []);

    useEffect(() => {
        if (!user?.email) {
            fetchDetails().then((data) => setUserEmail(data.data.email));
        }
        setUserEmail(user.email);
    }, [user]);

    return (
        <Stack direction='row' alignItems='center' mb={4}>
            <Box className={classes.container}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Typography variant='body1'>
                        Forward all your purchase emails to {!isMobile && <br />}
                        <span className={classes.boldText}>unlimitid@io.parsio.io</span> from{" "}
                        <span className={classes.boldText}>{userEmail}</span>
                    </Typography>
                    <span className={classes.separator} />
                    <CopyIcon
                        onClick={copyToKeyboard}
                        style={{ cursor: "pointer", minWidth: "16px" }}
                    />
                    <Snackbar
                        open={alertOpened}
                        onClose={() => setAlertOpened(false)}
                        autoHideDuration={2000}
                        message='Copied to clipboard'
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        ContentProps={{
                            sx: {
                                display: "block",
                                textAlign: "center"
                            }
                        }}
                        classes={{
                            root: classes.snackbarRoot
                        }}
                    />
                </Stack>
            </Box>
            <Box className={classes.imageContainer}>
                {isMobile ? (
                    <Tooltip
                        open={tooltipOpened}
                        onClose={() => setTooltipOpened(false)}
                        placement='top'
                        title='Make sure you forward emails from the email address you signed up with'>
                        <InfoIcon onClick={() => setTooltipOpened(prevState => !prevState)} style={{ minWidth: "16px" }} />
                    </Tooltip>
                ) : (
                    <Tooltip
                        placement='top'
                        title='Make sure you forward emails from the email address you signed up with'>
                        <InfoIcon style={{ minWidth: "16px" }} />
                    </Tooltip>
                )}
            </Box>
        </Stack>
    );
}

export default withStyles(styles)(InfoItem);
