import config from "../config";

import * as date from "./date";
import {
    getCardAttributes,
    getProductInfoAttributes,
    getPurchaseAttributes,
    getShippingAttributes,
    PRODUCT_ATTRIBUTES_TEXT,
    PURCHASE_ATTRIBUTES_TEXT,
    SALE_LISTING_ATTRIBUTES_TEXT,
    SHIPPING_ATTRIBUTES_TEXT
} from "./getCardAttributes";
/**
 * Replaces a ipfs:// with the ipfs publicServer defined in the config.
 * @param {string} tokenURI a ipfs://<cid> link
 * @returns
 */
export function ipfsURIToPublicServer(tokenURI) {
    return tokenURI.replace("ipfs://", `${config.services.ipfs.publicServer}/`);
}

export function stripCIDFromURI(tokenURI) {
    return tokenURI.split("/").pop();
}

export const utils = {
    date,
    getCardAttributes: getCardAttributes,
    getProductInfoAttributes: getProductInfoAttributes,
    getPurchaseAttributes: getPurchaseAttributes,
    getShippingAttributes: getShippingAttributes
};

export const constants = {
    PRODUCT_ATTRIBUTES_TEXT: PRODUCT_ATTRIBUTES_TEXT,
    PURCHASE_ATTRIBUTES_TEXT: PURCHASE_ATTRIBUTES_TEXT,
    SHIPPING_ATTRIBUTES_TEXT: SHIPPING_ATTRIBUTES_TEXT,
    SALE_LISTING_ATTRIBUTES_TEXT: SALE_LISTING_ATTRIBUTES_TEXT
};
