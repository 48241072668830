import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Grid,
    Box,
    TableContainer,
    Table,
    TableBody,
    CardMedia,
    CircularProgress
} from "@mui/material";
import { withStyles } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import { ReactComponent as PanoramaIcon } from "./img/PanoramaIcon.svg";
import { ReactComponent as DeleteIcon } from "./img/deleteIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import { Cards, Button, Tables, Wrappers, Gallery } from "@features/components";

import { generateRandomString } from "@app/utils/generateRandomString";
import {
    useGetLazyTokenQuery,
    useGetAllImagesQuery,
    useLazyGetPolicyQuery,
    useUploadToAWSMutation,
    useSaveImageMutation,
    useDeleteImageMutation
} from "@app/services";
import config from "@app/config";
import FormSection from "./form";
import MainTitle from "./mainTitle";
import { utils } from "@app/utils";
import TableDetails from "./tableDetails";
import { styles } from "./card.style";
import useDimensions from "../index/hooks/useMobile";

const tabsTitles = [
    { label: "Product Info", value: "1" },
    { label: "Purchase Info", value: "2" },
    { label: "Shipping Info", value: "3" }
];

const CardSellerPage = ({ classes }) => {
    const { search } = useLocation();

    const lmuid = useMemo(() => new URLSearchParams(search).get("lmuid"), [search]);
    const [currentImageIndex, setCurrentImageIndex] = useState(-1);
    const [allImages, setAllImages] = useState([]);
    const isImageUploading = useRef(false);
    const mainFileInputRef = useRef(null);
    const secondaryFileInputRef = useRef(null);
    const { isMobile } = useDimensions();
    const { data: tokenData } = useGetLazyTokenQuery({ lmuid }, { skip: !lmuid });
    const { data, isLoading } = useGetAllImagesQuery(lmuid, { skip: !lmuid });
    const [getPolicyTrigger] = useLazyGetPolicyQuery();
    const [uploadToAWSTrigger] = useUploadToAWSMutation();
    const [saveImageTrigger] = useSaveImageMutation();
    const [deleteImageTrigger] = useDeleteImageMutation();

    useEffect(() => {
        if (!isLoading && tokenData) {
            const externalImage = [
                {
                    imagePath: `https://ipfs.io/ipfs/${tokenData.mediaHash}`,
                    isExternal: true
                }
            ];
            if (!data) {
                setAllImages(externalImage);
            } else {
                setAllImages(externalImage.concat(data?.data));
            }
        }
    }, [data, tokenData]);

    const getPolicy = useCallback(() => {
        return getPolicyTrigger()
            .unwrap()
            .then((data) => {
                const policyInfo = data?.data;

                return {
                    policy: policyInfo["policy"],
                    signature: policyInfo["signature"],
                    credential: policyInfo["credential"],
                    awsAccessKeyId: policyInfo["awsAccessKeyId"],
                    date: policyInfo["date"]
                };
            });
    }, [getPolicyTrigger]);

    const saveImage = useCallback(
        (fileName) => {
            saveImageTrigger({
                data: {
                    image: fileName
                },
                cardId: lmuid
            })
                .unwrap()
                .finally(() => {
                    isImageUploading.current = false;
                });
        },
        [saveImageTrigger]
    );

    const uploadToAWS = useCallback(
        (policyInfo, file) => {
            const regex = /(?:\.([^.]+))?$/;
            const fileExtension = regex.exec(file.name)[1];
            const IMG_EXTENSIONS = ["png", "jpg", "jpeg"];

            if (IMG_EXTENSIONS.includes(fileExtension)) {
                const newFileName = `${generateRandomString(10)}.${fileExtension}`;
                const formData = new FormData();

                formData.append("key", "tmp/" + newFileName);
                formData.append("acl", "private");
                formData.append("Content-Type", "image/jpeg");
                formData.append("x-amz-credential", policyInfo.credential);
                formData.append("x-amz-algorithm", "AWS4-HMAC-SHA256");
                formData.append("success_action_status", "201");
                formData.append("x-amz-date", policyInfo.date);
                formData.append("policy", policyInfo.policy);
                formData.append("x-amz-signature", policyInfo.signature);
                formData.append("x-amz-expires", 60);
                formData.append("file", file);

                uploadToAWSTrigger(formData)
                    .unwrap()
                    .catch(() => saveImage(newFileName)); //TODO: fix the error by adding cors to the response
            }
        },
        [uploadToAWSTrigger]
    );

    const deleteImage = useCallback(
        (uuid, imagePath) => {
            const fileName = imagePath.split("/")[3];

            deleteImageTrigger({
                data: {
                    image: fileName
                },
                cardId: lmuid,
                uuid
            })
                .unwrap()
                .then(() => {
                    setCurrentImageIndex((prevIndex) => prevIndex - 1);
                });
        },
        [deleteImageTrigger]
    );

    useEffect(() => {
        if (!mainFileInputRef.current || !secondaryFileInputRef.current) return;

        const addImage = async (e) => {
            console.log(mainFileInputRef.current.files[0], secondaryFileInputRef.current.files[0]);
            let currentFile;

            if (mainFileInputRef.current.files[0]) {
                currentFile = mainFileInputRef.current.files[0];
            } else if (secondaryFileInputRef.current.files[0]) {
                currentFile = secondaryFileInputRef.current.files[0];
            } else {
                return;
            }

            if (!isImageUploading.current) {
                isImageUploading.current = true;
                const policy = await getPolicy();
                uploadToAWS(policy, currentFile);
            }
        };

        mainFileInputRef.current.addEventListener("change", addImage, false);
        secondaryFileInputRef.current.addEventListener("change", addImage, false);
    }, [currentImageIndex]);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            <Grid container justifyContent='space-between'>
                <MainTitle isDesktop={false} />
                <Grid item xs={12} lg={6} md={6} classes={{ root: classes.gridGallery }}>
                    <Box
                        className={classes.mainImageSellerContainer}
                        sx={{
                            border: currentImageIndex < 0 ? "1px dashed #DDD" : null
                        }}>
                        {currentImageIndex >= 0 && !isLoading ? (
                            <Carousel
                                autoPlay={false}
                                indicators={false}
                                index={currentImageIndex}
                                sx={{
                                    height: "100%"
                                }}>
                                {allImages.map(({ imagePath, isExternal }) => (
                                    <img
                                        src={
                                            !isExternal
                                                ? config.services.card.aws_s3_bucket + imagePath
                                                : imagePath
                                        }
                                        alt='product card'
                                        className={classes.mainImageSeller}
                                    />
                                ))}
                            </Carousel>
                        ) : (
                            <Grid
                                container
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                    height: "100%"
                                }}>
                                <Grid
                                    display='flex'
                                    sx={{
                                        flexDirection: "column"
                                    }}>
                                    <PanoramaIcon className={classes.chooseImageIcon} />
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        component='label'
                                        className={classes.uploadButton}>
                                        Choose files
                                        <input
                                            hidden
                                            accept='image/*'
                                            multiple
                                            type='file'
                                            ref={mainFileInputRef}
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                    <Grid container gap={1}>
                        {tokenData && (
                            <Grid className={classes.cardMedia} item>
                                <CardMedia
                                    component='img'
                                    style={{ borderRadius: "8px", height: "100%" }}
                                    image={tokenData?.metadata.media}
                                    alt='image item'
                                    onClick={() => {
                                        setCurrentImageIndex(0);
                                    }}
                                />
                            </Grid>
                        )}
                        {!isLoading &&
                            data?.data?.map(({ imagePath, uuid }, index) => (
                                <Grid className={classes.cardMedia} item key={index}>
                                    <CardMedia
                                        component='img'
                                        style={{ borderRadius: "8px", height: "100%" }}
                                        image={config?.services?.card?.aws_s3_bucket + imagePath}
                                        alt='image item'
                                        onClick={() => {
                                            setCurrentImageIndex(index + 1);
                                        }}
                                    />
                                    <DeleteIcon
                                        className={classes.deleteIcon}
                                        onClick={() => deleteImage(uuid, imagePath)}
                                    />
                                </Grid>
                            ))}
                        <Grid
                            className={classes.cardMedia}
                            item
                            onClick={() => setCurrentImageIndex(-1)}>
                            {currentImageIndex < 0 ? (
                                <Button
                                    color='secondary'
                                    component='label'
                                    className={classes.uploadButton}>
                                    <AddIcon sx={{ color: "#ddd" }} />
                                    <input
                                        hidden
                                        accept='image/*'
                                        multiple
                                        type='file'
                                        ref={secondaryFileInputRef}
                                    />
                                </Button>
                            ) : (
                                <AddIcon sx={{ color: "#ddd" }} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/* right side */}
                <Grid item xs={12} lg={5} md={5}>
                    <MainTitle isDesktop={true} />
                    <TableDetails lmuid={lmuid} />
                    {/* <FormSection lmuid={lmuid} /> */}
                </Grid>
            </Grid>
        </Wrappers.Container>
    );
};

export default withStyles(styles)(CardSellerPage);
