const CUSTOM_ATTRIBUTES = [
    { trait_type: "Colourway", value: "" },
    { trait_type: "Retail Price", value: "" }
];

export const PRODUCT_ATTRIBUTES_TEXT = {
    orderNo: "Order Number",
    nameOfProduct: "Title",
    productDescription: "Description",
    sellingPrice: "Selling Price",
    shippingPrice: "Shipping Price",
    category: "Category",
    subcategory: "Subcategory",
    targetConsumer: "Consumer",
};

export const PURCHASE_ATTRIBUTES_TEXT = {
    retailer: "Retailer",
    website: "Website",
    domain: "Domain",
    price: "Price",
};

export const SHIPPING_ATTRIBUTES_TEXT = {
    deliveryDetails: "Delivery Address",
    purchaseDate: "Purchase Date",
    deliveryDate: "Delivery Date",
    trackingNo: "Tracking Number",
    courier: "Courier",
};

export const SALE_LISTING_ATTRIBUTES_TEXT = {
    postcode: "Your Postcode",
    condition: "Condition",
    sellingPrice: "Selling Price",
    shippingPrice: "Shipping Price",
};

const PRODUCT_ATTRIBUTES = ["orderNo", "brand", "nameOfProduct", "category", "subcategory", "type", "targetConsumer", "size", "colour"];

const PURCHASE_ATTRIBUTES = ["retailer", "website", "price"];

const SHIPPING_ATTRIBUTES = ["deliveryDetails", "purchaseDate", "trackingNo", "deliveryDate", "courier"];


export const getCardAttributes = (attributes) => {
    return attributes?.reduce((sum, prev) => {
        if (prev?.trait_type?.includes("Colo")) {
            sum[0].value = prev.value;
        }
        if (prev?.trait_type?.includes("Retail")) {
            sum[1].value = prev.value;
        }

        return sum;
    }, CUSTOM_ATTRIBUTES);
};

export const getAttributes = (response, passedAttributes, passedDisplayText) => {
    const attributes = [];

    if (response.isSuccess) {
        const parsedInfo = response.data.data.parsedInformation;

        for (const key of passedAttributes) {
            const attributeObject = {};
            if (parsedInfo !== null && parsedInfo[key] !== undefined) {
                attributeObject[passedDisplayText[key]] = parsedInfo[key];
            } else {
                attributeObject[passedDisplayText[key]] = ""; // Assign empty string if not found
            }
            attributes.push(attributeObject);
        }
    }

    return attributes;
};

export const getProductInfoAttributes = (data)  => getAttributes(data, PRODUCT_ATTRIBUTES, PRODUCT_ATTRIBUTES_TEXT);
export const getPurchaseAttributes = (data) =>getAttributes(data, PURCHASE_ATTRIBUTES, PURCHASE_ATTRIBUTES_TEXT);


export const getShippingAttributes = (data)  => getAttributes(data, SHIPPING_ATTRIBUTES, SHIPPING_ATTRIBUTES_TEXT);

