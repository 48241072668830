import React from "react";
import { withStyles } from "@mui/styles";
import { Button as MuiButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const styles = (theme) => ({
    root: {
        fontWeight: "600",
        fontSize: "12px",
        transition: "0.5s",
        borderRadius: "6px",
        cursor: "pointer",
        textTransform: "capitalize",
        boxShadow: "none"
    },
    containedPrimary: {
        color: "white",
        backgroundColor: "#2363F2",
        border: "none"
    },
    containedSecondary: {
        color: "white",
        backgroundColor: "black",
        border: "1px solid black",
        transition: "0.5s",
        "&:hover": {
            color: "black",
            backgroundColor: "white"
        },

        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    outlinedSecondary: {
        border: "1px solid black",
        background: "white",
        color: "black",
        "&:hover": {
            background: "black",
            color: "white"
        }
    },
    sizeSmall: {
        fontSize: "11px",
        lineHeight: "17px"
    },
    sizeMedium: {
        padding: "10px 40px",
        width: "84px",
        height: "40px",
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    sizeLarge: {
        padding: "16px 32px",
        height: "61px",
        fontSize: "18px",
        lineHeight: "160%",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            lineHeight: "140%"
        }
    }
});

export const Button = withStyles(styles)((props) => {
    const { children, onClick, classes, loading, ...restProps } = props;
    return (
        <>
            {loading ? (
                <LoadingButton
                    loading
                    variant='contained'
                    classes={{
                        root: classes.root,
                        containedPrimary: classes.outlinedPrimary,
                        containedSecondary: classes.containedSecondary,
                        outlinedSecondary: classes.outlinedSecondary,
                        sizeSmall: classes.sizeSmall,
                        sizeMedium: classes.sizeMedium,
                        sizeLarge: classes.sizeLarge
                    }}
                    {...restProps}
                >
                    Submit
                </LoadingButton>
            ) : (
                <MuiButton
                    onClick={onClick}
                    classes={{
                        root: classes.root,
                        containedPrimary: classes.outlinedPrimary,
                        containedSecondary: classes.containedSecondary,
                        outlinedSecondary: classes.outlinedSecondary,
                        sizeSmall: classes.sizeSmall,
                        sizeMedium: classes.sizeMedium,
                        sizeLarge: classes.sizeLarge
                    }}
                    {...restProps}
                >
                    {children}
                </MuiButton>
            )}
        </>
    );
});
