import React, { useState, useEffect } from "react";
import { Box, MenuItem, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { Text, Button, FormInputs } from "..";
import { useCreateStripeAccountMutation, useLazySendBankAccountQuery } from "@app/services";
import { useDispatch } from "react-redux";
import { showSnackbarAlert } from "../../notifications/popups/snackbar.slice";
import { countries } from "@app/pages/my-account/connect-stripe/countries";
import { useAuthentication } from "../../user";

export default function ResponsiveDialog({ handleClose, handleSubmitForm, open, noCloseButton }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const { details } = useAuthentication();

    const userId = details?.cuid;

    const [selectedCountry, setCountry] = useState(countries[0]);
    const [currency, setCurrency] = useState(countries[0].currency[0]);

    const {
        formState: { errors, isDirty, isValid },
        handleSubmit,
        control,
        watch
    } = useForm({ mode: "onChange" });
    const onSubmit = (data) => handleForm(data);
    const onErrors = (data) => console.log(data, "error");

    // Send  something
    const [sendBankAccountDetails, { data, isSuccess, error, isLoading: waitingForAccountStatus }] =
        useCreateStripeAccountMutation();

    // send bank account
    const [func, res] = useLazySendBankAccountQuery();

    useEffect(() => {
        if (data?.data?.stripeAccountLink) {
            window.location.href = data?.data?.stripeAccountLink;
        }
    }, [data?.data?.stripeAccountLink]);

    useEffect(() => {
        if (res?.isError || res?.data?.error) {
            dispatch(
                showSnackbarAlert({
                    message: res?.data?.error?.message,
                    severity: "error"
                })
            );
        }
    }, [res?.isError, res?.data?.error]);

    useEffect(() => {
        if (res?.data?.token?.id) {
            const body = {
                externalAccountToken: res?.data?.token?.id,
                country: "GB"
            };
            sendBankAccountDetails({ userId, body });
        }
    }, [res?.data?.token?.id]);

    const handleForm = async (data) => {
        const { account_number, sort_code, currency, country_account } = data;
        func({
            country: country_account,
            currency,
            routing_number: sort_code,
            account_number,
            account_holder_type: "individual"
        });
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
        >
            <DialogTitle id='responsive-dialog-title'>
                <Text.Typography variant='h3'>Your bank account</Text.Typography>
                <Text.Typography variant='body2'>
                    We'll send your GBP payouts to this bank account.
                </Text.Typography>
            </DialogTitle>
            <DialogContent>
                <>
                    <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                        <Controller
                            control={control}
                            defaultValue={selectedCountry.value}
                            render={({ field }) => (
                                <FormInputs.Dropdown
                                    {...field}
                                    mt={2.4}
                                    label='Country of bank account'
                                    fullwidth={true}
                                    defaultValue={selectedCountry.value}
                                    value={selectedCountry.value}
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    {countries.map((item) => (
                                        <MenuItem value={item.value} key={item.value}>
                                            {item.country}
                                        </MenuItem>
                                    ))}
                                </FormInputs.Dropdown>
                            )}
                            name='country_account'
                        />

                        <Controller
                            control={control}
                            defaultValue={currency}
                            render={({ field }) => (
                                <FormInputs.Dropdown
                                    {...field}
                                    mt={2.4}
                                    label='Currency of bank account'
                                    fullwidth={true}
                                    defaultValue={currency}
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    {selectedCountry.currency.map((item) => (
                                        <MenuItem value={item} key={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </FormInputs.Dropdown>
                            )}
                            name='currency'
                        />

                        <Controller
                            control={control}
                            defaultValue=''
                            rules={{
                                required: "Please enter a routing number."
                            }}
                            render={({ field: { ...restField } }) => (
                                <FormInputs.InputField
                                    {...restField}
                                    name='sort_code'
                                    label='Sort code *'
                                    helperText={errors?.sort_code?.message}
                                    placeholder='108800'
                                    type='number'
                                    fullwidth={true}
                                    mt={2.4}
                                />
                            )}
                            name='sort_code'
                        />

                        <Controller
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: "Please enter account number."
                            }}
                            render={({ field }) => (
                                <FormInputs.InputField
                                    {...field}
                                    name='account_number'
                                    label='Account number *'
                                    helperText={errors?.account_number?.message}
                                    placeholder='00012345'
                                    type='number'
                                    fullwidth={true}
                                    mt={2.4}
                                />
                            )}
                            name='account_number'
                        />

                        <Controller
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: "Please confirm account number.",
                                validate: (val) => {
                                    if (watch("account_number") != val) {
                                        return "Your account numbers do no match";
                                    }
                                }
                            }}
                            render={({ field }) => (
                                <FormInputs.InputField
                                    {...field}
                                    name='confirmation_account_number'
                                    label='Confirm account number *'
                                    helperText={errors?.confirmation_account_number?.message}
                                    placeholder='00012345'
                                    type='number'
                                    fullwidth={true}
                                    mt={2.4}
                                />
                            )}
                            name='confirmation_account_number'
                        />
                        <Box mt={2.4}>
                            <Button
                                onClick={handleSubmitForm}
                                disabled={!isDirty || !isValid}
                                type='submit'
                                mt={2.4}
                                fullwidth={true}
                                size='large'
                                variant='contained'
                                color='primary'
                                loading={waitingForAccountStatus || res?.isLoading}
                                sx={{ width: "100%" }}
                            >
                                Submit
                            </Button>
                        </Box>
                        <Box mt={2.4}>
                            {!noCloseButton && (
                                <Button
                                    autoFocus
                                    onClick={handleClose}
                                    mt={2.4}
                                    fullwidth={true}
                                    size='large'
                                    variant='outlined'
                                    color='primary'
                                    sx={{ width: "100%" }}
                                >
                                    Close
                                </Button>
                            )}
                        </Box>
                    </form>
                </>
            </DialogContent>
        </Dialog>
    );
}
