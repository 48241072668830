import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ListingsGallery from "@features/fvmarketplace/listingsGallery";
import MainNavigation from "../navigations";

function MarketplacePage() {
    return (
        <MainNavigation>
            <Box p={4}>
                <Typography variant='h4' color='primary' textAlign='center'>
                    Marketplace
                </Typography>
                <Grid container spacing={4} justifyContent='center'>
                    <Grid item md={12}>
                        <ListingsGallery />
                    </Grid>
                </Grid>
                {/* <Grid container spacing={4} justifyContent='center'>
                            <Grid item md={12}>
                                <UserNFTs />
                            </Grid>
                        </Grid> */}
            </Box>
        </MainNavigation>
    );
}

export default MarketplacePage;
