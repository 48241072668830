import React from "react";
import { Box, Backdrop, CircularProgress } from "@mui/material";

function LoadingBackdrop({ show, children, loaderFixedToScreen, sx }) {
    return (
        <Box sx={{ position: "relative" }}>
            {children}
            {show ? (
                <Backdrop
                    sx={{ position: "absolute", zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }}
                    open={show}
                >
                    <CircularProgress
                        color='primary'
                        size='5rem'
                        sx={
                            loaderFixedToScreen
                                ? { position: "fixed", left: "47%", top: "45%" }
                                : {}
                        }
                    />
                </Backdrop>
            ) : (
                ""
            )}
        </Box>
    );
}

export default LoadingBackdrop;
