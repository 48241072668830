import { Box, Stack, useMediaQuery } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useCallback, useEffect, useState } from "react";
import Typography from "@features/components/text/typography";
import { ReactComponent as EditIcon } from "./editIcon.svg";

const styles = (theme) => ({
    wrapper: {
        width: "50%",

        [theme.breakpoints.down("1300")]: {
            width: "100%"
        }
    },
    infoBox: {
        border: "1px solid #ddd",
        borderRadius: "16px"
    },
    input: {
        width: "100%",
        height: "100%",
        border: "none",
        outline: "none",
        fontSize: "18px",

        [theme.breakpoints.down("md")]: {
            fontSize: "13px"
        }
    },
    inputContainer: {
        width: "100%",
        paddingRight: "0",
        paddingTop: "44px",

        [theme.breakpoints.down("md")]: {
            paddingTop: "24px"
        }
    }
});

const INFO_COLUMNS_NUMBER = 6;

function InfoBox({ title, description, classes }) {
    const [isEditMode, setEditMode] = useState(false);
    const [infoBoxInfo, setInfoBoxInfo] = useState(new Array(INFO_COLUMNS_NUMBER).fill(""));
    const isBoxesFit = useMediaQuery("(min-width:1300px)");
    const descriptionItems = description.split(/\r?\n/);

    const updateInputValue = useCallback(
        (value, inputIndex) => {
            const updatedInfoBoxInfo = infoBoxInfo.map((el, index) =>
                index === inputIndex ? value : el
            );

            setInfoBoxInfo(updatedInfoBoxInfo);
        },
        [infoBoxInfo]
    );

    const generateDescription = useCallback(() => {
        const descriptionList = [];

        descriptionItems.forEach((item) => {
            descriptionList.push(
                <>
                    {item}
                    <br />
                </>
            );
        });

        return descriptionList;
    }, []);

    const generateInputs = useCallback(() => {
        const inputList = [];

        descriptionItems.forEach((item, index) => {
            inputList.push(
                <input
                    value={infoBoxInfo[index]}
                    onInput={(e) => updateInputValue(e.target.value, index)}
                    className={classes.input}
                />
            );
        });

        return inputList;
    }, [infoBoxInfo]);

    useEffect(() => {
        const infoBoxItems = [];

        descriptionItems.forEach((item) => {
            infoBoxItems.push(item);
        });

        setInfoBoxInfo(infoBoxItems);
    }, []);

    return (
        <Box pt={isBoxesFit ? 6 : 1} className={classes.wrapper}>
            <Typography variant='body2' mb={1.2}>
                {title}
            </Typography>
            <Box className={classes.infoBox}>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    sx={{
                        width: "100%"
                    }}
                >
                    {isEditMode ? (
                        <Stack
                            direction='column'
                            p={{ xs: 3, md: 6 }}
                            className={classes.inputContainer}
                        >
                            {generateInputs()}
                        </Stack>
                    ) : (
                        <Typography variant='body2' p={{ xs: 3, md: 6 }} pr={0}>
                            {generateDescription()}
                        </Typography>
                    )}
                    <Stack direction='row' spacing={1} p={3} sx={{ height: "fit-content" }}>
                        <Typography
                            variant='h5'
                            onClick={() => setEditMode((prev) => !prev)}
                            sx={{
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                        >
                            {isEditMode ? "Cancel" : "Edit"}
                        </Typography>
                        <EditIcon />
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(InfoBox);
