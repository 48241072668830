import config from "../config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: config.services.card.baseUrl,
    prepareHeaders: (headers) => {
        headers.set("content-type", "application/json");
        return headers;
    }
});

export default createApi({
    reducerPath: "cardAPI",
    baseQuery,
    endpoints: () => ({})
});
