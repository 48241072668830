import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { PaginationGallery } from "@features/fvtoken/nftGallery";
import { setSearchString } from "../../filter.slice";
import FilterBar from "./filterBar";

function FilterPage() {
    const dispatch = useDispatch();
    const { search } = useLocation();

    useEffect(() => {
        const searchString = new URLSearchParams(search).get("t");

        dispatch(
            setSearchString({
                searchString: searchString
            })
        );

        return () => {
            dispatch(
                setSearchString({
                    searchString: ""
                })
            );
        };
    }, [search]);

    return (
        <>
            <FilterBar />
            <PaginationGallery />
        </>
    );
}

export default FilterPage;
