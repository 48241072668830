import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import useValidation from "../../hooks/useValidation";
import { showSnackbarAlert } from "../../notifications/popups/snackbar.slice";

// TODO: Later we can add display_types to determine how the trait should be displayed.
// I think for now this should work as a proof of concept.

function AddTraitDialog({ open, onClose, onSubmit, ...props }) {
    const dispatch = useDispatch();
    const [trait, setTrait, traitValid] = useValidation({ minLen: 2, maxLen: 100 });
    const [value, setValue, valueValid] = useValidation({ minLen: 1, maxLen: 100 });
    const [displayType, setDisplayType] = useState();
    const [inputType, setInputType] = useState("text");

    const handleSubmit = () => {
        if (typeof onSubmit === "function") {
            let parsedValue;
            switch (inputType) {
                case "number":
                    parsedValue = parseInt(value);
                    break;
                case "float":
                    parsedValue = parseFloat(value);
                    break;
                default:
                    parsedValue = value;
            }

            if (inputType !== "text" && isNaN(parsedValue)) {
                return dispatch(
                    showSnackbarAlert({
                        message: `Trait value cannot be parsed as a ${inputType}`,
                        severity: "error"
                    })
                );
            }

            onSubmit({ trait, value: parsedValue, type: displayType });
            setTrait();
            setValue();
            setDisplayType();
        }
    };

    const handleTypeChange = (e) => {
        setInputType(e.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose} {...props}>
            <DialogTitle>Add Attribute</DialogTitle>
            <DialogContent>
                <Stack spacing={2} py={1} sx={{ width: "100%" }}>
                    <TextField
                        label='Type'
                        placeholder='Enter attribute type'
                        value={trait}
                        onChange={(e) => setTrait(e.target.value)}
                    />
                    <Stack direction='row' spacing={2}>
                        <TextField
                            label='Value'
                            placeholder='Enter attribute value'
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            sx={{ width: "100%" }}
                        />
                        <Select
                            label='As'
                            onChange={handleTypeChange}
                            sx={{ width: "40%" }}
                            value={inputType}
                        >
                            <MenuItem value='text'>Text</MenuItem>
                            <MenuItem value='number'>Number</MenuItem>
                            <MenuItem value='float'>Float</MenuItem>
                        </Select>
                    </Stack>
                    <TextField
                        label='Display Type'
                        placeholder='Optional display type'
                        value={displayType}
                        onChange={(e) => setDisplayType(e.target.value)}
                        sx={{ width: "100%" }}
                    />
                    <Button disabled={!traitValid || !valueValid} onClick={handleSubmit}>
                        Add
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default AddTraitDialog;
