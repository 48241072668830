import React, { useEffect, useState } from "react";
import { Button, CardActions, Grid, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import MainNavigation from "../navigations";
import { useDispatch, useSelector } from "react-redux";
import { useAdminNFTs } from "@features/fvtoken/hooks/useNFTs";
import LazyNFTCard from "@features/fvtoken/lazyNFTCard";
import { Wrappers } from "@features/components";
import {
    useBlackListTokenMutation,
    useBurnLazyTokenMutation,
    useGetBlacklistedTokensQuery,
    useGetTokensQuery,
    useUnblacklistTokenMutation
} from "../services/modules/goweb3";
import { addLoading, removeLoading } from "../app.slice";
import { useNavigate } from "react-router-dom";
import useDialog from "@features/components/dialogs/useDialog";
import { NFTCardDetailDialog } from "@features/fvtoken/NFTCard";

const AdminPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const nfts = useAdminNFTs();
    const { data: goNFTData } = useGetTokensQuery(null);
    const [deleteToken] = useBurnLazyTokenMutation();

    // const { updateNFT } = useCreateERC721Token()
    // const { burnToken } = useFVToken()
    // const { open: cardDialogOpen, closeDialog: closeCardDialog, openDialog: openCardDialog, data: cardDialogData } = useDialog()
    // const { open: updateDialogOpen, closeDialog: closeUpdateDialog, openDialog: openUpdateDialog, data: updateDialogData } = useDialog()
    const loading = useSelector((state) => state.app.loading);
    const [addToBlacklisted] = useBlackListTokenMutation();
    const [removeFromBlacklisted] = useUnblacklistTokenMutation();
    const { data: blacklistData } = useGetBlacklistedTokensQuery();
    const [blacklistedIds, setBlacklistedIds] = useState([]);
    const [claimableNFTs, setClaimableNFTs] = useState([]);
    const [tab, setTab] = useState(0);
    const { open, closeDialog, openDialog, data } = useDialog();

    useEffect(() => {
        if (goNFTData) {
            setClaimableNFTs(goNFTData.filter(({ status }) => status === "CREATED"));
        }
    }, [goNFTData]);

    const deleteUnclaimedNFT = (lmuid) => {
        dispatch(addLoading({ id: `admin_${lmuid}` }));
        deleteToken({ lmuid });
        dispatch(removeLoading({ id: `admin_${lmuid}` }));
    };

    const handleAddToBlacklist = async (tokenId) => {
        dispatch(addLoading({ id: `admin_${tokenId}` }));
        await addToBlacklisted({ tokenId });
        dispatch(removeLoading({ id: `admin_${tokenId}` }));
    };

    const handleRemoveFromBlacklist = async (tokenId) => {
        dispatch(addLoading({ id: `admin_${tokenId}` }));
        await removeFromBlacklisted({ tokenId });
        dispatch(removeLoading({ id: `admin_${tokenId}` }));
    };

    useEffect(() => {
        setBlacklistedIds(blacklistData);
    }, [blacklistData]);

    // const handleBurn = (tokenId) => {
    //     console.log("burning token with id", tokenId)
    //     burnToken(tokenId).then(() => {
    //         dispatch(showSnackbarAlert({
    //             message: 'Successfully burned NFT'
    //         }))
    //     }).catch((e) => {
    //         console.error(e)
    //         dispatch(showSnackbarAlert({
    //             message: 'Failed to burn NFT',
    //             severity: 'error'
    //         }))
    //     })
    // }

    // const handleUpdate = async (name, description, file) => {
    //     const { tokenId, name: oldName, description: oldDesc, image } = updateDialogData
    //     console.log('edit nft', {
    //         tokenId,
    //         name: name || oldName,
    //         description: description || oldDesc,
    //         image,
    //         file,
    //     })
    //     closeUpdateDialog()
    //     await updateNFT(tokenId, name || oldName, description || oldDesc, image, file, [] /* attributes */).then(() => {
    //         dispatch(showSnackbarAlert({
    //             message: 'Successfully updated NFT'
    //         }))
    //     }).catch((e) => {
    //         console.error(e)
    //         dispatch(showSnackbarAlert({
    //             message: 'Failed to update NFT',
    //             severity: 'error'
    //         }))
    //     })
    // }

    return (
        <MainNavigation>
            <Typography p={5} variant='h4' color='primary' textAlign='center'>
                Admin
            </Typography>
            <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} id='tabs'>
                <Tab label='Blacklist' aria-controls={`tab_0`} />
                <Tab label='Unclaimed tokens' aria-controls={`tab_1`} />
            </Tabs>
            <Toolbar />
            <Grid
                container
                spacing={3}
                justifyContent='center'
                sx={{ display: tab === 0 ? "flex" : "none" }}
            >
                {nfts.length > 0 ? (
                    nfts.map((NFTData) => (
                        <Grid item key={`blacklist_nfts_${NFTData.tokenId}`}>
                            <Wrappers.LoadingBackdrop
                                sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
                                show={loading.includes(`admin_${NFTData.tokenId}`)}
                            >
                                <LazyNFTCard
                                    hideIndicator
                                    NFTData={NFTData}
                                    clickable
                                    onClick={(_, data) => openDialog(data)}
                                    actions={(data) => (
                                        <CardActions>
                                            {/* <Button color='info' onClick={() => openUpdateDialog(data)}>Update</Button> */}
                                            {/* <Button color='error' onClick={() => handleBurn(data.tokenId)}>Burn</Button> */}
                                            {!blacklistedIds.includes(data.tokenId) ? (
                                                <Button
                                                    sx={{ width: "100%" }}
                                                    onClick={() =>
                                                        handleAddToBlacklist(data.tokenId)
                                                    }
                                                >
                                                    Blacklist
                                                </Button>
                                            ) : (
                                                <Button
                                                    color='error'
                                                    sx={{ width: "100%" }}
                                                    onClick={() =>
                                                        handleRemoveFromBlacklist(data.tokenId)
                                                    }
                                                >
                                                    Remove From Blacklist
                                                </Button>
                                            )}
                                        </CardActions>
                                    )}
                                />
                            </Wrappers.LoadingBackdrop>
                        </Grid>
                    ))
                ) : (
                    <Typography variant='h6' color='text.secondary'>
                        They're no blacklistable tokens to show.
                    </Typography>
                )}
            </Grid>
            <Grid
                container
                spacing={3}
                justifyContent='center'
                sx={{ display: tab === 1 ? "flex" : "none" }}
            >
                {claimableNFTs.length > 0 ? (
                    claimableNFTs.map((tokenData) => (
                        <Grid item key={`claimable_nfts_${tokenData.lmuid}`}>
                            <Wrappers.LoadingBackdrop
                                sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
                                show={loading.includes(`admin_${tokenData.lmuid}`)}
                            >
                                <LazyNFTCard
                                    hideIndicator
                                    NFTData={{ custodial: tokenData, type: "lazy" }}
                                    clickable
                                    onClick={(_, data) => openDialog(data)}
                                    actions={() => (
                                        <CardActions>
                                            <Button
                                                sx={{ width: "100%" }}
                                                onClick={() =>
                                                    navigate(`/claim?lmuid=${tokenData.lmuid}`)
                                                }
                                                variant='contained'
                                            >
                                                Claim
                                            </Button>
                                            <Button
                                                sx={{ width: "100%" }}
                                                onClick={() => deleteUnclaimedNFT(tokenData.lmuid)}
                                                variant='contained'
                                                color='error'
                                            >
                                                Delete
                                            </Button>
                                        </CardActions>
                                    )}
                                />
                            </Wrappers.LoadingBackdrop>
                        </Grid>
                    ))
                ) : (
                    <Typography variant='h6' color='text.secondary'>
                        They're no unclaimed tokens to show.
                    </Typography>
                )}
            </Grid>
            <NFTCardDetailDialog {...data} open={open} onClose={closeDialog} fullWidth />
            {/* <Dialogs.EditNFT open={updateDialogOpen} onClose={closeUpdateDialog} onSubmit={handleUpdate} fullWidth /> */}
        </MainNavigation>
    );
};

export default AdminPage;
