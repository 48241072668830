import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Carousel from "react-material-ui-carousel";
import { withStyles } from "@mui/styles";
import {
    Grid,
    Stack,
    Box,
    TableContainer,
    Table,
    TableBody,
    CardMedia,
    CircularProgress
} from "@mui/material";

import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { useGetLazyTokenQuery } from "@app/services";
import { utils } from "@app/utils";
import { Cards, Text, Button, Tables, Wrappers, Gallery } from "@features/components";
import {
    useGetProductByImuidQuery,
    useLazyGetAllImagesQuery,
    useGetProductHistoryQuery
} from "@app/services";
import config from "@app/config";
import {
    setDescription,
    setImagePath,
    setName,
    setPrice,
    setLmuid,
    setSellerId
} from "@app/card.slice";
import { CART } from "@app/pages.constants";
import { useAuthentication } from "@app/../features/user";

import { styles } from "./card.style";
import useDimensions from "../index/hooks/useMobile";

const tabsTitles = [
    { label: "Original Images", value: "1" },
    { label: "Seller Images", value: "2" }
];

const CardPage = ({ classes }) => {
    const { search } = useLocation();
    const { details } = useAuthentication();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tokenData, setTokenData] = useState();
    const [allImages, setAllImages] = useState([]);
    const [isImagesRequested, setIsImagesRequested] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isOwner, setIsOwner] = useState(true);
    const lmuid = useMemo(() => new URLSearchParams(search).get("lmuid"), [search]);
    const { data, error, isLoading } = useGetLazyTokenQuery({ lmuid }, { skip: !lmuid });
    const itemData = useGetProductByImuidQuery(lmuid);
    const { isMobile } = useDimensions()
    const historyData = useGetProductHistoryQuery({ lmuid }, { skip: !lmuid });
    const [getAllImagesTrigger] = useLazyGetAllImagesQuery();

    const cardOptions = useMemo(
        () => utils.getCardAttributes(tokenData?.metadata?.attributes),
        [tokenData?.metadata?.attributes]
    );
    const saveDataToLocalStorage = useCallback(
        (data) => {
            dispatch(
                setName({
                    name: data?.metadata?.name
                })
            );

            dispatch(
                setDescription({
                    description: data?.metadata?.description
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        itemData.refetch();
    }, []);

    useEffect(() => {
        if (error) {
            console.log("error while fetching lazy token data", error);
            dispatch(
                showSnackbarAlert({
                    message: "Failed to get token information",
                    severity: "error"
                })
            );
            navigate("/");
            return;
        }
        if (data) {
            if (data.ipfs === "") {
                console.log("unable to find token with lmuid", lmuid);
                dispatch(
                    showSnackbarAlert({
                        message: "We are unable to find the requested token",
                        severity: "error"
                    })
                );
                navigate("/");
                return;
            }

            if (details?.cuid) {
                setIsOwner(data?.custodial?.cuid === details?.cuid);
            } else {
                setIsOwner(false);
            }
            setTokenData(data);
        }
    }, [dispatch, navigate, data, error, lmuid]);

    const habdleAddToBag = useCallback(() => {
        if (!cardOptions || !tokenData || !data) return;
        const { itemPrice } = itemData?.data?.data?.money;
        dispatch(
            setPrice({
                price: itemPrice
            })
        );

        dispatch(
            setImagePath({
                imagePath: tokenData?.metadata?.media
            })
        );

        dispatch(
            setLmuid({
                lmuid: lmuid
            })
        );

        dispatch(
            setSellerId({
                cuid: tokenData?.custodial?.cuid
            })
        );

        saveDataToLocalStorage(data);

        navigate(CART);
    }, [cardOptions, tokenData, itemData, data, dispatch, navigate]);

    const customOnChange = useCallback(
        async (tabValue) => {
            if (tabValue === "2") {
                const imagesData = await getAllImagesTrigger(lmuid).unwrap();
                setAllImages(imagesData?.data);
                setIsImagesRequested(true);
            }
        },
        [getAllImagesTrigger]
    );

    const filterHistoryData = useCallback((historyData) => {
        if (!historyData) {
            return;
        }

        const validTypes = ["SOLD", "CLAIMED_LAZY"];

        return historyData.filter((el) => {
            if (validTypes.includes(el?.type)) {
                return true;
            }
            return false;
        });
    }, []);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            {isMobile && (
                <Stack
                    direction='column'
                    justifyContent='space-between'
                    className={classes.mobilePadding}
                    sx={{
                        margin: {
                            xs: "40px 0 -12px",
                            md: "40px 0 32px"
                        }
                    }}
                >
                    <Text.Typography variant='h2'>{tokenData?.metadata?.name}</Text.Typography>
                    <Text.Typography variant='h5'>
                        Owned by <a href='/'>{tokenData?.owner?.username}</a>
                    </Text.Typography>
                </Stack>
            )}
            <Grid container justifyContent='space-between'>
                <Grid item xs={12} lg={6} md={6} classes={{ root: classes.gridGallery }}>
                    <Gallery.CardGallery tabsTitles={tabsTitles} customOnChange={customOnChange}>
                        <Box>
                            <Box className={classes.mainImage}>
                                <Box
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            md: !tokenData ? "100%" : "fit-content"
                                        }
                                    }}
                                    className={classes.imageMaxHeight}
                                >
                                    {tokenData ? (
                                        <img
                                            src={tokenData?.metadata.media}
                                            width='100%'
                                            alt={tokenData?.metadata.title}
                                            className={classes.imageMaxHeight}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                height: {
                                                    xs: "390px",
                                                    md: "658px"
                                                },
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {tokenData?.metadata && (
                                <Grid container gap={1}>
                                    <Grid className={classes.cardMedia} item>
                                        <CardMedia
                                            component='img'
                                            style={{ borderRadius: "8px" }}
                                            image={tokenData?.metadata.media}
                                            alt={tokenData?.metadata.title}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                        <Box>
                            {allImages?.length ? (
                                <>
                                    <Carousel
                                        autoPlay={false}
                                        indicators={false}
                                        index={currentImageIndex}
                                        sx={{
                                            height: {
                                                xs: "390px",
                                                md: "100%"
                                            }
                                        }}
                                    >
                                        {allImages?.map(({ imagePath }) => (
                                            <img
                                                src={config.services.card.aws_s3_bucket + imagePath}
                                                alt='product card'
                                                className={classes.mainImageSeller}
                                            />
                                        ))}
                                    </Carousel>
                                    <Box className={classes.cardMediaWrapper}>
                                        <Box gap={1} display='-webkit-box'>
                                            {allImages?.map(({ imagePath }, index) => (
                                                <Grid
                                                    className={classes.cardMedia}
                                                    item
                                                    key={index}
                                                >
                                                    <CardMedia
                                                        component='img'
                                                        style={{
                                                            borderRadius: "8px",
                                                            height: "100%"
                                                        }}
                                                        image={
                                                            config.services.card.aws_s3_bucket +
                                                            imagePath
                                                        }
                                                        alt='image item'
                                                        onClick={() => {
                                                            setCurrentImageIndex(index);
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        height: {
                                            xs: "390px",
                                            md: "658px"
                                        },
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    {isImagesRequested ? (
                                        <Text.Typography variant='h1'>No images</Text.Typography>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Gallery.CardGallery>
                </Grid>

                <Grid item xs={12} lg={5} md={5}>
                    {!isMobile && (
                        <Stack
                            direction='column'
                            justifyContent='space-between'
                            mb={4}
                            mt={5}
                            className={classes.mobilePadding}
                        >
                            <Text.Typography variant='h2'>{tokenData?.metadata?.name}</Text.Typography>
                            <Text.Typography variant='h5'>
                                Owned by <a href='/'>{tokenData?.owner?.username}</a>
                            </Text.Typography>
                        </Stack>
                    )}

                    <Box mb={4} className={classes.mobilePadding} sx={{mt: {xs: "16px"}}}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            style={{ width: "max-content" }}
                        >
                            Similar Items
                        </Button>
                    </Box>
                    <Box sx={{
                        padding: {
                            xs: "40px 24px",
                            md: "0"
                        },
                        background: {
                            xs: "#f5f5f5"
                        }
                    }}>
                        <Cards.Curvy sx={{ padding: "40px" }}>
                            {cardOptions ? (
                                <TableContainer>
                                    <Table
                                        aria-label='caption table'
                                        sx={{ tableLayout: "auto", width: "max-content" }}
                                    >
                                        <TableBody>
                                            {cardOptions?.map(({ trait_type, value }) => (
                                                <Tables.RowTable
                                                    key={trait_type}
                                                    secondColumnCell={value}
                                                    firstColumnCell={trait_type}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Cards.Curvy>
                    </Box>

                    <Grid
                        container
                        justifyContent='space-between'
                        mt={3}
                        mb={3}
                        spacing={3}
                        className={classes.mobilePadding}
                    >
                        {isOwner && !isLoading && (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <Text.Typography>You are the owner of this card</Text.Typography>
                            </Box>
                        )}
                        <Grid item xs={12} md={6} lg={6}>
                            <Button
                                onClick={habdleAddToBag}
                                disabled={
                                    itemData?.isError ||
                                    itemData?.isLoading ||
                                    itemData?.data?.data?.status.includes("not") ||
                                    isOwner
                                }
                                variant='contained'
                                color='primary'
                                size='large'
                                style={{ width: "100%", textTransform: "inherit" }}
                            >
                                Add to bag
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Button
                                variant='outlined'
                                color='secondary'
                                size='large'
                                style={{ width: "100%", textTransform: "inherit" }}
                            >
                                Chat to seller
                            </Button>
                        </Grid>
                    </Grid>

                    {/* <Box className={classes.mobilePadding}>
                        <Tables.CollabsibleTable data={filterHistoryData(historyData?.currentData)} />
                    </Box> */}
                </Grid>
            </Grid>
        </Wrappers.Container>
    );
};

export default withStyles(styles)(CardPage);
