import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { useAuthentication } from "@features/user";
import LoginForm from "@features/user/loginForm";
import { useVerifyAccountMutation } from "@app/services";
import { MY_PROFILE, PROFILE_ORDERS } from "../../pages.constants";

function EmailVerifier() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { authenticated, details } = useAuthentication();
    const { search } = useLocation();
    const token = useMemo(() => new URLSearchParams(search).get("token"), [search]);
    const [verified, setVerified] = useState(details ? details.verified : false);

    const [verifyAccount] = useVerifyAccountMutation();

    useEffect(() => {
        if (token && authenticated && !verified) {
            verifyAccount({ token }).then(({ data }) => {
                if (data?.verified) {
                    setVerified(true);
                }
            });
        }
    }, [verifyAccount, token, authenticated, verified]);

    useEffect(() => {
        if (verified) {
            dispatch(
                showSnackbarAlert({
                    message: `Account was verified`
                })
            );
            navigate(`${MY_PROFILE}/${PROFILE_ORDERS}`);
        }
    }, [verified, navigate, dispatch]);

    return (
        <Box sx={{ flexGrow: 1 }} textAlign='center'>
            <Grid container minHeight='80vh' justifyContent='center' alignContent='center'>
                <Grid item>
                    <Box p={2}>
                        {!authenticated ? (
                            <React.Fragment>
                                <Typography variant='body2' p={1} color='error'>
                                    Please login to verify account
                                </Typography>
                                <LoginForm />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <CircularProgress color='primary' />
                                <Typography variant='h5' p={2} color='text.secondary'>
                                    {verified ? "success" : "verifying"}
                                </Typography>
                            </React.Fragment>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default EmailVerifier;
