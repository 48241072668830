export const HOME_URL = "/";
export const INSTANT_CASH_URL = "/instantcash";
export const REGISTER = "/sign-up";
export const RESET_PASSWORD = "/reset-password";
export const CLAIM_NFT = "/claim";
export const LOGIN = "/sign-in";
export const MY_PROFILE = "/my-account";
export const CART = "/cart";
export const CHECK_OUT = "/check-out";
export const BUYER_REGISTER = "/buyer-register";

export const PROFILE = "profile";
export const PROFILE_ORDERS = "dashboard";
export const EDIT_PROFILE = "edit-profile";
export const PROFILE_SETTINGS = "settings";
export const CONNECT_STRIPE = "connect-stripe";

export const CARD = "/card";
export const EDIT_CARD = "/edit-card";
export const CARD_SELLER = "/card-seller";
