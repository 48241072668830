import { useState, useEffect, useMemo } from "react";
import { useTokenCreatedEvents, useTokenUpdatedEvents, useTransferEvents } from "../";
import {
    useGetBlacklistedTokensQuery,
    useGetTokensQuery,
    useGetListingsV2Query
} from "@app/services";

export function blockToNFT({
    tokenData: {
        returnValues: { tokenId, tokenURI },
        transactionHash,
        address
    }
}) {
    return { tokenId, tokenURI, address, transactionHash };
}

export function lazyToNFT(custodial) {
    return {
        custodial,
        tokenId: custodial.lmuid,
        owner: custodial.cuid,
        type: "Lazy"
    };
}

export function blockToTransfer({ returnValues: { from, to }, transactionHash }) {
    return { from, to, transactionHash };
}

export function blockToNFTUpdate({ returnValues: { tokenURI } }) {
    return { tokenURI };
}

export function useAdminNFTs() {
    const tokenTransfered = useTransferEvents();
    const tokensUpdated = useTokenUpdatedEvents();
    const tokensCreated = useTokenCreatedEvents();

    const [unburnedNFTs, setUnburnedNFTs] = useState([]);
    const { data: goNFTData, refetch } = useGetListingsV2Query(null);
    //const { data: goNFTData, refetch } = useGetTokensQuery(null);
    // console.log(goNFTData);
    useEffect(() => {
        if (tokenTransfered) {
            refetch();
        }
    }, [refetch, tokenTransfered]);

    useEffect(() => {
        // get all tokens created that have not been burned
        const chainTokens = tokensCreated
            .map(blockToNFT)
            .map((NFT) => ({
                ...NFT,
                updates: tokensUpdated
                    .filter(({ returnValues: { tokenId } }) => tokenId === NFT.tokenId)
                    .map(blockToNFTUpdate),
                transfers: tokenTransfered
                    .filter(({ returnValues: { tokenId } }) => tokenId === NFT.tokenId)
                    .map(blockToTransfer)
            }))
            // Apply the latest update and owner
            .map((NFT) => ({
                ...NFT,
                tokenURI:
                    NFT.updates && NFT.updates.length > 0
                        ? NFT.updates[NFT.updates.length - 1].tokenURI
                        : NFT.tokenURI,
                owner: (NFT.transfers && NFT.transfers.length > 0
                    ? NFT.transfers[NFT.transfers.length - 1].to
                    : ""
                ).toLowerCase()
            }))
            // filter burned tokens
            .filter(
                ({ tokenData: { owner } }) => owner !== "0x0000000000000000000000000000000000000000"
            )
            // Ignore malformed tokens
            .filter(({ tokenData: { tokenURI } }) => tokenURI)
            // Apply custodial data
            .map((NFT) => {
                const custodial = goNFTData
                    ? goNFTData.find(({ tokenId }) => tokenId === NFT.tokenId)
                    : null;
                if (!custodial) return { ...NFT, type: "Wallet" };
                return {
                    ...NFT,
                    custodial,
                    type: "Custodial"
                };
            })
            // Order newest first
            .reverse();

        // const tokensOnChainIds = chainTokens.map(({ tokenId }) => tokenId)

        setUnburnedNFTs([
            // Remaining tokens retrieved as goNFTData from useGetTokensQuery
            // They should have no id since they have not been minted and matched too an existing NFT.
            // These NFTs are api tokens and are not minted on the blockchain..
            // We need to support selling of these tokens and on sale these tokens will be minted to
            // the custodial wallet and owned custodially by the buyer.
            // The buyer should then have options to sell or transfer these tokens to a wallet they own.
            ...(goNFTData
                ? goNFTData.filter(({ status }) => status === "CLAIMED_LAZY").map(lazyToNFT)
                : []),
            ...(chainTokens ? chainTokens : [])
        ]);

        return () => setUnburnedNFTs([]);
    }, [tokensCreated, tokensUpdated, tokenTransfered, goNFTData]);

    return unburnedNFTs;
}

function useNFTs() {
    const NFTs = useAdminNFTs();
    const { data } = useGetBlacklistedTokensQuery(null);
    const [blacklistedIds, setBlacklistedIds] = useState([]);

    useEffect(() => {
        if (data && data.length) {
            setBlacklistedIds(data);
        }

        return () => setBlacklistedIds();
    }, [data]);

    const unblacklistedNFTs = useMemo(
        () =>
            NFTs.filter(({ tokenId }) =>
                blacklistedIds ? !blacklistedIds.includes(tokenId) : true
            ),
        [NFTs, blacklistedIds]
    );

    return unblacklistedNFTs;
}

export default useNFTs;
