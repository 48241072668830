import { Box } from "@mui/system";
import React from "react";
import { Text } from "@features/components";

function WithTitle(props) {
    const { titleVariant, titleText, children, ...restProps } = props;

    return (
        <>
            <Text.Typography variant={titleVariant || "h2"} mb={2}>
                {titleText || ""}
            </Text.Typography>
            <Box
                pt={4}
                sx={{
                    borderTop: "1px solid #DDD"
                }}
                {...restProps}
            >
                {children}
            </Box>
        </>
    );
}

export default WithTitle;
