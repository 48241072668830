import React, { useEffect, useMemo, useState } from "react";
import { TableContainer, Table, TableBody, CircularProgress, Stack, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Cards, Tables, Gallery, Text } from "@features/components";

import { useGetProductByImuidQuery, useUpdateCardPropertiesMutation } from "@app/services";
import { utils, constants } from "@app/utils";

import { styles } from "./styles";
import { useAuthentication } from "@features/user/index.js";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice.js";
import { useDispatch, useSelector } from "react-redux";
import useDimensions from "@app/pages/index/hooks/useMobile";
import {
    useGetProductAttributesByImuidQuery,
    useLazyGetProductUpdatedAttributesByImuidQuery
} from "@app/services/formsAPI";
import { SALE_LISTING_ATTRIBUTES_TEXT } from "@app/utils/getCardAttributes";
import ConnectEbayButton from "@app/navigations/connectEbayButton";
import { getCategoryId, getCategoryPath, getDynamicAttributes } from "../utils";

const tabsTitles = [
    { label: "Product Info", value: "1" }
    // { label: "Purchase Info", value: "2" },
    // { label: "Shipping Info", value: "3" }
];

const SELECT_INDEXES_SECOND_TABLE = [1];
const PRICE_FIELDS = ["Price", "Selling Price", "Shipping Price"];
const STATIC_FIELDS = ["Title", "Description", "Search Term", "Category"];

const TableDetails = ({
    classes,
    lmuid,
    itemData,
    refetchItemData,
    fieldsWithError,
    setFieldsWithError
}) => {
    const { isMobile } = useDimensions();
    const [formsAttributesValues, setFormsAttributesValues] = useState([]);
    const [updatedAspectsLoading, setUpdatedAspectsLoading] = useState(true);
    const [currentlySelectedCategory, setCurrentlySelectedCategory] = useState(null);
    const [saleListeningAttributeValues, setSaleListeningAttributeValues] = useState({
        postcode: "No value",
        condition: "No value",
        sellingPrice: "No value",
        shippingPrice: "No value"
    });
    const userToken = useSelector((state) => state.user.token);

    const [getUpdatedFormsAttributesTrigger] = useLazyGetProductUpdatedAttributesByImuidQuery();

    const purchaseAttributes = useMemo(() => utils.getPurchaseAttributes(itemData), [itemData]);

    const dispatch = useDispatch();

    const shippingAttributes = useMemo(() => utils.getShippingAttributes(itemData), [itemData]);
    const [createCardItemTrigger] = useUpdateCardPropertiesMutation();
    const { details } = useAuthentication();

    const formsAttributes = useGetProductAttributesByImuidQuery({
        lmuid,
        token: userToken
    });

    const getFormsAttribute = (el) => {
        let key;
        if (Object.keys(constants.PRODUCT_ATTRIBUTES_TEXT).includes(el?.name)) {
            key = constants.PRODUCT_ATTRIBUTES_TEXT[el.name];
        } else {
            key = el?.name;
        }

        if (itemData.data?.data?.parsedInformation?.[key]) {
            return itemData.data?.data?.parsedInformation?.[key];
        }
        return el?.selectedValue;
    };

    const handleSearchTermChange = (type, value) => {
        setUpdatedAspectsLoading(true);
        const newCategoryPath = formsAttributes.data.formTypes.find((el) => el.searchTerm === value)
            .categoryTree[0].categoryPath;

        const cardData = {
            parsedInformation: {
                ...itemData.data.data.parsedInformation,
                searchTerm: value,
                categoryPath: newCategoryPath
            }
        };
        setCurrentlySelectedCategory(newCategoryPath);
        createCardItemTrigger({
            userId: details.cuid,
            data: cardData,
            cardId: itemData.data.data.uuid
        })
            .unwrap()
            .catch((error) => {
                if (!error?.data?.extra?.clientSecret) {
                    dispatch(
                        showSnackbarAlert({
                            message: error.data.message,
                            severity: "error"
                        })
                    );
                }
            });

        setFormsAttributesValues((prevState) =>
            prevState.map((el) => {
                if (el.title === "Category") {
                    return {
                        ...el,
                        value: newCategoryPath
                    };
                }
                return el;
            })
        );
    };

    // const getDynamicAttributes = (categoryId, formsAttributes) => {
    //     const attributesIndexes = formsAttributes?.data?.categoryAspectIndex[categoryId]

    //     return (formsAttributes?.data?.aspects).filter((_, index) => {
    //         if((attributesIndexes || []).includes(index)) return true
    //         return false
    //     })
    // }

    // const getCategoryPath = (currentlySelectedCategory, formsAttributes, itemData) => {
    //     let categoryPath = null;
    //     const data = formsAttributes?.data

    //     if (currentlySelectedCategory) {
    //         categoryPath = currentlySelectedCategory;
    //     } else if (itemData?.data?.data?.parsedInformation?.categoryPath) {
    //         categoryPath = itemData?.data?.data?.parsedInformation?.categoryPath;
    //     } else {
    //         categoryPath = data?.formTypes?.[formsAttributes?.data?.selectedFormIndex || 0]?.categoryTree?.[0]?.categoryPath;
    //     }

    //     return categoryPath
    // }

    // const getCategoryId = (formsAttributes, categoryPath) => {
    //     let categoryId = null;

    //     formsAttributes.data.formTypes.forEach((type) => {
    //         if (categoryId) return;

    //         type.categoryTree.forEach((el) => {
    //             console.log(el)
    //             if (el.categoryPath === categoryPath) {
    //                 categoryId = el.categoryId;
    //                 return;
    //             }
    //         });
    //     });

    //     if(!categoryId) {
    //         categoryId = formsAttributes.data.formTypes[formsAttributes.data.selectedFormIndex].categoryTree[0].categoryId
    //     }

    //     return categoryId
    // }

    useEffect(() => {
        if (!formsAttributes?.isSuccess || !itemData || !formsAttributes?.data?.aspects) return;

        const data = formsAttributes?.data;

        const categoryPath = getCategoryPath(currentlySelectedCategory, formsAttributes, itemData);

        const categoryId = getCategoryId(data, categoryPath);

        setUpdatedAspectsLoading(false);
        setFormsAttributesValues([
            {
                title: "Title",
                value: itemData.data?.data?.parsedInformation?.nameOfProduct || data?.productName,
                options: null
            },
            {
                title: "Description",
                value:
                    itemData.data?.data?.parsedInformation?.productDescription ||
                    data?.productDescription,
                options: null
            },
            // {
            //     title: "Search Term",
            //     value:
            //         itemData.data?.data?.parsedInformation?.searchTerm ||
            //         data?.formTypes[formsAttributes?.data?.selectedFormIndex || 0]?.searchTerm,
            //     options: data?.formTypes.map((el) => el?.searchTerm),
            //     onClick: handleSearchTermChange
            // },
            {
                title: "Category",
                value: categoryPath,
                options: data.formTypes[
                    formsAttributes?.data?.selectedFormIndex || 0
                ].categoryTree.map((el) => el?.categoryPath),
                onClick: (type, value) => {
                    setUpdatedAspectsLoading(true);
                    setCurrentlySelectedCategory(value);
                    handleClick("categoryPath", value, true);
                }
            },
            ...(getDynamicAttributes(categoryId, data) || [])
                .filter((el) => el.required)
                .map((attribute) => ({
                    title: attribute?.name,
                    value: getFormsAttribute(attribute),
                    options: attribute?.options?.map((el) => el?.localizedValue)
                }))
        ]);

        setSaleListeningAttributeValues({
            postcode: itemData.data?.data?.parsedInformation?.postcode,
            condition: itemData.data?.data?.parsedInformation?.condition,
            sellingPrice: itemData.data?.data?.parsedInformation?.sellingPrice,
            shippingPrice: itemData.data?.data?.parsedInformation?.shippingPrice
        });
    }, [
        formsAttributes,
        itemData,
        formsAttributesValues.find((el) => el?.title === "Category")?.value
    ]);

    const handleFormAttributeClick = (title, value) => {
        setFormsAttributesValues((prevState) =>
            prevState.map((attribute) => {
                if (attribute?.title === title) {
                    return {
                        ...attribute,
                        value
                    };
                }
                return attribute;
            })
        );
    };

    const handleClick = (displayedText, value, defaultKey) => {
        const mergedObject = {
            ...constants.PRODUCT_ATTRIBUTES_TEXT,
            ...constants.SHIPPING_ATTRIBUTES_TEXT,
            ...constants.PURCHASE_ATTRIBUTES_TEXT
        };
        // Convert displayed text to attribute key using PRODUCT_ATTRIBUTES_TEXT
        const key = Object.keys(mergedObject).find(
            (attrKey) => mergedObject[attrKey] === displayedText
        );
        if (key || defaultKey) {
            const cardData = {
                parsedInformation: {
                    ...itemData.data.data.parsedInformation,
                    [key ? key : displayedText]: value
                }
            };
            createCardItemTrigger({
                userId: details.cuid,
                data: cardData,
                cardId: itemData.data.data.uuid
            })
                .unwrap()
                .catch((error) => {
                    if (!error?.data?.extra?.clientSecret) {
                        dispatch(
                            showSnackbarAlert({
                                message: error.data.message,
                                severity: "error"
                            })
                        );
                    }
                });
            refetchItemData();
        }
    };

    const formStaticFields = useMemo(() => {
        return formsAttributesValues.filter((el) => STATIC_FIELDS.includes(el.title));
    }, [formsAttributesValues]);

    const formDynamicFields = useMemo(() => {
        return formsAttributesValues.filter((el) => !STATIC_FIELDS.includes(el.title));
    }, [formsAttributesValues]);

    const formValuesLoading = useMemo(() => {
        if (formsAttributes.isLoading) return true;
        if (updatedAspectsLoading) {
            if (!formsAttributesValues.length) return true;
            return false;
        }
    }, [updatedAspectsLoading, formsAttributesValues, formsAttributes]);

    const resetError = (title) => {
        setFieldsWithError((prevState) => ({
            ...prevState,
            [title]: false
        }));
    };

    return (
        <Gallery.CardGallery tabsTitles={tabsTitles} style={{ padding: 0 }}>
            <div>
                <Stack spacing={4}>
                    {details?.eBayIntegrated && details && (
                        <Cards.Curvy
                            sx={{
                                mt: {
                                    xs: "40px",
                                    md: 0
                                }
                            }}>
                            <Stack
                                aria-label='caption table'
                                sx={{ tableLayout: isMobile ? "fixed" : "auto", width: "100%" }}>
                                {formValuesLoading ? (
                                    <Box py='8px' display='flex' justifyContent='center'>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    formsAttributes.isSuccess && (
                                        <>
                                            {formStaticFields?.map((info, index) => (
                                                <>
                                                    {info?.options ? (
                                                        <Tables.CollabsibleRow
                                                            key={index}
                                                            classes={{
                                                                editableRow: classes.editableRow
                                                            }}
                                                            firstColumnCell={info?.title}
                                                            type={info?.title}
                                                            handleClick={(type, value) => {
                                                                if (info?.onClick) {
                                                                    info.onClick(type, value);
                                                                } else {
                                                                    handleClick(type, value, true);
                                                                }
                                                            }}
                                                            secondColumnCell={
                                                                info?.value || "No value"
                                                            }
                                                            options={info?.options}
                                                            error={fieldsWithError[info?.title]}
                                                            resetError={resetError}
                                                        />
                                                    ) : (
                                                        <Tables.EditableRow
                                                            key={index}
                                                            classes={{
                                                                editableRow: classes.editableRow
                                                            }}
                                                            wrapText={info?.title === "Description"}
                                                            firstColumnCell={info?.title}
                                                            type={info?.title}
                                                            handleClick={(type, value) =>
                                                                handleClick(type, value, true)
                                                            }
                                                            secondColumnCell={
                                                                info?.value || "No value"
                                                            }
                                                            emptyOnEdit={info?.value ? false : true}
                                                            options={info?.options}
                                                            error={fieldsWithError[info?.title]}
                                                            resetError={resetError}
                                                        />
                                                    )}
                                                </>
                                            ))}
                                            {updatedAspectsLoading ? (
                                                <Box
                                                    sx={{
                                                        mt: 2,
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                    <CircularProgress />
                                                </Box>
                                            ) : (
                                                <>
                                                    {formDynamicFields?.map((info, index) => (
                                                        <>
                                                            {info?.options ? (
                                                                <Tables.CollabsibleRow
                                                                    key={index}
                                                                    classes={{
                                                                        editableRow:
                                                                            classes.editableRow
                                                                    }}
                                                                    firstColumnCell={info?.title}
                                                                    type={info?.title}
                                                                    handleClick={(type, value) => {
                                                                        if (info?.onClick) {
                                                                            info.onClick(value);
                                                                        } else {
                                                                            handleClick(
                                                                                type,
                                                                                value,
                                                                                true
                                                                            );
                                                                        }
                                                                    }}
                                                                    secondColumnCell={
                                                                        info?.value || "No value"
                                                                    }
                                                                    options={info?.options}
                                                                    error={
                                                                        fieldsWithError[info?.title]
                                                                    }
                                                                    resetError={resetError}
                                                                />
                                                            ) : (
                                                                <Tables.EditableRow
                                                                    key={index}
                                                                    classes={{
                                                                        editableRow:
                                                                            classes.editableRow
                                                                    }}
                                                                    firstColumnCell={info?.title}
                                                                    type={info?.title}
                                                                    handleClick={(type, value) =>
                                                                        handleClick(
                                                                            type,
                                                                            value,
                                                                            true
                                                                        )
                                                                    }
                                                                    secondColumnCell={
                                                                        info?.value || "No value"
                                                                    }
                                                                    emptyOnEdit={
                                                                        info?.value ? false : true
                                                                    }
                                                                    options={info?.options}
                                                                    error={
                                                                        fieldsWithError[info?.title]
                                                                    }
                                                                    resetError={resetError}
                                                                />
                                                            )}
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )
                                )}
                            </Stack>
                        </Cards.Curvy>
                    )}
                    <Stack spacing={2}>
                        <Box ml={{ xs: "16px", md: 0 }}>
                            <Text.Typography variant='body2' sx={{ fontWeight: 600 }}>
                                Sale Listing Details
                            </Text.Typography>
                        </Box>
                        <Cards.Curvy
                            sx={{
                                mt: {
                                    xs: "40px",
                                    md: 0
                                }
                            }}>
                            {formValuesLoading ? (
                                <Box py='8px' display='flex' justifyContent='center'>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Stack
                                    aria-label='caption table'
                                    sx={{
                                        tableLayout: isMobile ? "fixed" : "auto",
                                        width: "100%"
                                    }}>
                                    {itemData.isLoading ? (
                                        <Box py='8px' display='flex' justifyContent='center'>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        Object.keys(saleListeningAttributeValues)?.map(
                                            (key, index) => (
                                                <>
                                                    {SELECT_INDEXES_SECOND_TABLE.includes(index) ? (
                                                        <Tables.CollabsibleRow
                                                            classes={{
                                                                editableRow: classes.editableRow
                                                            }}
                                                            type={key}
                                                            handleClick={(type, value) =>
                                                                handleClick(type, value, true)
                                                            }
                                                            secondColumnCell={
                                                                saleListeningAttributeValues[key]
                                                            }
                                                            firstColumnCell={
                                                                SALE_LISTING_ATTRIBUTES_TEXT[key]
                                                            }
                                                            options={[
                                                                "New with box",
                                                                "New without box",
                                                                "New with defects",
                                                                "Used"
                                                            ]}
                                                            error={
                                                                fieldsWithError[
                                                                    SALE_LISTING_ATTRIBUTES_TEXT[
                                                                        key
                                                                    ]
                                                                ]
                                                            }
                                                            resetError={resetError}
                                                        />
                                                    ) : (
                                                        <Tables.EditableRow
                                                            classes={{
                                                                editableRow: classes.editableRow
                                                            }}
                                                            type={key}
                                                            handleClick={(type, value) =>
                                                                handleClick(type, value, true)
                                                            }
                                                            secondColumnCell={
                                                                saleListeningAttributeValues[key] ||
                                                                "No value"
                                                            }
                                                            firstColumnCell={
                                                                SALE_LISTING_ATTRIBUTES_TEXT[key]
                                                            }
                                                            emptyOnEdit={
                                                                saleListeningAttributeValues[
                                                                    key
                                                                ] === "No value" ||
                                                                !saleListeningAttributeValues[key]
                                                            }
                                                            isPrice={PRICE_FIELDS.includes(
                                                                SALE_LISTING_ATTRIBUTES_TEXT[key]
                                                            )}
                                                            error={
                                                                fieldsWithError[
                                                                    SALE_LISTING_ATTRIBUTES_TEXT[
                                                                        key
                                                                    ]
                                                                ]
                                                            }
                                                            resetError={resetError}
                                                        />
                                                    )}
                                                </>
                                            )
                                        )
                                    )}
                                </Stack>
                            )}
                        </Cards.Curvy>
                    </Stack>
                </Stack>
            </div>
            {/* <Cards.Curvy
                sx={{
                    mt: {
                        xs: "40px",
                        md: 0
                    }
                }}>
                <TableContainer>
                    <Table aria-label='caption table' sx={{ tableLayout: "auto", width: "100%" }}>
                        <TableBody>
                            {itemData.isLoading ? (
                                <CircularProgress />
                            ) : (
                                purchaseAttributes?.map((info) => (
                                    <Tables.EditableRow
                                        classes={{ editableRow: classes.editableRow }}
                                        type={Object.keys(info)[0]}
                                        handleClick={handleClick}
                                        secondColumnCell={Object.values(info)[0]}
                                        firstColumnCell={Object.keys(info)[0]}
                                        isPrice={PRICE_FIELDS.includes(Object.keys(info)[0])}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Cards.Curvy>
            <Cards.Curvy
                sx={{
                    mt: {
                        xs: "40px",
                        md: 0
                    }
                }}>
                <TableContainer>
                    <Table aria-label='caption table' sx={{ tableLayout: "auto", width: "100%" }}>
                        <TableBody>
                            {itemData.isLoading ? (
                                <CircularProgress />
                            ) : (
                                shippingAttributes?.map((info) => (
                                    <Tables.EditableRow
                                        classes={{ editableRow: classes.editableRow }}
                                        type={Object.keys(info)[0]}
                                        handleClick={handleClick}
                                        secondColumnCell={Object.values(info)[0]}
                                        firstColumnCell={Object.keys(info)[0]}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Cards.Curvy> */}
        </Gallery.CardGallery>
    );
};

export default withStyles(styles)(TableDetails);
