import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { setLoginReturn } from "../../app.slice";
import { useAuthentication } from "@features/user";
import { Wrappers } from "@features/components";
import { CLAIM_NFT, HOME_URL, LOGIN } from "../../pages.constants";
import { UserCard } from "../login/userCard";
import { setLmuid } from "../../card.slice";

function LazyTokenClaimPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { authenticated } = useAuthentication();
    const { search } = useLocation();

    const lmuid = useMemo(() => new URLSearchParams(search).get("lmuid"), [search]);

    useEffect(() => {
        if (!authenticated) {
            dispatch(
                setLmuid({
                    lmuid
                })
            );
            localStorage.setItem("lmuid", JSON.stringify(lmuid));
            dispatch(setLoginReturn({ referer: `/claim?lmuid=${lmuid}` }));
            navigate(LOGIN, { state: `/claim?lmuid=${lmuid}` });
        }
    }, [dispatch, authenticated, lmuid, dispatch]);

    if (!lmuid) {
        dispatch(
            showSnackbarAlert({
                message: "Invalid or missing claim",
                severity: "error"
            })
        );
        navigate(HOME_URL);
    }

    return (
        <Wrappers.Container isFullHeight>
            <UserCard lmuid={lmuid} url={CLAIM_NFT} />
        </Wrappers.Container>
    );
}

export default LazyTokenClaimPage;
