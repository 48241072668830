import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Stack, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import { Wrappers, Text, Cards, Button } from "@features/components";
import { CHECK_OUT, REGISTER } from "@app/pages.constants";
import useCardData from "@features/fvtoken/hooks/useCardData";
import { useGetProductByImuidQuery } from "@app/services";

import { styles } from "./styles";

const CartPage = ({ classes }) => {
    const navigate = useNavigate();
    const lmuid = useSelector((state) => state.card.lmuid);
    const { cardName, cardDescription, cardPrice, cardImagePath } = useCardData();
    const { data: cardData, isLoading: isCardLoading } = useGetProductByImuidQuery(lmuid, {
        skip: !lmuid
    });
    const [shippingPrice, setShippingPrice] = useState(0);

    const allowRender = useMemo(() => {
        if (!cardName || !cardDescription || !cardPrice) {
            return false;
        }
        return true;
    }, [cardName, cardDescription, cardPrice]);

    useEffect(() => {
        if (isCardLoading) return;

        setShippingPrice(
            cardData?.data?.money?.internationalShippingPrice +
                cardData?.data?.money?.domesticShippingPrice || 0
        );
    }, [isCardLoading]);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            <Text.Typography variant='h2' mt={7} className={classes.mobilePadding}>
                Bag
            </Text.Typography>
            <Grid container direction='row' mt={7} spacing={{ xs: 0, sm: 0, md: 10 }} mb={7}>
                <Grid item xs={12} lg={8} md={8} className={classes.mobilePadding}>
                    {allowRender && (
                        <Cards.Product
                            name={cardName}
                            description={cardDescription}
                            price={cardPrice}
                            imagePath={cardImagePath}
                        />
                    )}
                </Grid>

                <Grid item xs={12} lg={4} md={4}>
                    <Cards.Curvy classes={{ root: classes.cardCurvy }}>
                        <Text.Typography variant='h3' mb={4}>
                            Summary
                        </Text.Typography>
                        <Box>
                            <Stack direction='row' justifyContent='space-between'>
                                <Text.Typography variant='body2' mr={2}>
                                    Subtotal
                                </Text.Typography>
                                <Text.Typography variant='body2'>£{cardPrice}</Text.Typography>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' mt={1}>
                                <Text.Typography variant='body2'>
                                    Shipping & Delivery
                                </Text.Typography>
                                <Text.Typography variant='body2'>£{shippingPrice}</Text.Typography>
                            </Stack>
                        </Box>
                        <Box
                            mb={4}
                            mt={4}
                            pb={1}
                            sx={{
                                borderBottom: "1px solid #DDDDDD",
                                borderTop: "1px solid #DDDDDD"
                            }}
                        >
                            {" "}
                            <Stack direction='row' justifyContent='space-between' mt={1}>
                                <Text.Typography variant='body2'>Total</Text.Typography>
                                <Text.Typography variant='body2'>
                                    <b>{`£${Number.parseInt(cardPrice) + shippingPrice}`}</b>
                                </Text.Typography>
                            </Stack>
                        </Box>
                        <Button
                            onClick={() => navigate(CHECK_OUT)}
                            variant='contained'
                            color='primary'
                            size='large'
                            sx={{ width: "100%" }}
                        >
                            Checkout
                        </Button>
                        <Box mt={2}>
                            <Text.Typography variant='body1'>
                                Not a member? <a href={REGISTER}>Create an account.</a>
                            </Text.Typography>
                        </Box>
                    </Cards.Curvy>
                </Grid>
            </Grid>
        </Wrappers.Container>
    );
};

export default withStyles(styles)(CartPage);
