// Card imports
import ImageId from "./cards/imageId";
import Curvy from "./cards/curvy";
import Product from "./cards/product";

// Text imports
import DashHeader from "./text/dash-header";
import Typography from "./text/typography";

// Input imports
import EtherSelection from "./inputs/ethSelection";
import InputField from "./inputs/inputField";
import Dropdown from "./inputs/dropdown";
import ControlledInput from "./inputs/controlledInput";

// Dialog imports
import EditNFT from "./dialogs/editNFT";
import TransferNFT from "./dialogs/transferNFTDialog";
import Confirmation from "./dialogs/confirmationDialog";
import AddTrait from "./dialogs/addTraitDialog";

import LoadingBackdrop from "./wrappers/loading.js";
import Container from "./wrappers/container";

// Button
export * from "./button";

// Tables
import EditableRow from "./tables/EditableRow";
import CollabsibleRow from "./tables/CollabsibleRow/CollabsibleRow";
import RowTable from "./tables/rowTable";
import StyledTable from "./tables/styledTable";
import CollabsibleTable from "./tables/collabsibleTable";

//Gallery
import CardGallery from "./gallery/cardGallery";
import WithTitle from "./wrappers/withTitle";

//Footer
export * from "./footer";

export const Wrappers = {
    LoadingBackdrop,
    WithTitle,
    Container
};

export const Cards = {
    ImageId,
    Curvy,
    Product
};

export const Text = {
    DashHeader,
    Typography
};

export const FormInputs = {
    EtherSelection,
    InputField,
    Dropdown,
    ControlledInput
};

export const Dialogs = {
    EditNFT,
    TransferNFT,
    Confirmation,
    AddTrait
};

export const Tables = {
    RowTable,
    StyledTable,
    CollabsibleTable,
    EditableRow,
    CollabsibleRow
};

export const Gallery = {
    CardGallery
};
