import { Box, Grid, Slider, Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import InputField from "@features/components/inputs/inputField";
import Typography from "@features/components/text/typography";
import CheckboxListFilter from "./checkboxListFilter";
import { availability, brands, colours, gender, rangeMarks, sizes } from "./filter.config";
import FilterSectionWrapper from "./filterSectionWrapper";

const styles = {
    slider: {
        [`& .MuiSlider-thumb`]: {
            width: "23px",
            height: "23px",
            background: "#fff",
            border: "1px solid #757575"
        },
        [`& .MuiSlider-rail`]: {
            height: "1px",
            background: "#757575"
        },
        [`& .MuiSlider-track`]: {
            height: "1px"
        },
        [`& .MuiSlider-mark`]: {
            opacity: 0
        },
        [`& .MuiSlider-markLabel`]: {
            color: "#111"
        }
    },
    sizeBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "48px",
        height: "48px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        cursor: "pointer"
    },
    colourItemContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    colourItem: {
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        marginBottom: "8px"
    }
};

function FilterSections({ classes }) {
    const [rangeValue, setRangeValue] = useState([0, 5000]);

    const handleSliderChange = (_, newValue) => {
        setRangeValue(newValue);
    };

    const handleInputChange = (e, isMinValue) => {
        const price = e.target.value?.substr(1);

        if (!Number.parseInt(price) && price.length !== 0) {
            return;
        }

        if (isMinValue) {
            setRangeValue((prevRangeValue) => [price, prevRangeValue[1]]);
        } else {
            setRangeValue((prevRangeValue) => [prevRangeValue[0], price]);
        }
    };

    const generateSizeFilter = useCallback(() => {
        const filterElements = [];

        sizes.forEach((el, index) => {
            filterElements.push(
                <Box key={index} className={classes.sizeBox}>
                    <Typography variant='body1'>{el}</Typography>
                </Box>
            );
        });

        return filterElements;
    }, []);

    const generateColoursFilter = useCallback(() => {
        const filterElements = [];

        colours.forEach((el, index) => {
            filterElements.push(
                <Grid item xs={3} key={index} className={classes.colourItemContainer}>
                    <Box
                        className={classes.colourItem}
                        sx={{
                            background: el.colour,
                            border: el.colour === "#fff" ? "1px solid #ddd" : null
                        }}
                    />
                    <Typography variant='h5'>{el.label}</Typography>
                </Grid>
            );
        });

        return filterElements;
    }, []);

    return (
        <Box>
            <FilterSectionWrapper title='Size'>
                <Stack>
                    <Stack direction='row' alignItems='center' spacing={2} mb={3}>
                        <InputField
                            value={`£${rangeValue[0]}`}
                            onChange={(e) => handleInputChange(e, true)}
                            label='min price'
                            innerLabel={true}
                        />
                        <Typography variant='h5'>to</Typography>
                        <InputField
                            value={`£${rangeValue[1]}`}
                            onChange={(e) => handleInputChange(e, false)}
                            label='max price'
                            innerLabel={true}
                        />
                    </Stack>
                    <Stack>
                        <Slider
                            value={rangeValue}
                            onChange={handleSliderChange}
                            marks={rangeMarks}
                            min={0}
                            max={5000}
                            className={classes.slider}
                        />
                    </Stack>
                </Stack>
            </FilterSectionWrapper>
            <FilterSectionWrapper title='Size'>
                <Stack direction='row' spacing={1}>
                    {generateSizeFilter()}
                </Stack>
            </FilterSectionWrapper>
            <FilterSectionWrapper title='Brand'>
                <CheckboxListFilter checkboxList={brands} />
            </FilterSectionWrapper>
            <FilterSectionWrapper title='Colour'>
                <Grid container rowSpacing={1} columnSpacing={5}>
                    {generateColoursFilter()}
                </Grid>
            </FilterSectionWrapper>
            <FilterSectionWrapper title='Gender'>
                <CheckboxListFilter checkboxList={gender} />
            </FilterSectionWrapper>
            <FilterSectionWrapper title='Availability'>
                <CheckboxListFilter checkboxList={availability} />
            </FilterSectionWrapper>
        </Box>
    );
}

export default withStyles(styles)(FilterSections);
