import api from "../../authenticationAPI";

export const userAPI = api.injectEndpoints({
    endpoints: (build) => ({
        getUsername: build.query({
            query: ({ cuid }) => ({
                url: `/api/v1/user/${cuid}`,
                method: "GET"
            }),
            providesTags: ["Username"]
        }),
        getUserDetails: build.query({
            query: () => ({
                url: "/api/v1/user",
                method: "GET"
            }),
            providesTags: ["User"]
        }),
        updateAccount: build.mutation({
            query: (body) => ({
                url: "/api/v1/user",
                method: "PUT",
                body
            }),
            invalidatesTags: ["User", "Username"]
        }),
        deleteAccount: build.mutation({
            query: () => ({
                url: "/api/v1/user",
                method: "DELETE"
            }),
            invalidatesTags: ["User", "Username"]
        })
    }),
    overrideExisting: true
});

export const {
    useGetUsernameQuery,
    useLazyGetUsernameQuery,
    useGetUserDetailsQuery,
    useLazyGetUserDetailsQuery,
    useUpdateAccountMutation,
    useDeleteAccountMutation
} = userAPI;
