import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { withStyles } from "@mui/styles";

import { Text } from "..";

const styles = (theme) => ({
    root: {
        borderBottom: "none",
        padding: "0 32px 8px 0px",

        [theme.breakpoints.down("md")]: {
            paddingRight: "16px"
        }
    },
    firstColumn: {
        minWidth: "169px",
        fontWeight: 600,

        [theme.breakpoints.down("350")]: {
            minWidth: "fit-content"
        }
    }
});

function RowTable(props) {
    const { classes, secondColumnCell, firstColumnCell, key } = props;

    return (
        <TableRow key={key}>
            <TableCell classes={{ root: classes.root }}>
                <Text.Typography variant='body2' className={classes.firstColumn}>
                    {firstColumnCell}
                </Text.Typography>
            </TableCell>
            <TableCell classes={{ root: classes.root }}>
                <Text.Typography variant='body2' className={classes.secondColumn}>
                    {secondColumnCell}
                </Text.Typography>
            </TableCell>
        </TableRow>
    );
}

export default withStyles(styles)(RowTable);
