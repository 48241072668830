import orderAPI from "../../orderAPI";

export const orderService = orderAPI.injectEndpoints({
    endpoints: (build) => ({
        createOrder: build.mutation({
            query: ({ body, userId }) => ({
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    userId: userId
                },
                url: `/api/v1/order/create`,
                body: JSON.stringify(body)
            })
        })
    }),
    overrideExisting: true
});

export const { useCreateOrderMutation } = orderService;
