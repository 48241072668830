import { useCallback, useEffect, useState } from "react";
import { useWeb3 } from "..";

function useNetworkExplorer() {
    const { network } = useWeb3();
    const [explorer, setExplorer] = useState();

    useEffect(() => {
        if (network && network.explorers) {
            setExplorer(network.explorers[0]);
        }
    }, [network]);

    const transactionHashToEtherscanAddress = useCallback(
        (tx) => {
            return `${explorer ? explorer.url : ""}/tx/${tx}`;
        },
        [explorer]
    );

    return {
        transactionHashToEtherscanAddress,
        explorer
    };
}

export default useNetworkExplorer;
