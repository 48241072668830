import api from "../../authenticationAPI";

export const authenticationApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation({
            query: ({ email, password }) => ({
                url: "/api/v1/auth/login",
                method: "POST",
                body: {
                    email,
                    password
                }
            })
        }),
        logout: build.mutation({
            query: () => ({
                url: "/api/v1/auth/logout",
                method: "POST"
            }),
            invalidatesTags: ["User"]
        }),
        refresh: build.mutation({
            query: () => ({
                url: "/api/v1/auth/refresh",
                method: "GET"
            })
        }),
        register: build.mutation({
            query: ({ name, username, email, password, ...additionalSignupData }) => ({
                url: "/api/v1/auth/register",
                method: "POST",
                body: {
                    name,
                    username,
                    email,
                    password,
                    ...additionalSignupData
                }
            })
        }),
        verifyAccount: build.mutation({
            query: ({ token }) => ({
                url: `/api/v1/auth/account/verify?token=${token}`,
                method: "POST"
            }),
            invalidatesTags: ["User"]
        }),
        resetPassword: build.mutation({
            query: ({ token, password }) => ({
                url: `/api/v1/auth/account/reset-password?token=${token}`,
                method: "POST",
                body: {
                    password
                }
            }),
            invalidatesTags: ["User"]
        }),
        updatePassword: build.mutation({
            query: ({ currentPassword, newPassword, email }) => ({
                url: `/api/v1/auth/account/update-password`,
                method: "POST",
                body: {
                    email,
                    new_password: newPassword,
                    password: currentPassword
                }
            }),
            invalidatesTags: ["User"]
        })
    }),
    overrideExisting: true
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useRegisterMutation,
    useRefreshMutation,
    useVerifyAccountMutation,
    useResetPasswordMutation,
    useUpdatePasswordMutation
} = authenticationApi;
