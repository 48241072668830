import React from "react";
import { withStyles } from "@mui/styles";
import { Card, CardContent, CardMedia } from "@mui/material";

import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as BrandAdidas } from "./brandAdidas.svg";

const styles = (theme) => ({
    root: {
        padding: "8px",
        borderRadius: "16px",
        cursor: "pointer",
        background: "white",
        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1);",
        width: "344px",
        height: "445px",
        position: "relative",

        [theme.breakpoints.down("md")]: {
            width: "155px",
            height: "217px",
            padding: "4px",
            borderRadius: "8px"
        }
    },
    scaled: {
        transform: "scale(1.2)"
    },
    boxRoot: {
        border: "1px black solid",
        borderRadius: "16px",
        height: "100%",
        padding: "0 !important",
        [theme.breakpoints.down("md")]: {
            borderRadius: "8px",
            border: "0.5px black solid"
        }
    },
    cardMedia: {
        borderRadius: "16px 16px 0 0",
        [theme.breakpoints.down("md")]: {
            borderRadius: "8px 8px 0 0"
        }
    },
    cardImage: {
        padding: "30px",

        [theme.breakpoints.down("md")]: {
            padding: "10px"
        }
    },
    brand: {
        background: "black",
        borderRadius: "8px",
        boxShadow: " 0px 0px 9px rgba(0, 0, 0, 0.2)",
        position: "absolute",
        top: "24px",
        left: "24px",
        height: "56px",
        width: "56px",

        [theme.breakpoints.down("md")]: {
            top: "16px",
            left: "16px",
            height: "24px",
            width: "24px",
            borderRadius: "3px"
        }
    },
    product: {
        objectFit: "contain",
        height: "344px",

        [theme.breakpoints.down("md")]: {
            height: "158px"
        }
    },
    blockedIcon: {
        width: "70px",
        height: "95px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
        position: "absolute"
    },
    blockedCard: {
        opacity: 0.25
    },
    loadingBrand: {
        display: "none"
    },
    loadingCard: {
        background: "gray",
        borderRadius: "16px",
        width: "calc(344px - 16px)",
        height: "calc(445px - 16px)",

        [theme.breakpoints.down("md")]: {
            borderRadius: "8px",
            width: "calc(163px - 16px)",
            height: "calc(224px - 16px)"
        }
    }
});
// Head of a MUI Card with embedded media and floating ID indicator
function ImageId({
    classes,
    children,
    src,
    name,
    blocked,
    loading,
    size,
    className,
    withoutPadding,
    scaled,
    ...rest
}) {
    return (
        <Card
            elevation={4}
            {...rest}
            className={`${classes.root} ${scaled ? classes.scaled : ""}`}
            classes={{ root: className }}>
            {blocked && <Lock className={classes.blockedIcon} />}
            <CardContent
                className={`
                ${loading ? classes.loadingCard : classes.boxRoot}  
                ${blocked ? classes.blockedCard : ""}
            `}>
                {/* <BrandAdidas className={loading ? classes.loadingBrand : classes.brand} /> */}
                <CardMedia
                    component='img'
                    height={loading ? "100" : "194"}
                    image={src}
                    alt={name}
                    className={classes.product}
                    classes={{
                        root: classes.cardMedia,
                        media: !withoutPadding && classes.cardImage
                    }}
                />
                {children}
            </CardContent>
        </Card>
    );
}

export default withStyles(styles)(ImageId);
