import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDeleteAlertMutation, useLazyGetAlertsQuery, useMarkReadMutation } from "@app/services";
import { useErrorMessage } from "../hooks/useErrorMessage";
import { useAuthentication } from "../user";

const AlertsContext = createContext();

export function useAlerts() {
    return useContext(AlertsContext);
}

export function AlertsContextProvider({ children }) {
    const { authenticated } = useAuthentication();
    const token = useSelector((state) => state.user.token);
    const [fetchAlerts, { data }] = useLazyGetAlertsQuery();
    const [updateMarkedRead, { error: markReadErr }] = useMarkReadMutation();
    const [triggerDeleteRead, { error: deleteAlertErr }] = useDeleteAlertMutation();
    const [alerts, setAlerts] = useState([]);

    useErrorMessage({ error: deleteAlertErr });
    useErrorMessage({ error: markReadErr });

    const markRead = useCallback(
        (auid, v) => {
            updateMarkedRead({ auid, read: v, hash: token });
        },
        [updateMarkedRead, token]
    );

    const deleteAlert = useCallback(
        (auid) => {
            triggerDeleteRead({ auid, hash: token });
        },
        [triggerDeleteRead, token]
    );

    useEffect(() => {
        if (authenticated) {
            console.log(`fetching alerts`);
            fetchAlerts({ hash: token });
        }
    }, [fetchAlerts, authenticated, token]);

    useEffect(() => {
        console.log("fetching alerts", data);
        if (data && data.alert && data.alert.notifications && data.alert.notifications.alerts) {
            setAlerts(
                data.alert.notifications.alerts.map(({ createdAt, readAt, ...rest }) => {
                    const createdDate = new Date(createdAt);
                    const readDate = readAt ? new Date(readAt) : null;
                    const read = Boolean(readAt);
                    return {
                        ...rest,
                        read,
                        createdAt,
                        readAt,
                        createdTimestamp: `${createdDate.toLocaleTimeString()} - ${createdDate.toLocaleDateString()}`,
                        readTimestamp: read
                            ? `${readDate.toLocaleTimeString()} - ${readDate.toLocaleDateString()}`
                            : null
                    };
                })
            );
        }
    }, [data]);

    return (
        <AlertsContext.Provider value={{ alerts, markRead, deleteAlert }}>
            {children}
        </AlertsContext.Provider>
    );
}
