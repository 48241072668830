import React, { useCallback, useEffect, useState } from "react";
import {
    Backdrop,
    Box,
    Button,
    CardContent,
    CircularProgress,
    FormGroup,
    FormHelperText,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import { useLazyMintTokenMutation } from "@app/services";
import { useDispatch } from "react-redux";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import useValidation from "../hooks/useValidation";
import { Cards, Dialogs, Text } from "../components";
import useNetworkExplorer from "../web3/hooks/useNetworkExplorer";
import useDialog from "../components/dialogs/useDialog";

export function LazyMintFormWithAttributes({ onChanged, sx, ...props }) {
    const dispatch = useDispatch();
    const { transactionHashToEtherscanAddress } = useNetworkExplorer();
    const [lazyMint, { data, isSuccess, error }] = useLazyMintTokenMutation();
    const [name, setName, nameValid, nameHelperText] = useValidation({ minLen: 2, maxLen: 50 });
    const [description, setDescription, descriptionValid, descriptionHelperText] = useValidation({
        minLen: 2,
        maxLen: 200
    });
    const [email, setEmail, emailValid, emailHelperText] = useValidation(
        { minLen: 2, maxLen: 500 },
        validateEmail
    );
    const [disabled, setDisabled] = useState(false);
    const [file, setFile] = useState();
    const [tx, setTx] = useState("");
    const [snackbar, setSnackbar] = useState(false);
    const [traits, setTraits] = useState([]);
    const {
        open: addTraitOpen,
        openDialog: openTraitDialog,
        closeDialog: closeTraitDialog
    } = useDialog();

    const addTrait = useCallback(
        (trait_type, value, display_type) => {
            setTraits([
                ...traits,
                {
                    trait_type,
                    value,
                    ...(display_type ? { display_type } : null)
                }
            ]);
        },
        [traits]
    );

    const handleAddTrait = ({ trait, value, type }) => {
        if (!traits.map(({ trait_type }) => trait_type).includes(trait)) {
            addTrait(trait, value, type);
        } else {
            dispatch(
                showSnackbarAlert({
                    message: `Attribute ${trait} already exists`,
                    severity: "info"
                })
            );
        }
        closeTraitDialog();
    };

    useEffect(() => {
        if (typeof onChanged === "function") onChanged({ name, description, email, file });
    }, [onChanged, name, description, email, file]);

    const handleFileSelect = () => {
        const input = document.createElement("input");
        input.hidden = "hidden";
        input.type = "file";
        input.onchange = (e) => setFile(e.target.files[0]);
        input.click();
    };

    const handleLazyMint = async () => {
        console.log("uploading image", {
            name,
            description,
            file,
            email
        });
        const form = new FormData();
        form.append("file", file, file.name);
        form.append("name", name);
        form.append("description", description);
        form.append("attributes", JSON.stringify(traits));
        form.append("email", email);

        setDisabled(true);
        await lazyMint({ form });
        setDisabled(false);

        // Clear out form
        setName("");
        setEmail("");
        setDescription("");
        setFile();
    };

    useEffect(() => {
        if (isSuccess && data) {
            console.log("lazy mint success", data);
            setTx(data.transactionHash);
            dispatch(
                showSnackbarAlert({
                    message: "Success, Please check your emails for to claim your NFT",
                    severity: "success"
                })
            );
        }
    }, [dispatch, data, isSuccess]);

    useEffect(() => {
        if (error) {
            console.error(error);
            dispatch(
                showSnackbarAlert({
                    message: `Sorry, we are unable to mint tokens at the moment`,
                    severity: "error"
                })
            );
        }
    }, [dispatch, error]);

    return (
        <Cards.Curvy sx={{ position: "relative", ...sx }} {...props}>
            <CardContent>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Text.DashHeader>
                        Create Lazy NFT{" "}
                        <Typography component='span' color='text.secondary'>
                            with attributes
                        </Typography>
                    </Text.DashHeader>
                    <Box></Box>
                </Stack>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <TextField
                        label='Name'
                        placeholder='Enter NFT name'
                        disabled={disabled}
                        value={name}
                        color={!nameValid ? "error" : "success"}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <FormHelperText>
                        <Typography variant='p' color='error'>
                            {nameHelperText || ""}
                        </Typography>
                    </FormHelperText>

                    <TextField
                        label='Description'
                        variant='outlined'
                        placeholder='Enter description'
                        value={description}
                        disabled={disabled}
                        multiline
                        minRows={4}
                        maxRows={4}
                        color={!descriptionValid ? "error" : "success"}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <FormHelperText>
                        <Typography variant='p' color='error'>
                            {descriptionHelperText || ""}
                        </Typography>
                    </FormHelperText>
                    <FormGroup>
                        <TextField
                            type='email'
                            label='Email'
                            placeholder='Enter your email address'
                            autoComplete='email'
                            disabled={disabled}
                            value={email}
                            color={!emailValid ? "error" : "success"}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormHelperText>
                            <Typography variant='p' color='error'>
                                {emailHelperText || ""}
                            </Typography>
                        </FormHelperText>
                    </FormGroup>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Attribute</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Display Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {traits.map(({ trait_type, value, display_type }, x) => (
                                <TableRow key={`trait_${trait_type}_${x}`}>
                                    <TableCell>{trait_type}</TableCell>
                                    <TableCell>{value}</TableCell>
                                    <TableCell>{display_type}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Stack direction='row' spacing={2} pb={3}>
                        <Button onClick={openTraitDialog}>Add Attribute</Button>
                    </Stack>
                    <Button
                        variant='outlined'
                        component='label'
                        disabled={disabled}
                        onClick={handleFileSelect}
                    >
                        {file ? file.name : "Upload media"}
                    </Button>
                    <Button
                        mt={1}
                        onClick={handleLazyMint}
                        disabled={disabled || !nameValid || !descriptionValid || !file}
                    >
                        Mint
                    </Button>
                </Stack>
                {disabled ? (
                    <Backdrop
                        sx={{
                            backgroundColor: "rgba(0,0,0,.2)",
                            position: "absolute",
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={disabled}
                    >
                        <CircularProgress color='primary' size='5rem' />
                    </Backdrop>
                ) : (
                    ""
                )}
            </CardContent>
            <Snackbar
                open={snackbar}
                autoHideDuration={25000}
                onClose={() => setSnackbar(false)}
                message='View last transaction'
                action={
                    <Button href={transactionHashToEtherscanAddress(tx)} target='_blank'>
                        View Transaction
                    </Button>
                }
            />
            <Dialogs.AddTrait
                fullWidth
                open={addTraitOpen}
                onClose={closeTraitDialog}
                onSubmit={handleAddTrait}
            />
        </Cards.Curvy>
    );
}

function LazyMintForm({ onChanged, sx, ...props }) {
    const dispatch = useDispatch();
    const { transactionHashToEtherscanAddress } = useNetworkExplorer();
    const [lazyMint, { data, isSuccess, error }] = useLazyMintTokenMutation();
    const [name, setName, nameValid, nameHelperText] = useValidation({ minLen: 2, maxLen: 50 });
    const [description, setDescription, descriptionValid, descriptionHelperText] = useValidation({
        minLen: 2,
        maxLen: 200
    });
    const [email, setEmail, emailValid, emailHelperText] = useValidation(
        { minLen: 2, maxLen: 500 },
        validateEmail
    );
    const [disabled, setDisabled] = useState(false);
    const [file, setFile] = useState();
    const [tx, setTx] = useState("");
    const [snackbar, setSnackbar] = useState(false);

    useEffect(() => {
        if (typeof onChanged === "function") onChanged({ name, description, email, file });
    }, [onChanged, name, description, email, file]);

    const handleFileSelect = () => {
        const input = document.createElement("input");
        input.hidden = "hidden";
        input.type = "file";
        input.onchange = (e) => setFile(e.target.files[0]);
        input.click();
    };

    const handleLazyMint = async () => {
        console.log("uploading image", {
            name,
            description,
            file,
            email
        });
        const form = new FormData();
        form.append("file", file, file.name);
        form.append("name", name);
        form.append("description", description);
        form.append("email", email);

        setDisabled(true);
        await lazyMint({ form });
        setDisabled(false);

        // Clear out form
        setName("");
        setEmail("");
        setDescription("");
        setFile();
    };

    useEffect(() => {
        if (isSuccess && data) {
            console.log("lazy mint success", data);
            setTx(data.transactionHash);
            dispatch(
                showSnackbarAlert({
                    message: "Success, Please check your emails for to claim your NFT",
                    severity: "success"
                })
            );
        }
    }, [dispatch, data, isSuccess]);

    useEffect(() => {
        if (error) {
            console.error(error);
            dispatch(
                showSnackbarAlert({
                    message: `Sorry, we are unable to mint tokens at the moment`,
                    severity: "error"
                })
            );
        }
    }, [dispatch, error]);

    return (
        <Cards.Curvy sx={{ position: "relative", ...sx }} {...props}>
            <CardContent>
                <Text.DashHeader>Create Lazy NFT</Text.DashHeader>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <TextField
                        label='Name'
                        placeholder='Enter NFT name'
                        disabled={disabled}
                        value={name}
                        color={!nameValid ? "error" : "success"}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <FormHelperText>
                        <Typography variant='p' color='error'>
                            {nameHelperText || ""}
                        </Typography>
                    </FormHelperText>

                    <TextField
                        label='Description'
                        variant='outlined'
                        placeholder='Enter description'
                        value={description}
                        disabled={disabled}
                        multiline
                        minRows={4}
                        maxRows={4}
                        color={!descriptionValid ? "error" : "success"}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <FormHelperText>
                        <Typography variant='p' color='error'>
                            {descriptionHelperText || ""}
                        </Typography>
                    </FormHelperText>
                    <FormGroup>
                        <TextField
                            type='email'
                            label='Email'
                            placeholder='Enter your email address'
                            disabled={disabled}
                            value={email}
                            color={!emailValid ? "error" : "success"}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormHelperText>
                            <Typography variant='p' color='error'>
                                {emailHelperText || ""}
                            </Typography>
                        </FormHelperText>
                    </FormGroup>
                    <Button
                        variant='outlined'
                        component='label'
                        disabled={disabled}
                        onClick={handleFileSelect}
                    >
                        {file ? file.name : "Upload media"}
                    </Button>
                    <Button
                        mt={1}
                        onClick={handleLazyMint}
                        disabled={disabled || !nameValid || !descriptionValid || !file}
                    >
                        Mint
                    </Button>
                </Stack>
                {disabled ? (
                    <Backdrop
                        sx={{
                            backgroundColor: "rgba(0,0,0,.2)",
                            position: "absolute",
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={disabled}
                    >
                        <CircularProgress color='primary' size='5rem' />
                    </Backdrop>
                ) : (
                    ""
                )}
            </CardContent>
            <Snackbar
                open={snackbar}
                autoHideDuration={25000}
                onClose={() => setSnackbar(false)}
                message='View last transaction'
                action={
                    <Button href={transactionHashToEtherscanAddress(tx)} target='_blank'>
                        View Transaction
                    </Button>
                }
            />
        </Cards.Curvy>
    );
}

// Very basic email validation
// regex would be better but client side validation isn't enough anyway.
// server will need to validate email by requiring email confirmation.
export const validateEmail = (v) => {
    if (!v.includes("@")) return "email doesn't look right - expected @";
    if (!v.includes(".")) return "email doesn't look right - expected at least one .";
    if (v.endsWith("@")) return "email doesn't look right - shouldn't end with @";
    if (v.endsWith(".")) return "email doesn't look right - shouldn't end with .";
    return true;
};

export default LazyMintForm;
