import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";

export function useErrorMessage({ error }) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (error) {
            console.log("error occured", error);
            let message;
            switch (error.status) {
                case "FETCH_ERROR":
                    message = "Sorry, we are having technically difficulties";
                    break;
                default:
                    message = error.error;
                    break;
            }
            if (error.data) {
                message = error.data.message;
            }
            dispatch(
                showSnackbarAlert({
                    message,
                    severity: "error"
                })
            );
        }
    }, [dispatch, error]);
}
