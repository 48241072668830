import React from "react";
import { Grid } from "@mui/material";

import { Text, Wrappers } from "@features/components";
import LoginForm from "@features/user/loginForm";
import RegisterForm from "@features/user/registerForm";
import { LOGIN, REGISTER } from "../../pages.constants";

const PAGE_TITLE = {
    [LOGIN]: () => "Login to account",
    [REGISTER]: () => "Create an account"
};

export const CustomCard = ({ url, classes }) => {
    return (
        <Wrappers.Container>
            <Grid
                container
                justifyContent='center'
                alignItems='center'
                alignSelf='center'
                sx={{ minHeight: "inherit", height: "calc(100vh - 92px)" }}
            >
                <Grid item xs={12} md={6}>
                    {url && <Text.Typography variant='h2'>{PAGE_TITLE[url]()}</Text.Typography>}
                    {url === LOGIN ? <LoginForm /> : <RegisterForm />}
                </Grid>
            </Grid>
        </Wrappers.Container>
    );
};
