import { useEffect, useState } from "react";

// Basic Form Input validation
function useValidation({ minLen = 5, maxLen = 50, defaultValue = "" }, validate) {
    const [value, onValueChanged] = useState(defaultValue);
    const [error, setError] = useState();
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        setValidated(false);
        if (!value) return;
        // if (!value.includes('@')){
        //     setError(`Must have @ character`)
        //     return;
        // }
        if (value.length < minLen) {
            setError(`Must be ${minLen} characters or more.`);
        } else if (value.length > maxLen) {
            setError(`Must be ${maxLen} characters or less.`);
        } else if (typeof validate === "function") {
            try {
                const valid = validate(value, { minLen, maxLen });
                if (typeof valid === "string") {
                    setError(valid);
                } else {
                    setValidated(true);
                    setError(null);
                }
            } catch (e) {
                return setError(e.message);
            }
        } else {
            setValidated(true);
            setError(null);
        }
    }, [value, maxLen, minLen, validate]);

    return [value, onValueChanged, validated, error];
}

export default useValidation;
