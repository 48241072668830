import React, { useCallback } from "react";
import { Stack, Box } from "@mui/material";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";

import { FormInputs } from "@features/components";
import { withStyles } from "@mui/styles";

import { styles } from "./checkout.styles";

const cardElementsOptions = {
    style: {
        base: {
            color: "#222"
        }
    }
};

const CardField = ({ classes, cardError, control, errors }) => {
    const showHelperText = useCallback(
        (keyWord, helperText) => {
            if (cardError?.code?.includes(keyWord)) {
                return helperText;
            }
        },
        [cardError]
    );

    return (
        <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            spacing={{ xs: 1.8, sm: 0.2, md: 1 }}
            mb={4}
        >
            <Box className={classes.cardElementWrapper}>
                <FormInputs.InputField
                    label='Card number'
                    onlyLabel={true}
                    helperText={showHelperText("number", "Card number is incomplete")}
                >
                    <CardNumberElement options={cardElementsOptions} />
                </FormInputs.InputField>
            </Box>
            <Box className={classes.cardElementWrapper}>
                <FormInputs.InputField
                    label='Expiration data'
                    onlyLabel={true}
                    helperText={showHelperText("expiry", "Expiration data is incomplete")}
                >
                    <CardExpiryElement options={cardElementsOptions} />
                </FormInputs.InputField>
            </Box>
            <Box className={classes.cardElementWrapper}>
                <FormInputs.InputField
                    label='Security code'
                    onlyLabel={true}
                    helperText={showHelperText("cvc", "Security code is incomplete")}
                >
                    <CardCvcElement options={cardElementsOptions} />
                </FormInputs.InputField>
            </Box>
        </Stack>
    );
};

export default withStyles(styles)(CardField);
