import React, { useEffect, useMemo, useState } from "react";
import {
    Typography,
    CardContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Tooltip
} from "@mui/material";
import Web3 from "web3";
import LazyNFTCard from "../fvtoken/lazyNFTCard";
import useNetworkExplorer from "../web3/hooks/useNetworkExplorer";
import { useFVMarketplace } from ".";
import { NFTCardDetailDialog } from "../fvtoken/NFTCard";

function NFTMarketListingCard({
    NFTData,
    listingDetails: { listingId, lmluid, seller, price, isOwner, listingAddedTransactionHash },
    actions,
    children,
    onClick,
    ...props
}) {
    const { hasContract, getRoyaltyInfo } = useFVMarketplace();
    const [royaltyInfo, setRoyaltyInfo] = useState();

    useEffect(() => {
        if (NFTData.type !== "Lazy" && hasContract) {
            getRoyaltyInfo(listingId)
                .then((royaltyInfo) => {
                    console.log("got royalty info", { royaltyInfo });
                    const royaltyHolders = royaltyInfo.map(([address, total]) => ({
                        address,
                        total
                    }));
                    return {
                        royaltyHolders,
                        total: () =>
                            royaltyHolders.reduce(
                                (a, c) => a.add(Web3.utils.toBN(c.total)),
                                Web3.utils.toBN(0)
                            )
                    };
                })
                .then(setRoyaltyInfo);
        }
    }, [NFTData, hasContract, listingId, getRoyaltyInfo]);

    const bubbleShowDetails = (e, args) => {
        if (typeof onClick === "function") {
            const mergedDetails = {
                ...args,
                listingId,
                lmluid,
                seller,
                price,
                royaltyInfo,
                isOwner,
                listingAddedTransactionHash
            };
            onClick(e, mergedDetails);
        }
    };

    const proxyActions = (args) =>
        actions({
            ...args,
            listingId,
            lmluid,
            seller,
            price,
            royaltyInfo,
            isOwner,
            listingAddedTransactionHash
        });

    const formattedPrice = useMemo(() => Web3.utils.fromWei(price, "ether") || price, [price]);

    return (
        <LazyNFTCard
            hideIndicator
            NFTData={NFTData}
            {...props}
            clickable
            actions={proxyActions}
            onClick={bubbleShowDetails}
        >
            <CardContent>{children}</CardContent>
            {price ? (
                <Typography variant='body2' color='text.secondary' sx={{ marginTop: "auto" }}>
                    Price: {formattedPrice}
                </Typography>
            ) : (
                ""
            )}
        </LazyNFTCard>
    );
}

export function NFTMarketListingDetailDialog({
    seller,
    price,
    royaltyInfo,
    listingAddedTransactionHash,
    ...props
}) {
    const { transactionHashToEtherscanAddress } = useNetworkExplorer();

    return (
        <NFTCardDetailDialog
            {...props}
            actions={
                listingAddedTransactionHash ? (
                    <Tooltip
                        placement='top'
                        title='This transaction shows that the NFT was successfully listed on the blockchain. In our marketplace, smart contract.'
                    >
                        <Button
                            href={transactionHashToEtherscanAddress(listingAddedTransactionHash)}
                            variant='contained'
                            target='_blank'
                        >
                            View Listing Transaction
                        </Button>
                    </Tooltip>
                ) : (
                    ""
                )
            }
        >
            {royaltyInfo ? (
                <TableContainer component='div'>
                    <Table size='small' aria-label='Royalty information'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left' colSpan={3}>
                                    Price breakdown
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {royaltyInfo.royaltyHolders.map(({ address, total }, i) => (
                                <TableRow key={`royalty_holder_${i}`}>
                                    <TableCell>{address}</TableCell>
                                    <TableCell>Royalty Holder</TableCell>
                                    <TableCell>{Web3.utils.fromWei(total, "ether")}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>{seller}</TableCell>
                                <TableCell>Seller</TableCell>
                                <TableCell>
                                    {Web3.utils.fromWei(
                                        Web3.utils
                                            .toBN(price)
                                            .sub(Web3.utils.toBN(royaltyInfo.total()))
                                            .toString(),
                                        "ether"
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <Typography component='caption'>
                            Total Price: {Web3.utils.fromWei(price, "ether")}
                        </Typography>
                    </Table>
                </TableContainer>
            ) : (
                ""
            )}
        </NFTCardDetailDialog>
    );
}

export default NFTMarketListingCard;
