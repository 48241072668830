import { Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useCallback, useEffect } from "react";
import { Text, Button } from "@features/components";
import { ReactComponent as EbayLogo } from "../../assets/ebay-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setEbayState } from "@features/user/user.slice";
import config from "@app/config";

const styles = (theme) => ({
    connectEbayButton: {
        width: "fit-content",
        height: "fit-content",
        padding: "12px 24px",

        "& div": {
            alignItems: "center"
        }
    },
    background: {
        background: theme.palette.text.grey
    }
});

function ConnectEbayButton(props) {
    const { color, classes } = props;
    const userToken = useSelector((state) => state.user.token);

    const integrateEbay = useCallback(() => {
        const EBAY_INTEGRATION_CALLBACK = `${document.location.origin}/my-account/dashboard`;
        window.location.href = `${config.services.forms.baseUrl}ebay/oauth2?token=${userToken}&callback=${EBAY_INTEGRATION_CALLBACK}`;
    }, [userToken]);

    return (
        <Button
            variant='text'
            color={color}
            classes={{ root: `${classes.connectEbayButton} ${!color ? classes.background : ""}` }}
            onClick={integrateEbay}>
            <Stack direction='row' spacing={1}>
                <EbayLogo />
            </Stack>
        </Button>
    );
}

export default withStyles(styles)(ConnectEbayButton);
