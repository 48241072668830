import { useEffect, useState } from "react";
import { useListingAddedEvents, useListingRemovedEvents } from "..";
import { useGetListingsQuery } from "@app/services";
import { useAuthentication } from "../../user";
import { useWeb3 } from "../../web3";

// A hook that gets all listings in the market place and automatically
// refreshes listing when ListingAdded or ListingRemoved events are fired.
//
function useListings() {
    const { account } = useWeb3();
    const { details } = useAuthentication();
    const [listings, setListings] = useState([]);
    const ListingAddedEvents = useListingAddedEvents();
    const ListingRemovedEvents = useListingRemovedEvents();
    const { data: lazyListings } = useGetListingsQuery(null);

    useEffect(() => {
        const contractListings = ListingAddedEvents
            // Map to get values we want (tokenId, tokenURI).
            .map(({ returnValues, transactionHash }) => ({
                ...returnValues,
                listingAddedTransactionHash: transactionHash
            }))
            .map((listing) => {
                const match = ListingRemovedEvents.find(
                    ({ returnValues }) => returnValues.listingId === listing.listingId
                );
                if (match) {
                    return match.returnValues;
                }
                return listing;
            })
            // Filter out listings of tokens that have been burned/removed.
            .filter(({ tokenId }) => tokenId !== undefined)
            // Fetch tokens metadata.
            .map(({ listingId, tokenId, seller, price, ...rest }) => {
                const match = (lazyListings || []).find(
                    ({ tokenId: listedTokenId }) => listedTokenId === tokenId
                );
                const isOwner = account
                    ? seller.toLowerCase() === account
                    : details && match
                    ? details.cuid === match.seller
                    : false;
                return {
                    ...rest,
                    ...match,
                    listingId,
                    tokenId,
                    seller,
                    price,
                    isOwner
                };
            });

        setListings([
            ...contractListings,
            // Show lazy (off-chain) marketplace listings.
            ...(lazyListings && lazyListings.length
                ? lazyListings
                      .filter(({ type }) => type === "LAZY")
                      .map(({ lmluid, tokenId, seller, price, ...rest }) => {
                          const isOwner = details && details.cuid ? seller === details.cuid : false;

                          return {
                              ...rest,
                              lmluid,
                              tokenId,
                              seller,
                              price,
                              isOwner,
                              type: "Lazy"
                          };
                      })
                : [])
        ]);

        return () => setListings([]);
    }, [account, details, ListingRemovedEvents, ListingAddedEvents, lazyListings]);

    return listings;
}

export default useListings;
