import React, { useId, useState } from "react";
import {
    CardActions,
    CardContent,
    FormControl,
    FormHelperText,
    Grid,
    OutlinedInput,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    IconButton,
    Box,
    Link
} from "@mui/material";
import useValidation from "@features/hooks/useValidation";
import { useAuthentication } from ".";
import { useNavigate } from "react-router-dom";
import { Wrappers, Text, FormInputs, Button, Cards } from "@features/components";
import { withStyles } from "@mui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { RESET_PASSWORD } from "@app/pages.constants";

const styles = {
    inputRoot: {
        backgroundColor: "white"
    }
};
const LoginForm = ({ classes, ...props }) => {
    const navigate = useNavigate();
    const { login } = useAuthentication();
    const [email, setEmail, emailValidated, emailErr] = useValidation({ minLen: 6, maxLen: 500 });
    const [password, setPassword, passwordValidated, passwordErr] = useValidation({
        minLen: 12,
        maxLen: 32
    });

    const handleLogin = () => {
        console.log("login:", email);
        login(email, password);
    };

    const switchToForgotPassword = () => {
        navigate('/forgot-password')
    }

    return (
        <>
            <Box>
                <FormInputs.InputField
                    id='email_inpt'
                    mt={2.4}
                    name='email'
                    label='Email'
                    error={!!emailErr}
                    fullWidth
                    helperText={emailErr}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <PasswordField
                    name='password'
                    autoComplete='current-password'
                    label='Password'
                    fullWidth
                    mt={2.4}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    helperText={passwordErr}
                />
            </Box>
            <Box mt={1}>
                <Link style={{cursor: "pointer"}} onClick={switchToForgotPassword}>Forgot your password?</Link>
            </Box>

            <FormControlLabel
                sx={{ marginBottom: "32px", marginTop: "32px" }}
                control={<Checkbox />}
                label={<Text.Typography variant='h5'>Keep me signed in</Text.Typography>}
            />
            <Button
                id='login_btn'
                fullWidth
                variant='contained'
                color='primary'
                size='large'
                disabled={!emailValidated || !passwordValidated}
                onClick={handleLogin}>
                Sign In
            </Button>
        </>
    );
};

export const PasswordField = ({ name, password, onChange, helperText, label, ...props }) => {
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormInputs.InputField
            {...props}
            id={id}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={onChange}
            label={label || "Password"}
            endAdornment={
                <InputAdornment position='end'>
                    <IconButton
                        aria-label='Toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};

export default withStyles(styles)(LoginForm);
