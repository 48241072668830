import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    input: {
        width: "100%",
        maxWidth: "824px",
        height: "40px",
        border: "none",
        borderRadius: "8px",
        paddingLeft: theme.spacing(3),
        background: theme.palette.text.grey
    },
    textLink: {
        color: theme.palette.text.darkGrey,
        margin: `0 min(${theme.spacing(14)}, 5vw)`,
        fontWeight: 400,
        textDecoration: "none",
        borderBottom: "1px solid transparent",
        cursor: "pointer",

        "&:hover": {
            borderColor: theme.palette.text.darkGrey
        }
    },
    iconLink: {
        justifyContent: "center",
        alignItems: "center",
        minWidth: "41px",

        "& svg": {
            width: "27px"
        }
    },
    searchButton: {
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        marginLeft: "-40px",
        borderRadius: "0 8px 8px 0",
        background: theme.palette.text.darkGrey,

        "&:hover": {
            //remove built-in hover effect
            background: theme.palette.text.darkGrey
        },

        "& svg": {
            color: theme.palette.text.white,
            width: "23px",

            "& path": {
                fill: theme.palette.text.white
            }
        },

        [theme.breakpoints.down("md")]: {
            background: "transparent",
            borderRadius: "0",
            width: "auto",
            minWidth: "40px",

            "& svg path": {
                fill: `${theme.palette.text.black} !important`
            },

            "&:hover": {
                background: theme.palette.text.white
            }
        }
    },
    withoutTransition: {
        transition: "0s !important"
    },
    connectEbayButton: {
        width: 'fit-content',
        height: 'fit-content',
        background: theme.palette.text.grey,
        padding: "12px 24px"
    }
}));
