import React from "react";
import { Box, FormControlLabel, Checkbox, Link } from "@mui/material";
import { Wrappers, Text, FormInputs, Button } from "@features/components";
import { useRestoreStateOnAuthenticatedOrRedirect } from "../router";
import { withStyles } from "@mui/styles";
import { MY_PROFILE, PROFILE_ORDERS } from "../pages.constants";

const styles = (theme) => ({
    container: {
        maxWidth: "592px",
        marginBottom: "120px"
    }
});

const RegisterBuyerPage = ({ classes }) => {
    useRestoreStateOnAuthenticatedOrRedirect(`${MY_PROFILE}/${PROFILE_ORDERS}`);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            <Text.Typography variant='h2' mt={7}>
                Create an account
            </Text.Typography>

            <Box>
                <FormInputs.InputField name='name' label='Your name' fullWidth mt={2.4} />
                <FormInputs.InputField name='name' label='Email' fullWidth mt={2.4} />
                <FormInputs.InputField name='name' label='Username' fullWidth mt={2.4} />
                <FormInputs.InputField name='name' label='Password' fullWidth mt={2.4} />
                <Text.Typography variant='h5' color='gray' mt={1.6}>
                    Minimum of 12 characters
                </Text.Typography>
                <Text.Typography variant='h5' color='gray' mt={0.4} mb={3.2}>
                    Uppercase, lowercase letters and one number
                </Text.Typography>

                <FormControlLabel
                    sx={{ marginBottom: "32px" }}
                    control={<Checkbox />}
                    label={
                        <Text.Typography variant='h5'>
                            I agree to unlimitid’s <Link href='/'>Terms of Use</Link> and{" "}
                            <Link href='/'>Terms of Purchase</Link>.
                        </Text.Typography>
                    }
                />
            </Box>
            <Button
                fullWidth
                //onClick={() => navigate(CHECK_OUT)}
                variant='contained'
                color='primary'
                size='large'
            >
                Create your account
            </Button>
        </Wrappers.Container>
    );
};

export default withStyles(styles)(RegisterBuyerPage);
