import { Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useCallback, useEffect } from "react";
import { Text, Button } from "@features/components";
import { ReactComponent as EbayLogo } from "../../assets/ebay-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setEbayState } from "@features/user/user.slice";
import config from "@app/config";

const styles = (theme) => ({
    disconnectEbayButton: {
        width: "fit-content",
        height: "fit-content",
        padding: "12px 24px",
        border: '1px solid red'
    },
});

function DisconnectEbayButton(props) {
    const { classes } = props;
    const userToken = useSelector((state) => state.user.token);

    const integrateEbay = useCallback(() => {
        fetch(`${config.services.forms.baseUrl}ebay/oauth2?token=${userToken}`, {method: "DELETE"}).finally(() => {
            location.reload()
        })
    }, [userToken]);

    return (
        <Button
            color='error'
            classes={{ root: classes.disconnectEbayButton }}
            onClick={integrateEbay}>
            <Stack direction='row' spacing={1}>
                <EbayLogo />
            </Stack>
        </Button>
    );
}

export default withStyles(styles)(DisconnectEbayButton);
