import firstStepImage from "../../../../assets/landing-steps/first-step-image.png";
import secondStepImage from "../../../../assets/landing-steps/second-step-image.png";
import thirdStepImage from "../../../../assets/landing-steps/third-step-image.png";

export const stepsData = [
    {
        title: "Forward your purchase emails",
        description: (
            <>
                Simply forward any purchase emails you have to unlimitid@io.parsio.io
                <br />
                <br />
                We’ll use AI to extract all the relevant purchase information.
            </>
        ),
        image: firstStepImage,
        step: "1"
    },
    {
        title: "Keep a record of everything you own",
        description: (
            <>
                You will now have a profile where you can conveniently access and review all your
                purchases in one centralised location.
                <br />
                <br />
                We make it simple for you to keep track of what you own and identify items you would
                like to resell.
            </>
        ),
        image: secondStepImage,
        step: "2"
    },
    {
        title: "Chill while unlimitid does the selling for you",
        description: (
            <>
                Unlimitid seamlessly lists your items on top resale platforms, instantly - no hassle, 
                no engagement with the resale process needed.
                <br />
                <br />
                Simply relax, watch your earnings grow, and ship the item to the buyer's address we 
                provide
            </>
        ),
        image: thirdStepImage,
        step: "3"
    }
];
