export const styles = (theme) => ({
    box: {
        background: "#111111",
        display: "grid",
        placeItems: "center",
        color: "white",
        width: "27px",
        borderRadius: "4px",
        fontWeight: "600",

        [theme.breakpoints.down("md")]: {
            fontSize: "6px",
            fontWeight: 300,
            width: "13px",
            height: "13px"
        }
    },

    textBox: {
        overflow: "hidden",
        borderTop: "1px black solid",
        padding: "18px 24px",

        [theme.breakpoints.down("md")]: {
            padding: "8px",
            borderTop: "0.5px black solid"
        }
    },
    textWrap: {
        maxWidth: "75%",
        display: "flex",

        [theme.breakpoints.down("md")]: {
            display: "-webkit-box",
            whiteSpace: "normal",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical"
        }
    },
    text: {
        maxWidth: "fit-content",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    titleText: {
        fontWeight: 600,

        [theme.breakpoints.down("md")]: {
            maxWidth: "85%"
        }
    },
    descriptionText: {
        [theme.breakpoints.down("md")]: {
            maxWidth: "85%"
        }
    },
    iconBox: {
        [theme.breakpoints.down("md")]: {
            display: "flex",
            justifyContent: " end"
        }
    }
});
