import React, { useEffect, useState } from "react";
import { Box, Stack, Grid } from "@mui/material";
import { Cards, Text } from "../index";
import Photo from "./img/user.svg";
import LocationIcon from "./img/location.svg";
import { Link, useLocation } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { EDIT_PROFILE } from "@app/pages.constants";

const styles = {
    link: {
        color: "#111"
    }
};

const statusList = [
    {
        value: "owned",
        quantity: "23"
    },
    {
        value: "sold",
        quantity: "239"
    },
    {
        value: "for sale",
        quantity: "192"
    }
];

function UserCard({ classes }) {
    const [showEditLinks, setShowEditLinks] = useState(true);
    const location = useLocation();

    useEffect(() => {
        let currentUrl = location.pathname.split("/");
        currentUrl = currentUrl[currentUrl.length - 1];

        if (currentUrl === EDIT_PROFILE) {
            setShowEditLinks(false);
        } else {
            setShowEditLinks(true);
        }
    }, [location.pathname]);

    return (
        <Cards.Curvy
            sx={{
                borderRadius: "0 !important",
                padding: {
                    xs: "32px 16px !important",
                    sm: "40px"
                },
                width: "100%"
            }}
        >
            <Grid container alignItems='space-between' justifyContent='space-between'>
                <Grid item xs={12} lg={6} sm={5}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 0, sm: 2 }}>
                        <Box
                            sx={{
                                display: {
                                    xs: "none",
                                    sm: "block"
                                }
                            }}
                        >
                            <img src={Photo} alt='userPhoto' width={"150px"} />
                        </Box>
                        <Stack justifyContent='space-between'>
                            <Text.Typography
                                variant='h5'
                                sx={{
                                    display: {
                                        xs: "flex",
                                        sm: "block"
                                    },
                                    justifyContent: "space-between"
                                }}
                            >
                                <Link className={classes.link}>View profile</Link>
                                {showEditLinks && (
                                    <Box
                                        sx={{
                                            display: {
                                                xs: "block",
                                                sm: "none"
                                            }
                                        }}
                                    >
                                        <Link
                                            className={classes.link}
                                            to={`/my-account/${EDIT_PROFILE}`}
                                        >
                                            Edit details
                                        </Link>
                                    </Box>
                                )}
                            </Text.Typography>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Text.Typography variant='h2' mt={{ xs: 1, sm: 0 }}>
                                    James Newman
                                </Text.Typography>
                                {showEditLinks && (
                                    <Text.Typography
                                        variant='h5'
                                        sx={{
                                            display: {
                                                xs: "none",
                                                sm: "block"
                                            }
                                        }}
                                    >
                                        <Link className={classes.link}>Edit</Link>
                                    </Text.Typography>
                                )}
                            </Stack>
                            <Stack
                                sx={{
                                    display: {
                                        xs: "flex",
                                        sm: "none"
                                    },
                                    flexDirection: "row",
                                    my: 3
                                }}
                            >
                                <img src={Photo} alt='userPhoto' width={"80px"} />
                                <StatusList statusList={statusList} />
                            </Stack>
                            <Box sx={{ position: "relative" }}>
                                <Text.Typography
                                    variant='h5'
                                    mb={{ xs: 3, sm: 0 }}
                                    sx={{ textDecoration: "underline" }}
                                >
                                    Add a profile description. Describe yourself in one sentence.
                                </Text.Typography>
                            </Box>
                            <Stack direction='row' spacing={2}>
                                <img src={LocationIcon} alt='userPhoto' width={"14px"} />
                                <Text.Typography variant='h5'>No location set</Text.Typography>
                                {showEditLinks && (
                                    <Text.Typography
                                        variant='h5'
                                        sx={{
                                            display: {
                                                xs: "none",
                                                sm: "block"
                                            }
                                        }}
                                    >
                                        <Link className={classes.link}>Edit</Link>
                                    </Text.Typography>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={5}
                    sm={5}
                    p={1.5}
                    sx={{
                        display: {
                            xs: "none",
                            sm: "block"
                        }
                    }}
                >
                    <StatusList statusList={statusList} />
                </Grid>
            </Grid>
        </Cards.Curvy>
    );
}

const StatusList = ({ statusList }) => {
    return (
        <Stack direction='row' mt={1} mb={1} ml={{ xs: 5, sm: 0 }} spacing={{ xs: 1, sm: 2 }}>
            {statusList.map(({ value, quantity }) => {
                return (
                    <Stack
                        alignItems='center'
                        key={value}
                        sx={{
                            width: {
                                xs: "64px",
                                sm: "88px"
                            },
                            padding: "15px 0",
                            background: "white",
                            borderRadius: "8px",
                            border: "1px solid #DDDDDD"
                        }}
                    >
                        <Text.Typography variant='h4'>{quantity}</Text.Typography>
                        <Text.Typography variant='h5' style={{ textTransform: "capitalize" }}>
                            {value}
                        </Text.Typography>
                    </Stack>
                );
            })}
        </Stack>
    );
};

export default withStyles(styles)(UserCard);
