import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { withStyles } from "@mui/styles";

import LazyNFTCard from "@features/fvtoken/lazyNFTCard";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { useGetLazyTokenQuery } from "@app/services";
import LoginForm from "@features/user/loginForm";
import RegisterForm from "@features/user/registerForm";
import ClaimForm from "../verifiers/claimForm";
import { LOGIN, REGISTER, CLAIM_NFT, HOME_URL } from "../../pages.constants";
import { Text, Wrappers } from "@features/components";
import { styles } from "./styles";

const PAGE_CONTENT = {
    [LOGIN]: () => <LoginForm />,
    [REGISTER]: () => <RegisterForm />,
    [CLAIM_NFT]: (tokenData, blockedStatus, setStatus) => (
        <ClaimForm
            NFTData={{ tokenData, type: "lazy" }}
            setStatus={setStatus}
            blockedStatus={blockedStatus}
        />
    )
};

const PAGE_TITLE = {
    [LOGIN]: () => "Sign in to claim your digital card",
    [REGISTER]: () => "Register to claim your digital card",
    [CLAIM_NFT]: () => ""
};

export const UserCard = withStyles(styles)(({ lmuid, url, classes }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const { data, error, isLoading, isFetching } = useGetLazyTokenQuery({ lmuid });
    const loadingStatus = isLoading || isFetching;
    const [blockedStatus, setStatus] = useState(true);
    useEffect(() => {
        if (error && lmuid) {
            console.log("error while fetching lazy token data", error);
            dispatch(
                showSnackbarAlert({
                    message: "Failed to get token information",
                    severity: "error"
                })
            );
            navigate(HOME_URL);
            return;
        }
        if (data) {
            if (data.ipfs === "") {
                console.log("unable to find token with lmuid", lmuid);
                dispatch(
                    showSnackbarAlert({
                        message: "We are unable to find the requested token",
                        severity: "error"
                    })
                );
                navigate(HOME_URL);
                return;
            }
        }
    }, [lmuid, loadingStatus]);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                alignSelf='center'
                sx={{
                    minHeight: "inherit",
                    height: {
                        xs: "auto",
                        md: "calc(100vh - 92px)"
                    }
                }}
            >
                {url && !isDesktop && (
                    <Text.Typography variant='h2'>{PAGE_TITLE[url]()}</Text.Typography>
                )}
                <Grid item xs={12} lg={6} md={6} classes={{ root: classes.gridGallery }}>
                    {data && (
                        <LazyNFTCard
                            loading={loadingStatus}
                            className={classes.mobileCard}
                            blocked={blockedStatus}
                            onClick={() => navigate(`/card?lmuid=${lmuid}`)}
                            NFTData={data.metadata}
                            sx={{ margin: "0 auto" }}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={5}
                    md={5}
                    sx={{
                        mb: {
                            xs: 8,
                            md: 0
                        }
                    }}
                >
                    {url && isDesktop && (
                        <Text.Typography variant='h2'>{PAGE_TITLE[url]()}</Text.Typography>
                    )}
                    {data && PAGE_CONTENT[url](data, blockedStatus, setStatus)}
                </Grid>
            </Grid>
        </Wrappers.Container>
    );
});
