import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pagination: {
        resultsPerPage: 20
    },
    inactivity: {
        timeoutInSeconds: 60 * 5, // How long inactive before an action is taken
        showTimeoutDialogWhenTimeoutReached: true, // If true show a prompt and countdown before logout.
        timeoutDialogAdditionalTimeInSeconds: 60 // Time until automatic logout, after the timeout dialog has been shown.
    },
    snackbars: {
        showAlertTimeInSeconds: 20 // How long to show snackbar alerts.
    }
};

export const preferencesSlice = createSlice({
    name: "preferences",
    initialState,
    reducers: {
        setResultsPerPage: (state, { payload: { resultsPerPage } }) => {
            state.pagination.resultsPerPage = resultsPerPage;
        },
        setInactivityTimeout: (state, { payload: { seconds } }) => {
            state.inactivity.timeoutInSeconds = seconds;
        }
    }
});

export const { setResultsPerPage } = preferencesSlice.actions;

export default preferencesSlice.reducer;
