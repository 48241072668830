import { Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Typography from "@features/components/text/typography";

const styles = (theme) => ({
    wrapper: {
        width: "100%",

        [theme.breakpoints.down("md")]: {
            padding: "0 24px"
        }
    },
    titleBox: {
        borderBottom: "1px solid #DDD",
        paddingBottom: "8px",
        margin: "48px 0 32px 0"
    }
});

function FormWrapper(props) {
    const { children, title, classes } = props;

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.titleBox}>
                <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    {title}
                </Typography>
            </Box>
            {children}
        </Box>
    );
}

export default withStyles(styles)(FormWrapper);
