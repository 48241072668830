export const styles = (theme) => ({
    descriptionRow: {
        verticalAlign: "top",
        "& td:first-child": {
            width: "200px"
        },
        "& td:nth-child(2)": {
            minWidth: "200px",
        },
        "& td:nth-child(2) > p > div": {
            display: "-webkit-box",
            "-webkit-line-clamp": 3,
            "-webkit-box-orient": "vertical",
            whiteSpace: "pre-wrap !important",
        },
        "& td:nth-child(3)": {
          maxWidth: "50px",
            padding: "0 !important"
        },
        "& td:nth-child(3) > div": {
            paddingLeft: "0 !important",
            paddingTop: "8px"
        },

        [theme.breakpoints.down("md")]: {
            "& td:first-child": {
                paddingLeft: "24px"
            },
            "& td:nth-child(3)": {
                paddingRight: "24px !important"
            }
        },
    },
    descriptionCell: {
        borderBottom: "none"
    },
    editableRow: {
        "& td:first-child": {
            width: "200px"
        },
        "& td:nth-child(2) > p": {
            whiteSpace: "nowrap",
        },
        "& td:nth-child(2)": {
            maxWidth: "200px",
            minWidth: "200px",
        },
        "& td:nth-child(3)": {
            maxWidth: "50px",
            padding: "0 !important",
            textAlign: "right"
        },
        "& td:nth-child(3) > div": {
            paddingLeft: "0 !important",
            paddingTop: "8px"
        },

        [theme.breakpoints.down("md")]: {
            "& td:first-child": {
                paddingLeft: "24px"
            },
            "& td:nth-child(3)": {
                paddingRight: "24px !important"
            }
        },
    }
});
