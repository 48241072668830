import React from "react";
import InactivityTimeoutDialog from "./popups/inactivityTimeoutDialog";
import SnackbarAlerts from "./popups/snackbar";

function NotificationCenter() {
    return (
        <React.Fragment>
            <SnackbarAlerts />
            <InactivityTimeoutDialog />
        </React.Fragment>
    );
}

export default NotificationCenter;
