import { Box, CardContent, Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Cards, Text, Wrappers } from "@features/components";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { PasswordField } from "@features/user/loginForm";
import { passwordMatchValidation } from "@features/user/registerForm";
import { useResetPasswordMutation } from "@app/services";
import useValidation from "@features/hooks/useValidation";
import MainNavigation from "../../navigations";
import { withStyles } from "@mui/styles";
import Typography from "@features/components/text/typography";

export const styles = () => ({
    input: {
        width: "100%"
    }
});

function PasswordReset({ jwtToken, classes }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const token = useMemo(
        () => new URLSearchParams(search).get("token") || jwtToken,
        [search, jwtToken]
    );
    const [password, setPassword, passwordValidated, passwordErr] = useValidation({
        minLen: 7,
        maxLen: 50
    });
    const [confirmPassword, setConfirmPassword, confirmPasswordValidated, confirmPasswordErr] =
        useValidation({ minLen: 7, maxLen: 50 }, (v) => passwordMatchValidation(v, password));

    const [resetPassword, { isSuccess, error }] = useResetPasswordMutation();

    if (!token) {
        dispatch(
            showSnackbarAlert({
                message: "Password reset is not permitted",
                severity: "error"
            })
        );
        navigate("/login");
    }

    const handleChangePassword = () => {
        resetPassword({ password, token });
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(
                showSnackbarAlert({
                    message: "Password was changed",
                    severity: "success"
                })
            );
            navigate("/sign-in");
        }
    }, [dispatch, navigate, isSuccess]);

    useEffect(() => {
        if (error) {
            console.log("error occured during password reset", error);
            let message;
            switch (error.status) {
                case "FETCH_ERROR":
                    message = "Sorry, we are having technically difficulties";
                    break;
                default:
                    message = error.error;
                    break;
            }
            if (error.data) {
                message = error.data.message;
            }
            dispatch(
                showSnackbarAlert({
                    message,
                    severity: "error"
                })
            );
        }
    }, [error, dispatch]);

    return (
        <Wrappers.Container isFullHeight>
            <Box width='100%'>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ minHeight: "60vh" }}>
                    <Grid item xs={12} md={6}>
                        <Box width='100%'>
                            <Box>
                                <Typography variant="h3">Enter a new password</Typography>
                                <Stack spacing={3} mt={2}>
                                    <PasswordField
                                        autoComplete='new-password'
                                        name='password'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        helperText={passwordErr}
                                        classes={{
                                            root: classes.input
                                        }}
                                    />
                                    <PasswordField
                                        autoComplete='confirm-password'
                                        name='confirm-password'
                                        label='Confirm password'
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        helperText={confirmPasswordErr}
                                        classes={{
                                            root: classes.input
                                        }}
                                    />
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        size='large'
                                        disabled={!passwordValidated || !confirmPasswordValidated}
                                        onClick={handleChangePassword}>
                                        Change password
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Wrappers.Container>
    );
}

export default withStyles(styles)(PasswordReset);
