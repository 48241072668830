export const styles = {
    input: {
        width: "100%",
        height: "61px",
        marginTop: "4px",

        "& .MuiFormHelperText-root": {
            color: "#d32f2f !important"
        }
    }
};
