import React from "react";
import { Stack, Box, CardMedia, Card } from "@mui/material";
import { Text } from "../";
import { withStyles } from "@mui/styles";
import { ReactComponent as Trash } from "@app/pages/cart/trash.svg";

const styles = (theme) => ({
    root: {
        background: "inherit",
        padding: "24px 0",
        boxShadow: "none",
        minWidth: "fit-content"
    },
    cardMedia: {
        background: "white",
        borderRadius: "8px",
        cursor: "pointer",
        border: "1px solid #DDDDDD",
        // height: "192px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "64px",
            height: "64px"
        }
    }
});

function Product({ children, classes, small, name, description, price, imagePath, ...rest }) {
    return (
        <Card classes={{ root: classes.root }} {...rest}>
            <Stack direction='row' spacing={{ xs: 2, sm: 3, md: 4 }}>
                <Box
                    className={classes.cardMedia}
                    sx={{ width: small ? "79px" : "192px", height: small ? "79px" : "192px" }}
                >
                    <CardMedia
                        component='img'
                        style={{ borderRadius: "8px" }}
                        image={imagePath}
                        alt='product'
                    />
                </Box>

                <Box sx={{ flex: "1" }}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Text.Typography variant={small ? "h5" : "h4"}>{name}</Text.Typography>
                        {!small && (
                            <Text.Typography variant='h4' color='main'>
                                {price}
                            </Text.Typography>
                        )}
                    </Stack>

                    <Text.Typography variant={small ? "h5" : "body2"} color='gray'>
                        Men’s/Sneakers/Black
                    </Text.Typography>

                    {small && (
                        <Text.Typography variant='h5' color='gray'>
                            {price}
                        </Text.Typography>
                    )}

                    {!small && (
                        <Stack
                            direction={{ xs: "row", sm: "column" }}
                            justifyContent={{ xs: "space-between" }}
                        >
                            <Text.Typography variant='body2' color='gray'>
                                Size: M
                            </Text.Typography>
                            <Box mt={{ xs: 0, sm: 3, md: 3 }}>
                                <Trash style={{ cursor: "pointer" }} />
                            </Box>
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Card>
    );
}

export default withStyles(styles)(Product);
