import cardAPI from "../../cardAPI";

export const cardService = cardAPI.injectEndpoints({
    endpoints: (build) => ({
        updateCard: build.mutation({
            query: (body) => ({
                method: "post",
                headers: {
                    userId: body.userId
                },
                url: `/item/${body.cardId}/update`,
                body: body.data
            })
        }),
        updateCardProperties: build.mutation({
            query: (body) => ({
                method: "post",
                headers: {
                    userId: body.userId
                },
                url: `/item/${body.cardId}/update-parsed-information`,
                body: body.data
            })
        }),
        createCard: build.mutation({
            query: (body) => ({
                method: "post",
                url: `/item/create`,
                body
            })
        }),
        overrideExisting: true
    })
});

export const { useUpdateCardMutation, useCreateCardMutation, useUpdateCardPropertiesMutation} = cardService;
