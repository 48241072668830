import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store";
import { createTheme, ThemeProvider } from "@mui/material";
import { AuthenticationContextProvider } from "@features/user";
import { AlertsContextProvider } from "@features/notifications";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Web3Provider } from "@features/web3";
import { FVMarketplaceContractProvider } from "@features/fvmarketplace";
import { FVTokenContractProvider } from "@features/fvtoken";

// Basic Override Colors
// Later on we can do themeing properly
const theme = createTheme({
    palette: {
        primary: {
            main: "#2363F2"
        },
        secondary: {
            main: "#fff"
        },
        background: {
            default: "#e6e6e6",
            neutral: "black",
            secondary: "#1d242d",
            error: "error",
            success: "success"
        },
        neutral: {
            main: "#000000"
        },
        text: {
            white: "#ffffff",
            black: "#000000",
            grey: "#F5F5F5",
            darkGrey: "#111111",
            footer: "#26303e"
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <AuthenticationContextProvider>
                            <Web3Provider>
                                <FVMarketplaceContractProvider>
                                    <FVTokenContractProvider>
                                        <AlertsContextProvider>
                                            <App />
                                        </AlertsContextProvider>
                                    </FVTokenContractProvider>
                                </FVMarketplaceContractProvider>
                            </Web3Provider>
                        </AuthenticationContextProvider>
                    </LocalizationProvider>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
