import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Grid,
    Box,
    TableContainer,
    Table,
    TableBody,
    CardMedia,
    CircularProgress,
    Stack
} from "@mui/material";
import { withStyles } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import { ReactComponent as PanoramaIcon } from "./img/PanoramaIcon.svg";
import { ReactComponent as DeleteIcon } from "./img/deleteIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import { Cards, Button, Tables, Wrappers, Gallery } from "@features/components";

import { generateRandomString } from "@app/utils/generateRandomString";
import {
    useGetLazyTokenQuery,
    useGetAllImagesQuery,
    useLazyGetPolicyQuery,
    useUploadToAWSMutation,
    useSaveImageMutation,
    useDeleteImageMutation
} from "@app/services";
import config from "@app/config";
import FormSection from "./form";
import MainTitle from "./mainTitle";
import { utils } from "@app/utils";
import TableDetails from "./tableDetails";
import { styles } from "./card.style";
import useDimensions from "../index/hooks/useMobile";
import Dropzone from "react-dropzone";
import Typography from "@features/components/text/typography";
import Slider from "react-slick";
import CustomDropzone from "./CustomDropzone";

const tabsTitles = [
    { label: "Product Info", value: "1" },
    { label: "Purchase Info", value: "2" },
    { label: "Shipping Info", value: "3" }
];

const slideSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4
};

const ImageUploader = ({ classes, setImage }) => {
    const { search } = useLocation();

    const lmuid = useMemo(() => new URLSearchParams(search).get("lmuid"), [search]);
    const [currentImageIndex, setCurrentImageIndex] = useState(-1);
    const [allImages, setAllImages] = useState([]);
    const isImageUploading = useRef(false);
    const mainFileInputRef = useRef(null);
    const secondaryFileInputRef = useRef(null);
    const { isMobile } = useDimensions();
    const { data: tokenData } = useGetLazyTokenQuery({ lmuid }, { skip: !lmuid });
    const { data, isLoading } = useGetAllImagesQuery(lmuid, { skip: !lmuid });
    const [getPolicyTrigger] = useLazyGetPolicyQuery();
    const [uploadToAWSTrigger] = useUploadToAWSMutation();
    const [saveImageTrigger] = useSaveImageMutation();
    const [deleteImageTrigger] = useDeleteImageMutation();

    useEffect(() => {
        if (allImages[currentImageIndex]?.isExternal) {
            setImage(null);
            return;
        }
        let currentImage = allImages[currentImageIndex]?.imagePath;

        if (currentImage) {
            currentImage = `${config.services.card.aws_s3_bucket}${currentImage}`;
        }

        setImage(currentImage);
    }, [allImages, currentImageIndex]);

    useEffect(() => {
        if (!isLoading && tokenData) {
            const externalImage = [
                {
                    imagePath: `https://ipfs.io/ipfs/${tokenData.mediaHash}`,
                    isExternal: true
                }
            ];
            if (!data) {
                setAllImages(externalImage);
            } else {
                setAllImages(externalImage.concat([...data?.data]));
            }
        }
    }, [data, tokenData]);

    const getPolicy = useCallback(() => {
        return getPolicyTrigger()
            .unwrap()
            .then((data) => {
                const policyInfo = data?.data;

                return {
                    policy: policyInfo["policy"],
                    signature: policyInfo["signature"],
                    credential: policyInfo["credential"],
                    awsAccessKeyId: policyInfo["awsAccessKeyId"],
                    date: policyInfo["date"]
                };
            });
    }, [getPolicyTrigger]);

    const saveImage = useCallback(
        (fileName) => {
            saveImageTrigger({
                data: {
                    image: fileName
                },
                cardId: lmuid
            })
                .unwrap()
                .finally(() => {
                    isImageUploading.current = false;
                });
        },
        [saveImageTrigger]
    );

    const uploadToAWS = useCallback(
        (policyInfo, file) => {
            const regex = /(?:\.([^.]+))?$/;
            const fileExtension = regex.exec(file.name)[1];
            const IMG_EXTENSIONS = ["png", "jpg", "jpeg"];

            if (IMG_EXTENSIONS.includes(fileExtension)) {
                const newFileName = `${generateRandomString(10)}.${fileExtension}`;
                const formData = new FormData();

                formData.append("key", "tmp/" + newFileName);
                formData.append("acl", "private");
                formData.append("Content-Type", "image/jpeg");
                formData.append("x-amz-credential", policyInfo.credential);
                formData.append("x-amz-algorithm", "AWS4-HMAC-SHA256");
                formData.append("success_action_status", "201");
                formData.append("x-amz-date", policyInfo.date);
                formData.append("policy", policyInfo.policy);
                formData.append("x-amz-signature", policyInfo.signature);
                formData.append("x-amz-expires", 60);
                formData.append("file", file);

                uploadToAWSTrigger(formData)
                    .unwrap()
                    .catch(() => saveImage(newFileName)); //TODO: fix the error by adding cors to the response
            }
        },
        [uploadToAWSTrigger]
    );

    const deleteImage = useCallback(
        (uuid, imagePath) => {
            const fileName = imagePath.split("/")[3];

            deleteImageTrigger({
                data: {
                    image: fileName
                },
                cardId: lmuid,
                uuid
            })
                .unwrap()
                .then(() => {
                    setCurrentImageIndex((prevIndex) => prevIndex - 1);
                });
        },
        [deleteImageTrigger]
    );

    const addImage = async (file) => {
        if (!isImageUploading.current) {
            isImageUploading.current = true;
            const policy = await getPolicy();
            uploadToAWS(policy, file);
        }
    };

    const secondaryImages = useMemo(() => {
        if (allImages.length <= 2) return [];
        return allImages.slice(2);
    }, [allImages]);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            {allImages?.length > 1 && (
                <Grid container justifyContent='space-between' mt={2}>
                    <Box item xs={12} lg={6} md={6} margin="auto">
                        <Grid container gap={1}>
                            {!isLoading && (
                                <Stack alignItems="center" sx={{maxWidth: {xs: '300px', md: '622px'}, minWidth: 'fit-content'}} direction={{xs: "column", md: "row"}} spacing={2}>
                                    {/* {tokenData && (
                                            <Grid className={classes.cardMedia} item>
                                                <CardMedia
                                                    component='img'
                                                    style={{ borderRadius: "8px", height: "100%" }}
                                                    image={tokenData?.metadata.media}
                                                    alt='image item'
                                                    onClick={() => {
                                                        setCurrentImageIndex(0);
                                                    }}
                                                />
                                            </Grid>
                                        )} */}
                                    <Box className={classes.mainCardMedia} key={"main-image"}>
                                        <Stack height='100%'>
                                            <Box height='100%' sx={{ position: "relative" }}>
                                                <CardMedia
                                                    component='img'
                                                    style={{ borderRadius: "8px", height: "100%" }}
                                                    image={
                                                        config?.services?.card?.aws_s3_bucket +
                                                        allImages[1].imagePath
                                                    }
                                                    alt='image item'
                                                    onClick={() => {
                                                        setCurrentImageIndex(1);
                                                    }}
                                                />
                                                <DeleteIcon
                                                    className={classes.deleteIcon}
                                                    onClick={() =>
                                                        deleteImage(
                                                            allImages[1]?.uuid,
                                                            allImages[1]?.imagePath
                                                        )
                                                    }
                                                />
                                            </Box>
                                            <Box py="4px">
                                                <Typography variant='h6'>Main photo</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Box className={classes.cardMediaContainer}>
                                        {secondaryImages.map(({ imagePath, uuid }, index) => (
                                            <Box className={classes.cardMedia} key={index}>
                                                <CardMedia
                                                    component='img'
                                                    style={{ borderRadius: "8px", height: "100%" }}
                                                    image={
                                                        config?.services?.card?.aws_s3_bucket +
                                                        imagePath
                                                    }
                                                    alt='image item'
                                                    onClick={() => {
                                                        setCurrentImageIndex(index + 1);
                                                    }}
                                                />
                                                <DeleteIcon
                                                    className={classes.deleteIcon}
                                                    onClick={() => deleteImage(uuid, imagePath)}
                                                />
                                            </Box>
                                        ))}
                                        {allImages?.length > 1 && allImages?.length <= 5 && (
                                            <CustomDropzone addImage={addImage} small />
                                        )}
                                    </Box>
                                </Stack>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            )}
            {allImages?.length <= 1 && <CustomDropzone addImage={addImage} />}
        </Wrappers.Container>
    );
};

export default withStyles(styles)(ImageUploader);
