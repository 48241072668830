import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@mui/styles";
import { Elements } from "@stripe/react-stripe-js";

import { Grid, Box, Stack, Link, Divider, CircularProgress } from "@mui/material";

import { Text, Wrappers, Cards } from "@features/components";
import { CART } from "../../pages.constants";
import Typography from "@features/components/text/typography";
import { useDispatch, useSelector } from "react-redux";
import useCardData from "@features/fvtoken/hooks/useCardData";
import { useAuthentication } from "@features/user";
import {
    useCreateCustomerStripeAccountMutation,
    useCreatePaymentIntentMutation
} from "@app/services";
import { styles } from "./checkout.styles";
import { loadStripe } from "@stripe/stripe-js";
import FormContainer from "./formContainer";
import { useGetProductByImuidQuery } from "@app/services";

const stripePromise = loadStripe(
    "pk_test_51MFa6KHYHr5L6asBUEcVTZCH6uBaBVnUYj77DBhszHnFL6o1clobQ1HkbYVPOBqZ0H3bjXEfLIV0tPD2z737UYQz00TRnejNJM"
);

const CheckoutPage = ({ classes }) => {
    const { details } = useAuthentication();
    const dispatch = useDispatch();

    const { cardName, cardDescription, cardPrice, cardImagePath } = useCardData();
    const [shippingPrice, setShippingPrice] = useState(0);

    const lmuid = useSelector((state) => state.card.lmuid);

    const { data: cardData, isLoading: isCardLoading } = useGetProductByImuidQuery(lmuid, {
        skip: !lmuid
    });

    // passing the client secret obtained from the server
    const [options, setOptions] = useState({
        clientSecret: ""
    });
    const userEmail = useSelector((state) => state.user.email);
    const [createPaymentIntentTrigger] = useCreatePaymentIntentMutation();

    const [createCustomerStripe] = useCreateCustomerStripeAccountMutation();

    const createIntent = useCallback(
        (errorHandler) => {
            const body = {
                email: userEmail
            };
            createPaymentIntentTrigger({
                data: body,
                userId: details?.cuid
            })
                .unwrap()
                .then((paymentIntent) => {
                    setOptions({
                        clientSecret: paymentIntent?.data?.clientSecret
                    });
                })
                .catch(() => {
                    if (errorHandler) {
                        errorHandler();
                    }
                });
        },
        [userEmail, details]
    );

    useEffect(() => {
        if (!details?.cuid) return;

        const errorHandler = () => {
            createCustomerStripe({ userId: details?.cuid, email: userEmail })
                .unwrap()
                .then(() => createIntent());
        };

        createIntent(errorHandler);
    }, [userEmail, details]);

    useEffect(() => {
        if (isCardLoading) return;
        setShippingPrice(
            cardData?.data?.money?.internationalShippingPrice +
                cardData?.data?.money?.domesticShippingPrice || 0
        );
    }, [cardData]);

    return (
        <Wrappers.Container classes={{ root: classes.container }}>
            <Text.Typography variant='h2' mt={7} className={classes.mobilePadding}>
                Checkout
            </Text.Typography>
            <Grid container direction='row' spacing={{ xs: 0, sm: 0 }} mb={7}>
                <Grid item xs={12} lg={8} md={8} className={classes.mobilePadding}>
                    {options?.clientSecret ? (
                        <Elements stripe={stripePromise} options={options}>
                            <FormContainer lmuid={lmuid} cardData={cardData} />
                        </Elements>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    height: {
                                        xs: "50vh",
                                        md: "100%"
                                    },
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        </>
                    )}
                </Grid>

                <Grid item xs={12} lg={4} md={4} className={classes.mobile}>
                    <Cards.Curvy classes={{ root: classes.cardCurvy }}>
                        <Stack
                            mb={4}
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Text.Typography variant='h3'>In your bag</Text.Typography>
                            <Link href={CART} sx={{ textDecorationColor: "#111111" }}>
                                <Typography
                                    variant='h5'
                                    sx={{ color: " #111111", fontWeight: "600" }}
                                >
                                    Edit
                                </Typography>
                            </Link>
                        </Stack>

                        <Box>
                            <Stack direction='row' justifyContent='space-between'>
                                <Text.Typography variant='body2'>Subtotal</Text.Typography>
                                <Text.Typography variant='body2'>£{cardPrice}</Text.Typography>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' mt={1}>
                                <Text.Typography variant='body2'>
                                    Shipping & Delivery
                                </Text.Typography>
                                <Text.Typography variant='body2'>£{shippingPrice}</Text.Typography>
                            </Stack>
                        </Box>
                        <Box mb={4} mt={4} pb={1}>
                            <Stack direction='row' justifyContent='space-between' mt={1}>
                                <Text.Typography variant='body2'>Total</Text.Typography>
                                <Text.Typography variant='body2'>
                                    <b>{`£${Number.parseInt(cardPrice) + shippingPrice}`}</b>
                                </Text.Typography>
                            </Stack>
                        </Box>
                        <Divider />
                        <Cards.Product
                            small
                            name={cardName}
                            description={cardDescription}
                            price={cardPrice}
                            imagePath={cardImagePath}
                        />
                    </Cards.Curvy>
                </Grid>
            </Grid>
        </Wrappers.Container>
    );
};

export default withStyles(styles)(CheckoutPage);
