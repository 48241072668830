import React, { useCallback, useEffect, useState } from "react";
import MainNavigation from "../navigations";
import NFTCreateForm from "@features/fvtoken/nftCreateForm";
import {
    Box,
    Button,
    Card,
    CardActions,
    FormGroup,
    FormHelperText,
    Grid,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import LazyMintForm, { validateEmail } from "@features/lazymint/lazymintForm";
import useValidation from "@features/hooks/useValidation";
import { Dialogs } from "@features/components";
import useDialog from "@features/components/dialogs/useDialog";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { useDispatch } from "react-redux";
import { useLazyMintTokenMutation } from "../services/modules/goweb3";

export const NFTDesignerPage = () => {
    const dispatch = useDispatch();
    const [preview, setPreview] = useState();
    const {
        open: addTraitOpen,
        openDialog: openTraitDialog,
        closeDialog: closeTraitDialog
    } = useDialog();
    const [name, setName, nameValid] = useValidation({ minLen: 2, maxLen: 50 });
    const [description, setDescription, descriptionValid] = useValidation({
        minLen: 2,
        maxLen: 200
    });
    const [email, setEmail, emailValid, emailHelperText] = useValidation(
        { minLen: 2, maxLen: 500 },
        validateEmail
    );
    const [file, setFile] = useState();
    const [traits, setTraits] = useState([]);
    const [lazyMint, { data, isSuccess, error }] = useLazyMintTokenMutation();
    const [disabled, setDisabled] = useState(false);

    const addTrait = useCallback(
        (trait_type, value, display_type) => {
            setTraits([
                ...traits,
                {
                    trait_type,
                    value,
                    ...(display_type ? { display_type } : null)
                }
            ]);
        },
        [traits]
    );

    const handleFileSelect = () => {
        const input = document.createElement("input");
        input.hidden = "hidden";
        input.type = "file";
        input.onchange = (e) => setFile(e.target.files[0]);
        input.click();
    };

    const handleAddTrait = ({ trait, value, type }) => {
        if (!traits.map(({ trait_type }) => trait_type).includes(trait)) {
            addTrait(trait, value, type);
        } else {
            dispatch(
                showSnackbarAlert({
                    message: `Trait ${trait} already exists`,
                    severity: "info"
                })
            );
        }
        closeTraitDialog();
    };

    useEffect(() => {
        if (file) {
            return setPreview(URL.createObjectURL(file));
        }
        setPreview();
    }, [file]);

    const handleLazyMint = async () => {
        console.log("uploading image", {
            name,
            description,
            file,
            attributes: traits,
            email
        });
        const form = new FormData();
        form.append("file", file, file.name);
        form.append("name", name);
        form.append("description", description);
        form.append("attributes", traits);
        form.append("email", email);

        setDisabled(true);
        await lazyMint({ form });
        setDisabled(false);

        // Clear out form
        setName("");
        setEmail("");
        setDescription("");
        setEmail("");
        setTraits([]);
        setFile();
    };

    useEffect(() => {
        if (isSuccess && data) {
            console.log("lazy mint success", data);
            dispatch(
                showSnackbarAlert({
                    message: "Success, Please check your emails for to claim your NFT",
                    severity: "success"
                })
            );
        }
    }, [dispatch, data, isSuccess]);

    useEffect(() => {
        if (error) {
            console.error(error);
            dispatch(
                showSnackbarAlert({
                    message: `Sorry, we are unable to mint tokens at the moment`,
                    severity: "error"
                })
            );
        }
    }, [dispatch, error]);

    return (
        <MainNavigation>
            <Grid container spacing={4}>
                <Grid item flexGrow={1} sx={{ display: "flex", justifyContent: "center" }}>
                    <Stack>
                        {preview ? (
                            <Paper sx={{ backgroundColor: "#fff" }}>
                                <img src={preview} width={300} height={300} alt='nft' />
                            </Paper>
                        ) : (
                            <Skeleton variant='rectangular' width={300} height={300} />
                        )}
                        <Button variant='outlined' component='label' onClick={handleFileSelect}>
                            {file ? file.name : "Upload media"}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item flexGrow={7}>
                    <Stack spacing={4}>
                        <TextField
                            sx={{ width: "100%", minWidth: 300 }}
                            label='Name'
                            placeholder='Enter NFT name'
                            value={name}
                            color={!nameValid ? "error" : "success"}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            multiline
                            sx={{ width: "100%", minWidth: 300 }}
                            label='Description'
                            rows={10}
                            placeholder='Describe your NFT'
                            value={description}
                            color={!descriptionValid ? "error" : "success"}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Trait</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Display Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {traits.map(({ trait_type, value, display_type }, x) => (
                                    <TableRow key={`trait_${trait_type}_${x}`}>
                                        <TableCell>{trait_type}</TableCell>
                                        <TableCell>{value}</TableCell>
                                        <TableCell>{display_type}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <CardActions>
                            <Button onClick={openTraitDialog}>Add Trait</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Stack spacing={2}>
                        <FormGroup>
                            <TextField
                                type='email'
                                label='Email'
                                placeholder='Enter your email address'
                                value={email}
                                color={!emailValid ? "error" : "success"}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <FormHelperText>
                                <Typography variant='p' color='error'>
                                    {emailHelperText || ""}
                                </Typography>
                            </FormHelperText>
                        </FormGroup>
                        <Button
                            mt={1}
                            onClick={handleLazyMint}
                            disabled={
                                disabled || !emailValid || !nameValid || !descriptionValid || !file
                            }
                        >
                            Mint
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <Dialogs.AddTrait
                fullWidth
                open={addTraitOpen}
                onClose={closeTraitDialog}
                onSubmit={handleAddTrait}
            />
        </MainNavigation>
    );
};

export const MintTokenPage = () => {
    return (
        <MainNavigation>
            <Box>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ minHeight: "60vh" }}
                >
                    <Grid item xs={12} md={6}>
                        <NFTCreateForm />
                    </Grid>
                </Grid>
            </Box>
        </MainNavigation>
    );
};

export const LazyMintTokenPage = () => {
    return (
        <MainNavigation>
            <Box>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ minHeight: "60vh" }}
                >
                    <Grid item xs={12} md={6}>
                        <LazyMintForm />
                    </Grid>
                </Grid>
            </Box>
        </MainNavigation>
    );
};
