import React, { useEffect, useState } from "react";
import { Button, CardActions, Grid } from "@mui/material";
import { useFVMarketplace } from ".";
import { useDispatch, useSelector } from "react-redux";
import useListings from "./hooks/useListings";
import useNFTs from "../fvtoken/hooks/useNFTs";
import useDialog from "../components/dialogs/useDialog";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import { useWeb3 } from "../web3";
import NFTMarketListingCard, { NFTMarketListingDetailDialog } from "./NFTMarketListingCard";
import { useAuthentication } from "../user";
import { useBuyListingMutation } from "@app/services";
import { Wrappers } from "../components";
import { addLoading, removeLoading } from "@app/app.slice";

function ListingsGallery() {
    const dispatch = useDispatch();
    const { buy } = useFVMarketplace();
    const [lazyBuy] = useBuyListingMutation();

    const { account } = useWeb3();
    const { authenticated, details } = useAuthentication();
    const NFTs = useNFTs();
    const listings = useListings();
    const { open, closeDialog, openDialog, data } = useDialog();
    const [listedNFTs, setListedNTFs] = useState([]);

    const loading = useSelector((state) => state.app.loading);

    useEffect(() => {
        if (NFTs && listings) {
            setListedNTFs(
                NFTs.map((NFTData) => ({
                    NFTData,
                    listingDetails: listings.find(
                        ({ tokenId: listedTokenId }) => listedTokenId === NFTData.tokenId
                    )
                })).filter(({ listingDetails }) => listingDetails)
            );
        }

        return () => setListedNTFs();
    }, [NFTs, listings]);

    const handleBuy = async (type, listingId, lmluid, price) => {
        if (!authenticated) {
            return dispatch(
                showSnackbarAlert({
                    message: "You need to be logged in to buy tokens",
                    severity: "info"
                })
            );
        }

        console.log("buying listing:", { type, listingId, lmluid, price });
        const loadingId = `listing_${lmluid || listingId}`;

        dispatch(addLoading({ id: loadingId }));
        if (type === "Wallet") {
            await buy(listingId, price < 1 ? 1 : price)
                .then((result) => {
                    console.log(result);
                    dispatch(
                        showSnackbarAlert({
                            message: "Successfully brought NFT",
                            severity: "success"
                        })
                    );
                })
                .catch((e) => {
                    console.error(e);
                    if (!account) {
                        return dispatch(
                            showSnackbarAlert({
                                message: "You must connect metamask to buy NFTs",
                                severity: "info"
                            })
                        );
                    }
                    dispatch(
                        showSnackbarAlert({
                            message: "Unable to buy or user rejected transaction",
                            severity: "error"
                        })
                    );
                });
        } else if (details && details.cuid) {
            await lazyBuy({ lmluid, buyer: details.cuid, price }).then((res) => {
                console.log("lazy buy", { res });
            });
        }
        dispatch(removeLoading({ id: loadingId }));
    };

    return (
        <React.Fragment>
            <Grid container p={5} spacing={3} justifyContent='center' paddingBottom={10}>
                {listedNFTs.map(({ listingDetails, NFTData }) => (
                    <Grid
                        item
                        key={`marketplace_listing_${
                            listingDetails.lmluid || listingDetails.listingId
                        }`}
                    >
                        <Wrappers.LoadingBackdrop
                            sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
                            show={loading.includes(
                                `listing_${listingDetails.lmluid || listingDetails.listingId}`
                            )}
                        >
                            <NFTMarketListingCard
                                listingDetails={listingDetails}
                                NFTData={NFTData}
                                onClick={(_, data) => openDialog(data)}
                                actions={({ listingId, lmluid, price, type, isOwner }) =>
                                    !isOwner ? (
                                        <CardActions>
                                            <Button
                                                sx={{ width: "100%" }}
                                                onClick={() =>
                                                    handleBuy(type, listingId, lmluid, price)
                                                }
                                            >
                                                Buy
                                            </Button>
                                        </CardActions>
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </Wrappers.LoadingBackdrop>
                    </Grid>
                ))}
            </Grid>
            <NFTMarketListingDetailDialog {...data} open={open} onClose={closeDialog} fullwidth />
        </React.Fragment>
    );
}

export default ListingsGallery;
