import { Box } from "@mui/material";
import React from "react";
import Typography from "@features/components/text/typography";

function FilterSectionWrapper({ title, children }) {
    return (
        <Box
            sx={{
                borderBottom: "1px solid #DDD",
                p: "40px 48px",
                "@media screen and (max-width: 350px)": {
                    p: "40px 28px"
                }
            }}
        >
            <Typography variant='body2' sx={{ fontWeight: 600, mb: 2 }}>
                {title}
            </Typography>
            {children}
        </Box>
    );
}

export default FilterSectionWrapper;
