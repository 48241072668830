import React from "react";
import { Box, Grid } from "@mui/material";
import MainNavigation from "../../navigations";
import { LazyMintFormWithAttributes } from "@features/lazymint/lazymintForm";

export const LazyMintWithAttributesPage = () => {
    return (
        <MainNavigation>
            <Box>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ minHeight: "60vh" }}
                >
                    <Grid item xs={12} md={6}>
                        <LazyMintFormWithAttributes />
                    </Grid>
                </Grid>
            </Box>
        </MainNavigation>
    );
};

export default LazyMintWithAttributesPage;
