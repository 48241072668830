import { MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const units = [
    "wei",
    "kwei",
    "babbage",
    "femtoether",
    "mwei",
    "lovelace",
    "picoether",
    "gwei",
    "shannon",
    "nanoether",
    "nano",
    "szabo",
    "microether",
    "micro",
    "finney",
    "milliether",
    "milli",
    "ether",
    "kether",
    "grand",
    "mether",
    "gether",
    "tether"
];

function EtherSelection({ onChange }) {
    const [amount, setAmount] = useState("1");
    const [unit, setUnit] = useState(units[1]);

    useEffect(() => {
        if (onChange && typeof onChange === "function") {
            onChange(amount, unit);
        }
    }, [amount, unit, onChange]);

    return (
        <Stack direction='row' spacing={1}>
            <TextField
                label='Amount'
                variant='outlined'
                value={amount}
                type='number'
                placeholder='Enter transaction amount'
                onChange={(e) => setAmount(e.target.value)}
            />
            <Select
                value={unit}
                label='Unit'
                onChange={(e) => setUnit(e.target.value)}
                sx={{ width: "100%" }}
            >
                {units.map((u) => (
                    <MenuItem key={u} value={u}>
                        {u}
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    );
}

export default EtherSelection;
