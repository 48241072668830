import React, { useEffect } from "react";
import { Box, Button, CardActions, CardContent, Stack, TextField } from "@mui/material";
import useValidation from "@features/hooks/useValidation";
import { useNavigate } from "react-router-dom";
import { useLazySendPasswordResetQuery } from "@app/services";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import { useDispatch } from "react-redux";
import { Cards, FormInputs, Text, Wrappers } from "../components";
import Typography from "@features/components/text/typography";
import Wrapper from "@app/pages/card/form/wrapper";

const ForgotPasswordForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail, emailValidated, emailErr] = useValidation({ minLen: 6, maxLen: 500 });

    const [resetPassword, { error }] = useLazySendPasswordResetQuery();

    const handlePasswordReset = () => {
        console.log("resetPassword:", email);
        resetPassword({ email }).then(({ data }) => {
            if (data && data.success) {
                dispatch(
                    showSnackbarAlert({
                        message: "Please check your emails",
                        severity: "info"
                    })
                );
            }
        });
    };

    useEffect(() => {
        if (error) {
            dispatch(
                showSnackbarAlert({
                    message: error.data.message,
                    severity: "error"
                })
            );
        }
    }, [dispatch, error]);

    const switchToLogin = () => {
        navigate("/sign-in");
    };

    return (
        <Wrappers.Container isFullHeight>
            <Box width='100%'>
                <Box>
                    <Typography variant='h3'>Reset password</Typography>
                    <Box is='form'>
                        <Stack spacing={3} my={2}>
                            <FormInputs.InputField
                                fullWidth
                                error={!!emailErr}
                                helperText={emailErr}
                                name='email'
                                autoComplete='email'
                                value={email}
                                label='Email address'
                                placeholder='Enter your email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Stack>
                    </Box>
                </Box>
                <Box>
                    <Stack direction='row' spacing={3} width='100%' justifyContent='space-between'>
                        <Button variant='contained' color='primary' onClick={switchToLogin}>
                            Back to login
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={!emailValidated}
                            onClick={handlePasswordReset}>
                            Send
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Wrappers.Container>
    );
};

export default ForgotPasswordForm;
