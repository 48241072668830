import React, { useState, useEffect } from "react";
import { Button as MUIButton, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { useWeb3 } from "../web3";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";

const defaultButtonState = {
    text: "Connect Wallet",
    disabled: false
};

function WalletConnectButton(props) {
    const dispatch = useDispatch();
    const { account, requestAuthorize } = useWeb3();
    const [connectButton, setConnectButton] = useState(defaultButtonState);

    useEffect(() => {
        if (account) {
            setConnectButton({
                text: account,
                disabled: true
            });
        } else {
            setConnectButton(defaultButtonState);
        }
    }, [account]);

    const handleConnect = (e) => {
        if (!connectButton.disabled) {
            requestAuthorize().catch((e) =>
                dispatch(
                    showSnackbarAlert({
                        message: e.message,
                        severity: "error"
                    })
                )
            );
        } else {
            navigator.clipboard.writeText(connectButton.text);
        }
    };

    return (
        <Button variant='contained' color='success' onClick={handleConnect} {...props}>
            {connectButton.text}
        </Button>
    );
}

const Button = styled(MUIButton)({
    width: 160,
    justifyContent: "start",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block"
});

export default WalletConnectButton;
