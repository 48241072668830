import ipfsAPI from "../../ipfsAPI";

export const ipfs = ipfsAPI.injectEndpoints({
    endpoints: (build) => ({
        uploadFile: build.mutation({
            query: (body) => ({
                method: "post",
                url: "/add",
                body
            })
        }),
        // TODO: pin and unpin should be mutations
        pinFile: build.query({
            query: (cid) => ({
                method: "post",
                url: `/pin?arg=${cid}`
            })
        }),
        unpinFile: build.query({
            query: (cid) => ({
                method: "post",
                url: `/pin/rm?arg=${cid}`
            })
        })
    }),
    overrideExisting: true
});

export const {
    useUploadFileMutation,
    useLazyPinFileQuery,
    usePinFileQuery,
    useLazyUnpinFileQuery,
    useUnpinFileQuery
} = ipfs;
