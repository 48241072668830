import React, { useMemo, useState } from "react";
import {
    CssBaseline,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer,
    Box,
    Menu
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuthentication } from "@features/user";

import { useNavigate, Link, Outlet, useLocation } from "react-router-dom";

import { withStyles } from "@mui/styles";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Wrappers, Cards, Text, Button } from "@features/components";
import { useGetClaimedTokensQuery } from "@app/services";
import LazyNFTCard from "@features/fvtoken/lazyNFTCard";

import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import ProfilePage from "./profile";
import {
    PROFILE_ORDERS,
    EDIT_PROFILE,
    PROFILE_SETTINGS,
    CONNECT_STRIPE,
    PROFILE
} from "../../pages.constants";
import { maxWidth } from "@mui/system";
const drawerWidth = 240;

const styles = (theme) => ({
    drawerPaper: {
        // width: 250,
        // overflow: "auto",
        [theme.breakpoints.up("md")]: {
            overflow: "auto",
            width: drawerWidth,
            position: "relative",
            height: "100%"
        }
    },
    popoverPaper: {
        width: "100%",
        maxWidth: "100%",
        marginLeft: "-16px"
    },
    activeMenuItem: {
        background: "#F5F5F5 !important"
    }
});

const DashboardPage = ({ classes }) => {
    const [currentItem, setCurrentItem] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const auth = useAuthentication();
    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = useMemo(() => {
        const url = location.pathname.split("/");
        return url[url.length - 1];
    }, [location]);
    const itemList = [
        {
            text: "Orders",
            path: PROFILE_ORDERS
        },
        // {
        //    text: "Edit Profile",
        //    path: EDIT_PROFILE
        // },
        {
            text: "Account settings",
            path: PROFILE_SETTINGS
        }
        // {
        //     text: "Connect Stripe",
        //     path: CONNECT_STRIPE
        // }
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    ``;
    return (
        <>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Drawer
                    variant='permanent'
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <List sx={{ pt: 0 }}>
                        <ListItem
                            sx={{
                                background: "#111",
                                height: "72px",
                                px: 4.3,
                                py: 2.5
                            }}
                        >
                            <Typography variant='h5' color='#fff'>
                                My account
                            </Typography>
                        </ListItem>
                        {itemList.map((item, id) => {
                            const { text, path } = item;
                            return (
                                <ListItemButton
                                    key={text}
                                    component={Link}
                                    to={path}
                                    className={path === currentUrl ? classes.activeMenuItem : null}
                                    sx={{ px: 4, py: 2 }}
                                >
                                    <ListItemText>{text}</ListItemText>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Drawer>
                <Box p={1.6} sx={{ width: "100%" }}>
                    <Outlet />
                </Box>
            </Box>
            <Box
                sx={{
                    height: "70px",
                    display: { xs: "block", md: "none" },
                    background: "#D9D9D9"
                }}
            >
                <Box
                    onClick={handleClick}
                    aria-controls='customized-button'
                    sx={{
                        height: "70px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        px: 3,
                        cursor: "pointer"
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "17px",
                            fontWeight: "500"
                        }}
                    >
                        My account / {itemList[currentItem].text}
                    </Typography>
                    <KeyboardArrowDownIcon
                        sx={{
                            transition: "0.5s",
                            transform: `rotate(${+Boolean(anchorEl) * 180}deg)`
                        }}
                    />
                </Box>
                <Menu
                    PopoverClasses={{ paper: classes.popoverPaper }}
                    anchorEl={anchorEl}
                    aria-labelledby='customized-button'
                    anchorOrigin={{ vertical: "bottom" }}
                    transformOrigin={{ vertical: "top" }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <List>
                        {itemList.map((item, id) => {
                            const { text, path } = item;
                            return (
                                <ListItemButton
                                    key={text}
                                    component={Link}
                                    to={path}
                                    className={path === currentUrl ? classes.activeMenuItem : null}
                                    onClick={() => setCurrentItem(id)}
                                >
                                    <ListItemText>{text}</ListItemText>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Menu>
                <Box p={1.6} sx={{ width: "100%" }}>
                    <Outlet />
                </Box>
            </Box>
            {/* <p><ProfilePage/></p> */}
        </>
        // <Wrappers.Container>
        //     <Stack spacing={4}>
        //         <Cards.Curvy sx={{ padding: "30px" }}>
        //             <Grid container alignItems='space-between' justifyContent='space-between'>
        //                 <Grid item xs={12} lg={6} md={5}>
        //                     <Stack direction='row' spacing={2}>
        //                         <Box>
        //                             <img src={Photo} alt='userPhoto' width={"100px"} />
        //                         </Box>
        //                         <Stack justifyContent='space-between'>
        //                             <Stack direction='row' spacing={2} alignItems='center'>
        //                                 <Text.Typography variant='h2'>James Newman</Text.Typography>
        //                                 <Button variant='contained' color='primary' size='small'>
        //                                     Follow
        //                                 </Button>
        //                                 <Text.Typography variant='body2'>
        //                                     173 Followers
        //                                 </Text.Typography>
        //                             </Stack>

        //                             <Text.Typography variant='body1'>
        //                                 @jewman <span style={{ color: "#2363F2" }}>(123)</span>
        //                             </Text.Typography>

        //                             <Text.Typography variant='body1'>
        //                                 I love collection luxury goods and showing off my
        //                                 collection.
        //                             </Text.Typography>

        //                             <Stack direction='row' spacing={1}>
        //                                 <Text.Typography variant='h6'>23 Owned</Text.Typography>
        //                                 <Text.Typography variant='h6'>4 For Sale</Text.Typography>
        //                                 <Text.Typography variant='h6'>287 Sold</Text.Typography>
        //                             </Stack>
        //                         </Stack>
        //                     </Stack>
        //                 </Grid>
        //                 <Grid
        //                     item
        //                     xs={12}
        //                     lg={5}
        //                     md={5}
        //                     p={1.5}
        //                     sx={{ backgroundColor: "#fff", borderRadius: "25px", padding: "18px" }}>
        //                     <Text.Typography variant='h6'>Loyalty Score</Text.Typography>
        //                     <Stack direction='row' justifyContent='space-between' mt={1} mb={1}>
        //                         <Text.Typography variant='h6'>66%</Text.Typography>
        //                         <Text.Typography variant='body1'>
        //                             Score is increased by loyalty and engagement.{" "}
        //                             <a href='https://www.google.com/'>Learn more.</a>
        //                         </Text.Typography>
        //                     </Stack>

        //                      <Box>
        //                         <BorderLinearProgress variant='determinate' value={66} />
        //                     </Box>
        //                 </Grid>
        //             </Grid>
        //         </Cards.Curvy>

        //         <Cards.Curvy sx={{ padding: "30px 0 30px 0" }}>
        //             <Text.Typography variant='h2' align='center' mb={3}>
        //                 <BankAccountForm />
        //                 Collection
        //             </Text.Typography>
        //             <Grid container spacing={3} justifyContent='center'>
        //                 {data?.currentData &&
        //                     [...data?.currentData]?.reverse().map((item) => (
        //                         <Grid
        //                             item
        //                             key={`lazy_card_${item.lmuid}`}
        //                             sx={{ justifyContent: "center", alignItems: "center" }}>
        //                             <LazyNFTCard
        //                                 NFTData={{ custodial: item, type: "lazy" }}
        //                                 onClick={() => navigate(`/card?lmuid=${item.lmuid}`)}
        //                             />
        //                         </Grid>
        //                     ))}
        //             </Grid>
        //         </Cards.Curvy>
        //     </Stack>
        // </Wrappers.Container>
    );
};

export default withStyles(styles)(DashboardPage);
