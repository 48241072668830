import React, { useEffect, useState } from "react";
import { useWeb3 } from "../web3";
import useFetchTokenMetadata from "./hooks/useFetchTokenMetadata";
import NFTCard from "./NFTCard";
import NFTCardSkeleton from "./NFTCardSkeleton";
import { useLazyFetchQuery } from "@app/services";

// TODO: ideally we will have a list of brand information such as logo (src).
// It wouldn't be a hardcoded object, but probably retrieved from an api.
export const staticBrands = {
    Nike: {
        src: "https://img.etimg.com/thumb/msid-59738997,width-300,imgsize-20421,resizemode-1/nike.jpg"
    }
    // Rolex: {
    //     src: "https://banner2.cleanpng.com/20180608/rpf/kisspng-rolex-submariner-24-hours-of-daytona-watch-brand-5b1a7ab5322055.0160297115284620052053.jpg"
    // }
};

function LazyNFTCard({ NFTData: lazyNFTData, onLoaded, onClick, children, loading, externalImage, ...props }) {
    const { account } = useWeb3();
    const [fetchToken, { isUninitialized, isLoading, isFetching, error }] = useLazyFetchQuery();
    const [NFTData, setNFTData] = useState();
    // const [brand, setBrand] = useState();
    const loadingStatus = !lazyNFTData || loading || isLoading;
    //
    useEffect(() => {
        if (!isLoading) {
            const image = `${lazyNFTData?.media}`;
            setNFTData(() => ({ ...lazyNFTData, image: image }));
            // const tokenId =
            //     lazyNFTData.type === "Wallet" ? lazyNFTData.tokenId : lazyNFTData.custodial.lmuid;
            // const tokenURI = lazyNFTData?.tokenURI || lazyNFTData.custodial.ipfs;
            // const image = `https://ipfs.io/ipfs/${lazyNFTData?.custodial?.mediaHash}`;
            // setNFTData({ ...lazyNFTData, image: image });
            // fetchToken(cid).then(async (token) => {
            //     console.log(token, 888);
            //     if (!token) return;
            //     const loaded = {
            //         ...token,
            //         ...lazyNFTData
            //     };
            //     setNFTData(loaded);
            //     if (typeof onLoaded === "function") {
            //         onLoaded(loaded);
            //     }
            // });
        }

        // return () => {
        //     setNFTData();
        // };
    }, [lazyNFTData, account]);

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [error]);

    if (loadingStatus) {
        return <NFTCardSkeleton loading={loadingStatus} sx={props.sx} />;
    }
    const findBrandAttribute = NFTData?.attributes && NFTData?.attributes.find(
        (attribute) => attribute.trait_type.toLowerCase() === 'brand');

    return (
        <NFTCard
            onClick={(e) => (onClick ? onClick(e, NFTData) : null)}
            description={findBrandAttribute ? findBrandAttribute.value : ''}
            name={NFTData?.description}
            image={externalImage ? externalImage : NFTData?.image}
            price={NFTData?.price}
            {...props}
        >
            {children}
        </NFTCard>
    );
}

export default LazyNFTCard;
