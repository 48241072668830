import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    message: "",
    severity: "success",
    time: 6000
};

export const snackbarAlertSlice = createSlice({
    name: "snackbarAlert",
    initialState,
    reducers: {
        showSnackbarAlert: (state, { payload }) => {
            state.severity = initialState.severity; // always default to success
            for (const prop of Object.keys(payload)) {
                state[prop] = payload[prop];
            }
            state.open = true;
            console.log("showSnackbarAlert", payload);
        },
        closeSnackbarAlert: (state) => {
            console.log("closeSnackbarAlert");
            state.open = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { showSnackbarAlert, closeSnackbarAlert } = snackbarAlertSlice.actions;

export default snackbarAlertSlice.reducer;
