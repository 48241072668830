export const styles = (theme) => ({
    container: {
        padding: "0 152px",
        [theme.breakpoints.down("md")]: {
            padding: 0
        }
    },
    cardCurvy: {
        padding: "40px",
        minWidth: "fit-content"
    },
    mobilePadding: {
        paddingRight: "56px",

        [theme.breakpoints.down("md")]: {
            padding: "0 4%"
        }
    },
    mobile: {
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    confirmButton: {
        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    cardElementWrapper: {
        width: "100%",
        "& .StripeElement": {
            display: "flex",
            height: "61px",
            alignItems: "center",
            border: "1px solid #ddd",
            borderRadius: "4px",
            padding: "0 8px"
        },

        "& .__PrivateStripeElement": {
            flex: 1
        }
    },
    iframe: {
        border: "none",
        width: "320px",
        height: "650px",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },
    closeButton: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 1
    }
});
