import config from "../config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: config.services.ipfs.apiurl,
    prepareHeaders: (headers) => {
        headers.append("Authorization", config.services.ipfs.auth);
        return headers;
    }
});

export default createApi({
    reducerPath: "ipfsAPI",
    baseQuery,
    endpoints: () => ({})
});
