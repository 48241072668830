export const getDynamicAttributes = (categoryId, formsAttributes) => {
    const attributesIndexes = formsAttributes?.categoryAspectIndex[categoryId]

    return (formsAttributes?.aspects || []).filter((_, index) => {
        if((attributesIndexes || []).includes(index)) return true
        return false
    })
}

export const getCategoryPath = (currentlySelectedCategory, formsAttributes, itemData) => {
    let categoryPath = null;
    const data = formsAttributes?.data

    if (currentlySelectedCategory) {
        categoryPath = currentlySelectedCategory;
    } else if (itemData?.data?.data?.parsedInformation?.categoryPath) {
        categoryPath = itemData?.data?.data?.parsedInformation?.categoryPath;
    } else {
        categoryPath = data?.formTypes?.[formsAttributes?.data?.selectedFormIndex || 0]?.categoryTree?.[0]?.categoryPath;
    }

    return categoryPath
} 

export const getCategoryId = (formsAttributes, categoryPath) => {
    let categoryId = null;

    formsAttributes.formTypes.forEach((type) => {
        if (categoryId) return;

        type.categoryTree.forEach((el) => {
            if (el.categoryPath === categoryPath) {
                categoryId = el.categoryId;
                return;
            }
        });
    });

    if(!categoryId) {
        categoryId = formsAttributes.formTypes[formsAttributes.selectedFormIndex].categoryTree[0].categoryId
    }

    return categoryId
}