import React, { useCallback, useMemo, useState } from "react";
import {
    Drawer,
    Box,
    Toolbar,
    Link,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    AppBar,
    Stack,
    Divider
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuthentication } from "@features/user";
import { useLocation, useNavigate } from "react-router-dom";
import WalletConnectButton from "@features/wallet/walletConnectButton";
import { Wrappers, Button, Text } from "@features/components";
import { useSelector } from "react-redux";
import config from "../config";
import navigation, { NAV_TYPE_BUTTON, NAV_TYPE_SELECT } from "../navigation";
import { HOME_URL, REGISTER, MY_PROFILE, LOGIN, CART, PROFILE_ORDERS } from "../pages.constants";
import { ReactComponent as Profile } from "./profile.svg";
import { useStyles } from "./index.style";
import ConnectEbayButton from "./connectEbayButton";
import DisconnectEbayButton from "./disconnectEbayButton";
import useDimensions from "@app/pages/index/hooks/useMobile";

function MainNavigation({ children }) {
    const navigate = useNavigate();
    const classes = useStyles();
    const { authenticated, logout, details } = useAuthentication();
    const [draw, setDraw] = useState(false);
    const [searchText, setSearchText] = useState("");
    const location = useLocation();
    const { isMobile } = useDimensions();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState();

    const handleClick = (event, id) => {
        setOpen(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen();
        setAnchorEl(null);
    };

    const handleSearch = useCallback(() => {
        if (searchText) {
            navigate(`/filters?t=${searchText}`);
        }
    }, [searchText, navigate]);

    const loading = useSelector((state) => state.app.loading);
    const loginState = useSelector((state) => state.app.navigation);

    const howItWorksButtonWithoutBg = useMemo(() => {
        return location.pathname === HOME_URL;
    }, [location]);

    const isProfilePage = useMemo(() => {
        console.log(location.pathname, MY_PROFILE)
        return location.pathname.includes(MY_PROFILE);
    }, [location]);

    const ebayIntegrated = useMemo(() => {
        if (details?.eBayIntegrated) {
            return true;
        }
        return false;
    }, [details]);

    return (
        <Wrappers.LoadingBackdrop loaderFixedToScreen>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    // color='background'
                    sx={{
                        boxShadow: "none",
                        position: "inherit",
                        height: "93px",
                        // borderBottom: "1px solid #ddd",
                        background: '#fff'
                    }}>
                    <Toolbar sx={{ height: "100%", justifyContent: "center" }}>
                        <Stack display='block' direction='row' sx={{ alignItems: "center" }}>
                            <div onClick={() => navigate(HOME_URL)} style={{ cursor: "pointer" }}>
                                <img
                                    width='116'
                                    alt={config.branding.company}
                                    src={config.branding.logo}
                                />
                            </div>
                        </Stack>
                        {/* <Stack
                            direction='row'
                            sx={{
                                display: {
                                    md: "flex"
                                },
                                ml: {
                                    xs: "52px",
                                    md: 0
                                }
                            }}>
                            {howItWorksButtonWithoutBg && (
                                <Text.Typography variant='body1'>
                                    <Link href='#how-it-works' className={classes.textLink}>
                                        How it works
                                    </Link>
                                </Text.Typography>
                            )}
                        </Stack> */}
                        <Stack
                            direction='row'
                            pr={2}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: {
                                    xs: "none",
                                    md: "flex"
                                },
                                flexGrow: 1,
                                p: 0
                            }}>
                            {/* <input
                                value={searchText}
                                onInput={(e) => setSearchText(e.target.value)}
                                placeholder='Search'
                                className={classes.input}
                            />
                            <IconButton
                                size='large'
                                aria-label='search'
                                className={classes.searchButton}
                                onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton> */}
                        </Stack>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            {config.features.web3.allowMetamask ? <WalletConnectButton /> : ""}
                            <Stack
                                direction='row'
                                spacing={3}
                                sx={{
                                    alignItems: "center",
                                    display: "flex",
                                    marginLeft: {
                                        xs: 0,
                                        md: 8
                                    }
                                }}>
                                {/* <Stack direction='row' spacing={3} sx={{ alignItems: "center" }}>
                                    {!howItWorksButtonWithoutBg && (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            sx={{
                                                display: {
                                                    md: "flex"
                                                },
                                                minWidth: "121px"
                                            }}
                                            classes={{ root: classes.withoutTransition }}
                                            onClick={() => navigate("/#how-it-works")}>
                                            How it works
                                        </Button>
                                    )}
                                    {/* <Button
                                        variant='outlined'
                                        color='secondary'
                                        sx={{
                                            display: {
                                                xs: "none",
                                                md: "flex"
                                            }
                                        }}
                                        onClick={() => navigate(HOME_URL)}>
                                        Browser
                                    </Button> */}
                                    {/* {authenticated ? (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    md: "flex"
                                                }
                                            }}
                                            classes={{ root: classes.withoutTransition }}
                                            onClick={() => logout(`Goodbye, ${details.name}`)}>
                                            Logout
                                        </Button>
                                    ) : (
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    md: "flex"
                                                }
                                            }}
                                            classes={{ root: classes.withoutTransition }}
                                            onClick={() => navigate(REGISTER, loginState)}>
                                            Sign Up
                                        </Button>
                                    )}
                                </Stack> */}
                                <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                                    {/* <IconButton
                                        size='large'
                                        aria-label='search'
                                        sx={{
                                            display: {
                                                md: "none"
                                            }
                                        }}
                                        className={classes.searchButton}>
                                        <SearchIcon />
                                    </IconButton> */}
                                    {/* <Link
                                        className={classes.iconLink}
                                        onClick={() => navigate(CART, loginState)}
                                        sx={{
                                            display: "flex"
                                        }}
                                        component='button'>
                                        <Cart />
                                    </Link> */}
                                    {/* <Link
                                        className={classes.iconLink}
                                        onClick={() => navigate(`${MY_PROFILE}/${PROFILE_ORDERS}`)}
                                        sx={{
                                            display: {
                                                xs: "none",
                                                md: "flex"
                                            }
                                        }}
                                        component='button'>
                                        <Profile />
                                    </Link> */}
                                    {/* <IconButton
                                        size='large'
                                        edge='start'
                                        color='inherit'
                                        aria-label='menu'
                                        sx={{
                                            mr: 2,
                                            display: {
                                                md: "none"
                                            },
                                            ml: {
                                                xs: "-16px !important",
                                                md: 0
                                            }
                                        }}
                                        onClick={() => setDraw(!draw)}>
                                        <MenuIcon />
                                    </IconButton> */}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Drawer
                    anchor='left'
                    open={draw}
                    onClose={() => setDraw(false)}
                    sx={{
                        flexShrink: 0,
                        minWidth: 400,
                        [`& .MuiDrawer-paper`]: { boxSizing: "border-box" }
                    }}>
                    <Toolbar />
                    <List sx={{ minWidth: 200, marginBottom: "auto" }}>
                        {navigation.links
                            .reduce(
                                (a, e) =>
                                    Boolean(e.authorizedOnly) && !authenticated ? a : [...a, e],
                                []
                            )
                            .sort((a, b) =>
                                a.type !== b.type && a.type === NAV_TYPE_BUTTON ? -1 : 0
                            )
                            .map(({ text, type, location, options }, navIndex) => {
                                switch (type) {
                                    case NAV_TYPE_BUTTON:
                                        return (
                                            <ListItem
                                                disablePadding
                                                key={`draw_nav_btn_${navIndex}`}>
                                                <ListItemButton onClick={() => navigate(location)}>
                                                    {text}
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    case NAV_TYPE_SELECT:
                                        return (
                                            <React.Fragment key={`draw_nav_btn_${navIndex}`}>
                                                <Divider />
                                                {options.map(({ text, location }, elemIndex) => (
                                                    <ListItem
                                                        key={`draw_nav_${navIndex}_el_${elemIndex}`}
                                                        disablePadding>
                                                        <ListItemButton
                                                            onClick={() => navigate(location)}>
                                                            {text}
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </React.Fragment>
                                        );
                                    default:
                                        break;
                                }
                                return null;
                            })}
                    </List>
                    <Box sx={{ bgcolor: "background.default" }}>
                        <Divider />
                        <List>
                            {authenticated ? (
                                <React.Fragment>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            onClick={() =>
                                                navigate(`${MY_PROFILE}/${PROFILE_ORDERS}`)
                                            }>
                                            Profile
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding color='primary'>
                                        <ListItemButton
                                            onClick={() => logout(`Goodbye, ${details.name}`)}>
                                            Logout
                                        </ListItemButton>
                                    </ListItem>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(LOGIN, loginState)}>
                                            Login
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            onClick={() => navigate(REGISTER, loginState)}>
                                            Signup
                                        </ListItemButton>
                                    </ListItem>
                                </React.Fragment>
                            )}
                        </List>
                    </Box>
                </Drawer>
            </Box>
        </Wrappers.LoadingBackdrop>
    );
}

export default MainNavigation;
