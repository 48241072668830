import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

function ConfirmationDialog({
    open,
    onClose,
    title,
    message,
    cancelText = "No",
    confirmationText = "Yes",
    onConfirm,
    onCancel,
    ...data
}) {
    const handleCancel = () => {
        if (onCancel) onCancel();
    };

    const handleConfirm = () => {
        if (onConfirm) onConfirm(data);
    };

    return (
        <Dialog open={open} keepMounted onClose={onClose} aria-describedby='confirmation-dialog'>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='confirmation-dialog'>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{cancelText}</Button>
                <Button onClick={handleConfirm} color='error'>
                    {confirmationText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;
