import tokenAPI from "../../tokenAPI";

export const tokenService = tokenAPI.injectEndpoints({
    endpoints: (build) => ({
        fetch: build.query({
            query: (cid) => ({
                url: `/${cid}`
            })
        })
    }),
    overrideExisting: true
});

export const { useLazyFetchQuery, useFetchQuery } = tokenService;
