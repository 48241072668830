import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import {
    Dialog,
    Typography,
    Tooltip,
    DialogActions,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Table,
    TableContainer,
    DialogContent,
    DialogTitle,
    Fade,
    Stack,
    Box
} from "@mui/material";
import { Cards, Text, Button } from "../components";
import useNetworkExplorer from "../web3/hooks/useNetworkExplorer";
import { useLazyGetUsernameQuery } from "@app/services";
import { styles } from "./styles";

const IdCard = (props) => {
    return <Box {...props}>id</Box>;
};

function NFTCard({ name, description, image, onClick, classes, blocked, sx, ...props }) {
    return (
        <Cards.ImageId
            onClick={onClick}
            src={image}
            alt={name}
            blocked={blocked}
            sx={sx}
            {...props}
        >
            <Stack className={classes.textBox}>
                <Stack direction='row' justifyContent='space-between'>
                    <Text.Typography
                        variant='h5'
                        className={`${classes.textWrap} ${classes.descriptionText} `}
                    >
                        <Tooltip title={name} arrow>
                            <div className={classes.text}>{description}</div>
                        </Tooltip>
                    </Text.Typography>
                </Stack>
                <Stack
                    direction={{ xs: "row", sm: "row", md: "row" }}
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Text.Typography
                        variant='body1'
                        className={`${classes.textWrap} ${classes.titleText}`}
                    >
                        <Tooltip title={description} arrow>
                            <div className={classes.text}>{name}</div>
                        </Tooltip>
                    </Text.Typography>
                    <IdCard className={classes.box} />
                </Stack>
            </Stack>
        </Cards.ImageId>
    );
}

export function NFTCardDetailDialog({
    name,
    description,
    attributes,
    tokenId,
    address,
    owner,
    custodial,
    type,
    transactionHash,
    children,
    actions,
    open,
    onClose
}) {
    const { transactionHashToEtherscanAddress } = useNetworkExplorer();
    const [realWorldAsset, setRealWorldAsset] = useState();
    const [username, setUsername] = useState();

    const [fetchUsername] = useLazyGetUsernameQuery();

    useEffect(() => {
        if (custodial && custodial.cuid) {
            fetchUsername({ cuid: custodial.cuid }).then(({ data }) => {
                if (data && data.username) {
                    setUsername(data.username);
                }
            });
        } else {
            setUsername(owner);
        }
        return () => {
            setUsername();
        };
    }, [fetchUsername, setUsername, custodial, owner]);

    // TODO: we need a better way to support nft attributes
    // Though a popup dialog is not really the best display for such things.
    //
    // If we eventually at NFT display page, it would be possible to have a more indepth
    // attribute system.
    useEffect(() => {
        setRealWorldAsset();
        if (
            attributes &&
            attributes.find(({ trait_type, value }) => trait_type === "Type" && value === "RWA")
        ) {
            const rwa = attributes.reduce((a, { trait_type, value }) => {
                switch (trait_type) {
                    case "Colorway":
                        a.push({
                            attribute: "Colorway",
                            value
                        });
                        break;
                    case "Retail Price":
                        a.push({
                            attribute: "Retail Price",
                            value
                        });
                        break;
                    case "Release Date":
                        a.push({
                            attribute: "Release Date",
                            value
                        });
                        break;
                    default:
                        break;
                }
                return a;
            }, []);
            setRealWorldAsset(rwa);
        }
    }, [attributes]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle color='primary'>{name}</DialogTitle>
            <DialogContent>
                {description && (
                    <Typography variant='body1' py={2}>
                        {description}
                    </Typography>
                )}
            </DialogContent>
            <Stack spacing={3}>
                {children}
                <TableContainer component='div'>
                    <Table size='small' aria-label='NFT detail table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left' colSpan={2}>
                                    Details
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tokenId && (
                                <TableRow>
                                    <TableCell>TokenID</TableCell>
                                    <TableCell>{tokenId}</TableCell>
                                </TableRow>
                            )}
                            {address && (
                                <TableRow>
                                    <TableCell>Token address</TableCell>
                                    <TableCell>{address}</TableCell>
                                </TableRow>
                            )}
                            {username && (
                                <TableRow>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>{username}</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell>Ownership type</TableCell>
                                <TableCell>{type}</TableCell>
                            </TableRow>
                            {custodial && custodial.custodialWalletId && (
                                <React.Fragment>
                                    <TableRow>
                                        <TableCell>Custodial wallet address</TableCell>
                                        <TableCell>{custodial.custodialWalletId}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {realWorldAsset && (
                    <TableContainer component='div'>
                        <Table size='small' aria-label='NFT attributes table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left' colSpan={2}>
                                        Attributes
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(realWorldAsset || []).map(({ attribute, value }, x) => (
                                    <TableRow key={`attribute_${tokenId}_${x}`}>
                                        <TableCell>{attribute}</TableCell>
                                        <TableCell>{value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Stack>
            <DialogActions>
                {actions}
                {transactionHash && (
                    <Button
                        href={transactionHashToEtherscanAddress(transactionHash)}
                        variant='contained'
                        target='_blank'
                    >
                        View Transaction
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction='down' ref={ref} {...props} />;
});

export default withStyles(styles)(NFTCard);
