import config from "../config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: config.services.auth.baseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const user = getState().user;
        // Add authorization header to the relevant endpoints
        if (
            user &&
            user.token &&
            [
                "verifyAccount",
                "sendAccountVerification",
                "getUserDetails",
                "updateAccount",
                "deleteAccount"
            ].includes(endpoint)
        ) {
            headers.set("Authorization", `Bearer ${user.token}`);
        }

        // Add a header used to set the base return address on the authentication server.
        // Only add X-Email-Return-Address when using a route that send emails, or may send one in the future.
        if (
            [
                "register",
                "verifyAccount",
                "updateAccount",
                "resetPassword",
                "deleteAccount",
                "sendAccountVerification",
                "sendPasswordReset"
            ].includes(endpoint)
        ) {
            headers.set("x-basename", config.server.basePath);
        }

        return headers;
    },
    credentials: "include"
});

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // here you can deal with 401 error
    }
    return result;
};

export default createApi({
    reducerPath: "authAPI",
    baseQuery: baseQueryWithInterceptor,
    tagTypes: ["User", "Username"],
    endpoints: () => ({})
});
