import NFTCard from "@features/fvtoken/NFTCard";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo } from "react";
import Heading from "../heading";
import useDimensions from "../hooks/useMobile";
import { dummyData } from "./dummyData";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        overflow: "hidden",
        marginLeft: "-24px",

        [theme.breakpoints.down("md")]: {
            paddingBottom: "40px",
            marginLeft: "-16px"
        }
    },
    containerHuge: {
        marginLeft: "calc((100vw - 1696px) / -2)"
    },
    wrapper: {
        display: "flex",
        justifyContent: "center"
    }
}));

function CardList() {
    const classes = useStyles();
    const { isMobile } = useDimensions();
    const isHugeScreen = useMediaQuery("(min-width:1728px)");

    const cards = useMemo(
        () =>
            dummyData.map((card, index) => (
                <Box key={index} pt={index % 2 === 1 ? "72px" : 0}>
                    <NFTCard
                        name={card.brand}
                        description={card.description}
                        image={card.image}
                        withoutPadding
                    />
                </Box>
            )),
        []
    );

    return (
        <>
            {/* <Heading
                title='Save Time and Make Money'
                description={
                    <>
                        You don't have to deal with the time-consuming listing process, annoying
                        hagglers or the need to list on multiple platforms - we take care of
                        everything!
                        {!isMobile ? (
                            <>
                                <br />
                                <br />
                            </>
                        ) : (
                            " "
                        )}
                        So you can declutter and make money without having to waste your time.
                    </>
                }
                margin={isMobile ? "20px" : "90px"}
            />
            <Box className={`${classes.container} ${isHugeScreen && classes.containerHuge}`}>
                <Stack direction='row' spacing={2} className={classes.wrapper}>
                    {cards}
                </Stack>
            </Box> */}
        </>
    );
}

export default CardList;
