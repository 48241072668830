import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbarAlert } from "./snackbar.slice";
import Slide from "@mui/material/Slide";
import { useMemo } from "react";

function SlideTransition(props) {
    return <Slide {...props} direction='down' />;
}

function SnackbarAlerts() {
    const dispatch = useDispatch();
    const { open, severity, message } = useSelector((state) => state.snackbarAlert);
    const { showAlertTimeInSeconds } = useSelector((state) => state.preferences.snackbars);

    const close = () => dispatch(closeSnackbarAlert());
    const autoHideDuration = useMemo(() => showAlertTimeInSeconds * 1000, [showAlertTimeInSeconds]);

    return (
        <Snackbar
            TransitionComponent={SlideTransition}
            sx={{ minWidth: "300" }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={close}
        >
            <Alert onClose={close} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarAlerts;
