import React, { Children, cloneElement } from "react";
import { InputLabel, Select, Box } from "@mui/material";
import { Controller } from "react-hook-form";

function ControlledInput({ children, control, name, errors }) {
    return (
        <Controller
            control={control}
            defaultValue={""}
            rules={{
                required: `Please enter the ${name}`
            }}
            render={({ field }) => (
                <>
                    {Children.map(children, (child) =>
                        cloneElement(child, {
                            ...field,
                            helperText: errors?.[`${name}`]?.message
                        })
                    )}
                </>
            )}
            name={`${name}`}
        />
    );
}

export default ControlledInput;
