import api from "../../alertsAPI";

export const alertsAPI = api.injectEndpoints({
    endpoints: (build) => ({
        getAlerts: build.query({
            query: ({ hash }) => ({
                method: "GET",
                url: `/api/v1/nam?hash=${hash}`
            }),
            providesTags: (_result, _err, id) => [{ type: "Alerts", id }]
        }),
        markRead: build.mutation({
            query: ({ auid, hash, read = true }) => ({
                method: "POST",
                url: `/api/v1/nam/mark?hash=${hash}&auid=${auid}&read=${read}`
            }),
            invalidatesTags: ["Alerts"]
        }),
        deleteAlert: build.mutation({
            query: ({ auid, hash }) => ({
                method: "DELETE",
                url: `/api/v1/nam/alert?hash=${hash}&auid=${auid}`
            }),
            invalidatesTags: ["Alerts"]
        })
    }),
    overrideExisting: true
});

export const {
    useGetAlertsQuery,
    useLazyGetAlertsQuery,
    useMarkReadMutation,
    useDeleteAlertMutation
} = alertsAPI;
