import React from "react";
import { Box, Stack } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Text } from "@features/components";

import { styles } from "./card.style";

function MainTitle({ classes, isDesktop }) {
    const navigate = useNavigate();

    return (
        <Stack
            direction='column'
            justifyContent='space-between'
            className={classes.mobilePadding}
            sx={{
                mb: {
                    xs: 0,
                    md: 4
                },
                mt: 5,
                display: {
                    xs: isDesktop ? "none" : "block",
                    md: isDesktop ? "block" : "none"
                }
            }}
        >
            <Box
                display='flex'
                alignItems='center'
                mb={1}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/my-account/my-orders")}
            >
                <KeyboardArrowLeftIcon width='20px' />
                <Text.Typography variant='h5'>Back to profile</Text.Typography>
            </Box>
            <Text.Typography variant='h2'>Edit all details below</Text.Typography>
        </Stack>
    );
}

    export default withStyles(styles)(MainTitle);
