import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    inactivity: 0,
    navigation: undefined,
    loading: []
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setInactiveFor: (state, { payload: { seconds } }) => {
            state.inactivity = seconds;
        },
        setLoginReturn: (state, { payload: { referer } }) => {
            console.log("setLoginReturn", referer);
            state.navigation = {
                state: {
                    referer
                }
            };
        },
        clearLoginReturn: (state) => {
            console.log("clearLoginReturn");
            state.navigation = undefined;
        },
        addLoading: (state, { payload: { id } }) => {
            console.log("addLoading", id);
            state.loading = [...state.loading, id];
        },
        removeLoading: (state, { payload: { id } }) => {
            console.log("removeLoading", id);
            state.loading = state.loading.filter((i) => i !== id);
        }
    }
});

export const { setLoginReturn, clearLoginReturn, addLoading, removeLoading, setInactiveFor } =
    appSlice.actions;

export default appSlice.reducer;
