import React, { useCallback, useEffect, useState } from "react";
import { Box, MenuItem, Select, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";

import { useAuthentication } from "@features/user";
import { Button, FormInputs, Text } from "@features/components";
import FormWrapper from "./wrapper";

import { useUpdateCardMutation } from "@app/services";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";
import { useSaveProductDetailsMutation, useAddListForSaleMutation } from "@app/services";
import { useAddListingMutation, useGetListStatusByIdQuery } from "@app/services";

import { MY_PROFILE, PROFILE_ORDERS } from "../../../pages.constants";
import { styles } from "./styles";

function FormSection(props) {
    const { classes, lmuid } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { details } = useAuthentication();

    const [selectValue, setSelectValue] = useState("new");
    const [cardData, setCardData] = useState();
    const [orderDetails, setOrderDetails] = useState();

    const theme = useTheme();
    const isShippingPriceFit = useMediaQuery(theme.breakpoints.between("900", "995"));

    const [updateCardTrigger, status] = useUpdateCardMutation();
    // Save or update product details
    const [
        updateCardOptions,
        { isError, isLoading, isSuccess: successUpdate, error: unsavedProductError }
    ] = useSaveProductDetailsMutation();

    // Add list for sale. Change status on "list-for-sale"
    const [addListForSale, resp] = useAddListForSaleMutation();

    // list for sale NFT
    const [
        listForSaleNFT,
        {
            isLoading: loadingNFT,
            isError: isErrorNFT,
            error: errorNFT,
            isSuccess: isSuccessNFT,
            data
        }
    ] = useAddListingMutation();

    // get list for sale nft status
    const {
        data: marketListingsStatus,
        isLoading: loadNFT,
        isError: errorToken
    } = useGetListStatusByIdQuery({ lmuid });
    useEffect(() => {
        if (isSuccessNFT || (resp.isSuccess && successUpdate)) {
            navigate(`${MY_PROFILE}/${PROFILE_ORDERS}`);
        }
    });
    const {
        formState: { errors, isDirty, isValid },
        handleSubmit,
        control
    } = useForm({ mode: "onChange" });

    useEffect(() => {
        const cardOptions = JSON.parse(localStorage?.getItem("uid"));

        if (cardOptions) {
            setCardData(() => cardOptions);
        }
    }, []);
    useEffect(() => {
        if (isErrorNFT) {
            dispatch(
                showSnackbarAlert({
                    message: errorNFT?.data?.cause,
                    severity: "error"
                })
            );
            return;
        }
    }, [isErrorNFT]);

    useEffect(() => {
        if (orderDetails) {
            updateCardOptions(orderDetails);

            addListForSale({
                cardId: orderDetails?.cardId,
                userId: orderDetails?.userId
            });
        }
    }, [orderDetails]);
    const onSubmit = (data) => {
        const postData = {
            condition: selectValue,
            price: +data.new_price,
            shippingPrice: +data.new_international_price
        };

        const orderData = {
            data: postData,
            cardId: cardData.uuid,
            userId: details?.cuid
        };
        return setOrderDetails(() => orderData);
    };

    const handleChange = useCallback((e) => {
        setSelectValue(e.target.value);
    }, []);

    const listForSale = () => {
        if (orderDetails?.data?.price) {
            const data = {
                lmuid: lmuid,
                price: {
                    value: orderDetails.data.price.toString(),
                    unit: "GBP"
                }
            };
            return listForSaleNFT(data);
        }
    };

    useEffect(() => {
        if (isError) {
            dispatch(
                showSnackbarAlert({
                    message: unsavedProductError?.data?.message,
                    severity: "error"
                })
            );
            return;
        }
    }, [isError]);

    useEffect(() => {
        if (resp.isSuccess) {
            dispatch(
                showSnackbarAlert({
                    message: "Saved data",
                    severity: "success"
                })
            );

            if (!marketListingsStatus) listForSale();
            return;
        }
    }, [resp.isSuccess]);

    const getResponsiveStyle = useCallback(
        (firstOption, secondOption) => {
            if (isShippingPriceFit) {
                return firstOption;
            }

            return {
                xs: firstOption,
                sm: secondOption
            };
        },
        [isShippingPriceFit]
    );

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormWrapper title='General details'>
                    <Text.Typography variant='h5'>Condition</Text.Typography>
                    <Select value={selectValue} onChange={handleChange} className={classes.input}>
                        <MenuItem value='new'>New</MenuItem>
                    </Select>
                </FormWrapper>
                <FormWrapper title='Price & shipping'>
                    <Text.Typography variant='h5'>Price</Text.Typography>
                    <Controller
                        control={control}
                        defaultValue={cardData?.money?.itemPrice}
                        rules={{
                            required: "Please enter a valid price"
                        }}
                        render={({ field }) => (
                            <FormInputs.InputField
                                {...field}
                                type='number'
                                name='new_price'
                                variant='outlined'
                                sx={{
                                    width: "100%"
                                }}
                                helperText={errors?.new_price?.message}
                                placeholder={cardData?.money?.itemPrice || ""}
                                inputProps={{
                                    className: classes.input
                                }}
                            />
                        )}
                        name='new_price'
                    />
                    <Stack
                        direction={getResponsiveStyle("column", "row")}
                        spacing={1}
                        sx={{ mt: 4 }}
                    >
                        <Box sx={{ width: getResponsiveStyle("100%", "50%") }}>
                            <Text.Typography variant='h5'>Domestic shipping price</Text.Typography>
                            <Controller
                                control={control}
                                defaultValue={cardData?.money?.domesticShippingPrice || ""}
                                rules={{
                                    required: "Please enter domestic shipping price"
                                }}
                                render={({ field }) => (
                                    <FormInputs.InputField
                                        {...field}
                                        type='number'
                                        helperText={errors?.new_domnestic_price?.message}
                                        name='new_domnestic_price'
                                        variant='outlined'
                                        placeholder={cardData?.money?.domesticShippingPrice || ""}
                                        sx={{
                                            width: "100%"
                                        }}
                                        inputProps={{
                                            className: classes.input
                                        }}
                                    />
                                )}
                                name='new_domnestic_price'
                            />
                        </Box>
                        <Box sx={{ width: getResponsiveStyle("100%", "50%") }}>
                            <Text.Typography variant='h5'>
                                International shipping price
                            </Text.Typography>
                            <Controller
                                control={control}
                                defaultValue={cardData?.money?.internationalShippingPrice || ""}
                                rules={{
                                    required: "Please enter international shipping price"
                                }}
                                render={({ field }) => (
                                    <FormInputs.InputField
                                        {...field}
                                        type='number'
                                        name='new_international_price'
                                        helperText={errors?.new_international_price?.message}
                                        variant='outlined'
                                        placeholder={cardData?.money?.internationalShippingPrice}
                                        sx={{
                                            width: "100%"
                                        }}
                                        inputProps={{
                                            className: classes.input
                                        }}
                                    />
                                )}
                                name='new_international_price'
                            />
                        </Box>
                    </Stack>
                    <Button
                        onClick={() => {
                            // if (marketListingsStatus) {
                            //     listForSale();
                            // }
                        }}
                        loading={status.isLoading || isLoading || loadingNFT || loadNFT}
                        disabled={!isDirty || !isValid}
                        variant='contained'
                        color='primary'
                        size='large'
                        type='submit'
                        sx={{
                            width: "100%",
                            my: 5
                        }}
                    >
                        Save details
                    </Button>
                </FormWrapper>
            </form>
        </Box>
    );
}

export default withStyles(styles)(FormSection);
