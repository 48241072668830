import { Box, Checkbox, FormControlLabel, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, FormInputs, Gallery } from "@features/components";
import Typography from "@features/components/text/typography";
import UserCard from "@features/components/profile/userCard";
import InfoBox from "./infoBox";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as CheckboxIcon } from "../../filter/img/checkboxIcon.svg";
import { ReactComponent as CheckedIcon } from "../../filter/img/checkedIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useUpdatePasswordMutation } from "@app/services";
import { showSnackbarAlert } from "@features/notifications/popups/snackbar.slice";

const tabsTitles = [
    // { label: "Shipping & Billing", value: "1" },
    { label: "Security", value: "1" }
];

const boxesInfo = [
    {
        title: "Shipping",
        description:
            "Flat 2/B Dolphin Court" +
            "\n" +
            "4 Beach Green" +
            "\n" +
            "Shoreham by Sea" +
            "\n" +
            "West Sussex" +
            "\n" +
            "BN435YG" +
            "\n" +
            "United Kingdom"
    },
    {
        title: "Billing",
        description:
            "Flat 2/B Dolphin Court" +
            "\n" +
            "4 Beach Green" +
            "\n" +
            "Shoreham by Sea" +
            "\n" +
            "West Sussex" +
            "\n" +
            "BN435YG" +
            "\n" +
            "United Kingdom"
    }
];

function ProfileSettings() {
    const [isChecked, setIsChecked] = useState(false);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [updatePassword, { isSuccess, error, isLoading }] = useUpdatePasswordMutation();
    const isBoxesFit = useMediaQuery("(min-width:1300px)");
    const {
        formState: { errors },
        handleSubmit,
        control,
        getValues
    } = useForm({ mode: "onChange" });

    const onSecurityTabSubmit = (data) => {
        updatePassword({
            currentPassword: data.current_password,
            newPassword: data.new_password,
            email: user?.email
        });
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(
                showSnackbarAlert({
                    message: "Password was changed",
                    severity: "success"
                })
            );
        }
    }, [dispatch, isSuccess]);

    useEffect(() => {
        if (error) {
            console.log("error occured during password reset", error);
            let message;
            switch (error.status) {
                case "FETCH_ERROR":
                    message = "Sorry, we are having technically difficulties";
                    break;
                default:
                    message = error.error;
                    break;
            }
            if (error.data) {
                message = error.data.message;
            }
            dispatch(
                showSnackbarAlert({
                    message,
                    severity: "error"
                })
            );
        }
    }, [error, dispatch]);

    return (
        <>
            {/* <UserCard /> */}
            <Box
                mx={{ xs: 1.5, md: 12 }}
                mt={{ xs: 5, md: 9 }}
                sx={{
                    [`& .MuiTabPanel-root`]: {
                        padding: 0
                    }
                }}>
                <Gallery.CardGallery
                    tabsTitles={tabsTitles}
                    sx={{
                        padding: {
                            xs: 0,
                            md: 3
                        }
                    }}>
                    {/* <Box>
                        <Stack
                            direction={isBoxesFit ? "row" : "column"}
                            spacing={2}
                            mt={isBoxesFit ? 0 : 3}>
                            <InfoBox
                                title={boxesInfo[0].title}
                                description={boxesInfo[0].description}
                            />
                            <InfoBox
                                title={boxesInfo[1].title}
                                description={boxesInfo[1].description}
                            />
                        </Stack>
                        <FormControlLabel
                            sx={{ mt: 3 }}
                            control={
                                <Checkbox
                                    checked={isChecked}
                                    onChange={() => setIsChecked((prev) => !prev)}
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckedIcon />}
                                />
                            }
                            label={
                                <Typography variant='h5'>
                                    Billing address is the same as delivery
                                </Typography>
                            }
                        />
                        <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            type='submit'
                            sx={{
                                width: "100%",
                                mt: 5
                            }}>
                            Save details
                        </Button>
                    </Box> */}
                    <Box
                        pt={isBoxesFit ? 6 : 4}
                        sx={{
                            maxWidth: {
                                xs: "none",
                                md: "700px"
                            }
                        }}>
                        <form onSubmit={handleSubmit(onSecurityTabSubmit)}>
                            <Controller
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: "Please enter current password",
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&+=!_]{8,}$/,
                                        message: "Please enter a valid password"
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInputs.InputField
                                        {...field}
                                        name='current_password'
                                        label='Current password'
                                        helperText={errors?.current_password?.message}
                                        placeholder='Please enter current password'
                                        fullwidth={true}
                                    />
                                )}
                                name='current_password'
                            />
                            <Controller
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: "Please enter new password",
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&+=!_]{8,}$/,
                                        message: "Please enter a valid password"
                                    }
                                }}
                                render={({ field }) => (
                                    <FormInputs.InputField
                                        {...field}
                                        name='new_password'
                                        label='New password'
                                        helperText={errors?.new_password?.message}
                                        placeholder='Please enter new password'
                                        fullwidth={true}
                                        mt={2}
                                    />
                                )}
                                name='new_password'
                            />
                            <Controller
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: "Please confirm new password",
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@#$%^&+=!_]{8,}$/,
                                        message: "Please enter a valid password"
                                    },
                                    validate: (value) =>
                                        value === getValues("new_password") ||
                                        "Passwords do not match"
                                }}
                                render={({ field }) => (
                                    <FormInputs.InputField
                                        {...field}
                                        name='confirm_password'
                                        label='Confirm password'
                                        helperText={errors?.confirm_password?.message}
                                        placeholder='Please confirm new password'
                                        fullwidth={true}
                                        mt={2}
                                    />
                                )}
                                name='confirm_password'
                            />
                            <Typography
                                variant='h5'
                                sx={{
                                    color: "#757575",
                                    mt: 3
                                }}>
                                Minimum of 12 characters
                                <br />
                                Uppercase, lowercase letters and one number
                            </Typography>
                            <Button
                                variant='contained'
                                color='primary'
                                size='large'
                                type='submit'
                                loading={isLoading}
                                sx={{
                                    width: "100%",
                                    mt: 5
                                }}>
                                Save details
                            </Button>
                        </form>
                    </Box>
                </Gallery.CardGallery>
            </Box>
        </>
    );
}

export default ProfileSettings;
