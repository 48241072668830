import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { loadStripe } from "@stripe/stripe-js";

import config from "@app/config";

export const stripeApi = createApi({
    reducerPath: "stripeData",
    baseQuery: fetchBaseQuery({
        baseUrl: config.stripe.stripeBaseUrl
    }),
    tagTypes: ["stripeApi"],
    endpoints: (build) => ({
        getUserStripeAccount: build.query({
            query: ({ userId }) => ({
                headers: {
                    userId
                },
                url: `/api/v1/stripe/account`
            })
        }),
        createStripeAccount: build.mutation({
            query: ({ userId, body }) => {
                return {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        userId: userId
                    },
                    url: `/api/v1/stripe/account/create`,
                    body: JSON.stringify(body)
                };
            }
        }),
        sendBankAccount: build.query({
            queryFn: async (body) => {
                const stripePromise = loadStripe(
                    "pk_test_51MFa6KHYHr5L6asBUEcVTZCH6uBaBVnUYj77DBhszHnFL6o1clobQ1HkbYVPOBqZ0H3bjXEfLIV0tPD2z737UYQz00TRnejNJM"
                );

                const response = await (await stripePromise).createToken("bank_account", body);
                return { data: response };
            }
        }),
        createCustomerStripeAccount: build.mutation({
            query: ({ userId, email }) => {
                return {
                    method: "POST",
                    headers: {
                        userId: userId
                    },
                    url: `/api/v1/stripe/customer/create`,
                    body: {
                        email
                    }
                };
            }
        }),
        createPaymentIntent: build.mutation({
            query: (body) => {
                return {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        userId: body.userId
                    },
                    url: `/api/v1/stripe/customer/setup-intent/create`,
                    body: JSON.stringify(body.data)
                };
            }
        })
    })
});

export const {
    useGetUserStripeAccountQuery,
    useCreateStripeAccountMutation,
    useLazySendBankAccountQuery,
    useCreateCustomerStripeAccountMutation,
    useCreatePaymentIntentMutation
} = stripeApi;
