export const styles = (theme) => ({
    container: {
        [theme.breakpoints.down("md")]: {
            marginTop: "40px",
            padding: 0
        }
    },
    gridGallery: {
        minHeight: "inherit",
        height: "100%",
        display: "flex",
        alignItems: "center",
        borderRight: "1px solid #DDDDDD",
        [theme.breakpoints.down("md")]: {
            border: "none",
            margin: "32px 0 56px 0"
        }
    },
    mobilePadding: {
        [theme.breakpoints.down("md")]: {
            padding: "0 4%"
        }
    },
    mobileCard: {
        [theme.breakpoints.down("md")]: {
            width: "344px",
            height: "445px",

            "& img": {
                height: "344px "
            }
        }
    }
});
