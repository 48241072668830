import api from "../../authenticationAPI";

export const authenticationApi = api.injectEndpoints({
    endpoints: (build) => ({
        emailInUse: build.query({
            query: ({ email }) => ({
                method: "POST",
                url: "/api/v1/email/in-use",
                body: {
                    email
                }
            })
        }),
        sendAccountVerification: build.query({
            query: () => ({
                method: "POST",
                url: `/api/v1/email/send-verification`
            })
        }),
        sendPasswordReset: build.query({
            query: ({ email }) => ({
                method: "POST",
                url: `/api/v1/email/send-password-reset`,
                body: {
                    email
                }
            })
        })
    }),
    overrideExisting: true
});

export const {
    useEmailInUseQuery,
    useLazyEmailInUseQuery,
    useSendAccountVerificationQuery,
    useLazySendAccountVerificationQuery,
    useSendPasswordResetQuery,
    useLazySendPasswordResetQuery
} = authenticationApi;
