import React, { Children, useCallback } from "react";
import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "../text/typography";

const styles = (theme) => ({
    textColorPrimary: {
        fontSize: "18px",
        fontWeight: "600",
        textTransform: "capitalize",

        [theme.breakpoints.down("md")]: {
            fontSize: "13px"
        }
    },
    selected: {
        color: "#111111 !important"
    },
    oneTabIndicator: {
        background: "#ffffff"
    },
    indicator: {
        background: "#111111"
    },
    smallTabPanel: {
        padding: "0",
        paddingTop: "24px",
        [theme.breakpoints.down("md")]: {
            padding: "0"
        }
    }
});

function CardGallery(props) {
    const {
        children,
        onClick,
        classes,
        loading,
        tabsTitles,
        oneTab,
        customOnChange,
        ...restProps
    } = props;
    const [value, setValue] = React.useState(oneTab ? 0 : "1");

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (customOnChange) {
            customOnChange(newValue);
        }
    };

    const generateTabs = useCallback(() => {
        const Tabs = [];
        const arrayChildren = Children.toArray(children);
        const getValueForZeroIndex = () => (oneTab ? 0 : "1");

        arrayChildren.map((child, index) => {
            Tabs.push(
                <TabPanel
                    value={!index ? getValueForZeroIndex() : `${index + 1}`}
                    classes={{
                        root: classes.smallTabPanel
                    }}
                    sx={{
                        p: oneTab ? "0 0 24px 0" : null
                    }}
                    key={index}
                >
                    {child}
                </TabPanel>
            );
        });

        return Tabs;
    }, [children]);

    return (
        <Box mt={3} {...restProps}>
            <TabContext value={value} fullWidth>
                <Box sx={{ borderBottom: oneTab ? 0 : 1, borderColor: "divider" }}>
                    <TabList
                        onChange={handleChange}
                        aria-label='product'
                        classes={{
                            indicator: oneTab ? classes.oneTabIndicator : classes.indicator
                        }}
                        sx={{
                            minHeight: oneTab ? 0 : 6
                        }}
                        variant="fullWidth"
                    >
                        {!oneTab ? (
                            tabsTitles.map(({ label, value }) => (
                                <Tab
                                    label={label}
                                    value={value}
                                    key={label}
                                    classes={{
                                        textColorPrimary: classes.textColorPrimary,
                                        selected: classes.selected
                                    }}
                                />
                            ))
                        ) : (
                            <Typography variant='body2' sx={{ mb: 0, fontWeight: 600 }}>
                                {oneTab}
                            </Typography>
                        )}
                    </TabList>
                </Box>
                {generateTabs()}
            </TabContext>
        </Box>
    );
}

export default withStyles(styles)(CardGallery);
