import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

const useDimensions = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    return { isMobile, isTablet };
};

export default useDimensions;
