import React from "react";
import { Stack, FormControlLabel, Checkbox } from "@mui/material";

import { FormInputs, Text } from "@features/components";

const DeliveryField = ({ control, errors }) => {
    return (
        <>
            <Stack
                mt={2.4}
                direction={{ xs: "column", sm: "column", md: "row" }}
                spacing={{ xs: 1.8, sm: 0.2, md: 1 }}
            >
                <FormInputs.ControlledInput control={control} errors={errors} name={"first_name"}>
                    <FormInputs.InputField name='name' label='First name' fullWidth />
                </FormInputs.ControlledInput>
                <FormInputs.ControlledInput control={control} errors={errors} name={"last_name"}>
                    <FormInputs.InputField name='name' label='Last name' fullWidth />
                </FormInputs.ControlledInput>
            </Stack>
            <FormInputs.ControlledInput control={control} errors={errors} name={"first_address"}>
                <FormInputs.InputField name='name' label='Address line 1' fullWidth mt={2.4} />
            </FormInputs.ControlledInput>
            <FormInputs.ControlledInput control={control} errors={errors} name={"second_address"}>
                <FormInputs.InputField name='name' label='Address line 2' fullWidth mt={2.4} />
            </FormInputs.ControlledInput>
            <Stack
                mt={2.4}
                direction={{ xs: "column", sm: "column", md: "row" }}
                spacing={{ xs: 1.8, sm: 0.2, md: 1 }}
            >
                <FormInputs.ControlledInput control={control} errors={errors} name={"city"}>
                    <FormInputs.InputField name='name' label='Town / City' fullWidth />
                </FormInputs.ControlledInput>
                <FormInputs.ControlledInput control={control} errors={errors} name={"postcode"}>
                    <FormInputs.InputField name='name' label='Postcode' fullWidth />
                </FormInputs.ControlledInput>
                <FormInputs.ControlledInput control={control} errors={errors} name={"country"}>
                    <FormInputs.InputField
                        name='name'
                        label='Postcode'
                        fullWidth='Country / Region'
                    />
                </FormInputs.ControlledInput>
            </Stack>
            <FormControlLabel
                mt={2.4}
                sx={{ marginTop: "18px" }}
                control={<Checkbox />}
                label={
                    <Text.Typography variant='h5'>
                        Save these details for future use
                    </Text.Typography>
                }
            />
        </>
    );
};

export default DeliveryField;
