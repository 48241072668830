import { Text } from "@features/components";
import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
    separator: {
        height: "5px",
        width: "77px",
        background: "#004FFF"
    },
    separatorSmall: {
        width: "40px"
    },
    textContainer: {
        [theme.breakpoints.down("sm")]: {
            alignItems: "center"
        }
    },
    text: {
        textAlign: "start",

        [theme.breakpoints.down("sm")]: {
            textAlign: "center"
        }
    },
    title: {
        fontSize: "52px !important",
        fontWeight: 600,

        "@media screen and (max-width: 1455px)": {
            fontSize: "42px !important"
        },

        "@media screen and (max-width: 1160px)": {
            fontSize: "34px !important"
        },

        [theme.breakpoints.down("md")]: {
            fontSize: "28px !important",
            fontWeight: "500 !important"
        }
    }
}));

function Heading(props) {
    const classes = useStyles();
    const { title, description, margin, subheading, leftAlignment, titleClassName, ...rest } =
        props;

    const separator = useMemo(
        () => <Box className={`${classes.separator} ${subheading && classes.separatorSmall}`} />,
        []
    );

    return (
        <Box sx={{ margin: `${margin} auto`, maxWidth: subheading ? "464px" : "970px" }}>
            <Stack alignItems={!leftAlignment && "center"} spacing={4} {...rest}>
                {!subheading && separator}
                <Stack spacing={3} className={classes.textContainer}>
                    <Text.Typography
                        variant='h1'
                        className={`${classes.title} ${titleClassName}`}
                        sx={{
                            textAlign: !leftAlignment && "center"
                        }}
                        {...rest}>
                        {title}
                    </Text.Typography>
                    {subheading && separator}
                    <Text.Typography variant='body2' sx={{ textAlign: !leftAlignment && "center" }}>
                        {description}
                    </Text.Typography>
                </Stack>
            </Stack>
        </Box>
    );
}

export default Heading;
