import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import config from "@app/config";

export const uploadProductApi = createApi({
    reducerPath: "upload-product-data",
    baseQuery: fetchBaseQuery({ baseUrl: config.services.uploadCard.baseUrl }),
    endpoints: (build) => ({
        getProductByImuid: build.query({
            query: (lmuid) => `item/by-lmuid/${lmuid}`
        }),
        saveProductDetails: build.mutation({
            query: (param) => ({
                method: "POST",
                headers: {
                    userId: param.userId
                },
                url: `item/${param.cardId}/update`,
                body: { ...param.data }
            })
        }),
        addListForSale: build.mutation({
            query: (body) => ({
                method: "POST",
                headers: {
                    userId: body.userId
                },
                url: `item/${body.cardId}/list-for-sale`
            })
        })
    })
});

export const {
    useGetProductByImuidQuery,
    useLazyGetProductByImuidQuery,
    useSaveProductDetailsMutation,
    useAddListForSaleMutation
} = uploadProductApi;
