import { CardContent, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { Cards } from "../components";

function NFTCardSkeleton({ src, name, description, hideAction = false, loading, ...props }) {
    return (
        <Cards.ImageId src={src} loading={loading} sx={{ height: "256px" }} {...props}>
            <CardContent
                sx={{
                    p: "0 16px",
                    mt: "-8px"
                }}
            >
                <Stack
                    paddingTop='1.2em'
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        pb: {
                            xs: 1,
                            md: 2
                        }
                    }}
                >
                    {name && name.length > 0 ? (
                        <Typography variant='h6' color='primary'>
                            {name}
                        </Typography>
                    ) : (
                        <Skeleton variant='rounded' animation='pulse' width='60%' />
                    )}
                </Stack>
                {description && description.length > 0 ? (
                    <Typography variant='body2' color='text.secondary' textAlign='center'>
                        {description}
                    </Typography>
                ) : (
                    <Stack
                        spacing={0.5}
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Skeleton variant='rounded' animation='pulse' width='100%' height='.2em' />
                        <Skeleton variant='rounded' animation='pulse' width='100%' height='.2em' />
                    </Stack>
                )}
            </CardContent>
        </Cards.ImageId>
    );
}

export default NFTCardSkeleton;
