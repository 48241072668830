import React, { useState, useRef, useEffect } from "react";
import { TableCell, TableRow, Box, Tooltip, Grid } from "@mui/material";
import { FormInputs } from "../index";
import { withStyles } from "@mui/styles";
import { ReactComponent as EditIcon } from "./edit-icon.svg";
import { ReactComponent as SaveIcon } from "./save-icon.svg";
import { Text } from "..";

const styles = (theme) => ({
    editableRow: {
        borderBottom: "1px solid #DDD",

        [theme.breakpoints.down("md")]: {
            padding: "0 16px"
        }
    },
    error: {
        borderBottom: "1px solid red"
    },
    root: {
        padding: "16px 32px 16px 0px",

        [theme.breakpoints.down("md")]: {
            paddingRight: "16px"
        }
    },
    firstColumn: {
        maxWidth: "180px",
        fontWeight: 600,

        [theme.breakpoints.down("350")]: {
            minWidth: "fit-content"
        }
    },
    lastColumn: {
        padding: "16px 0",
        display: "flex",
        justifyContent: "end"
    },
    editIcon: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        paddingTop: "0 !important"
    }
});

function EditableRow(props) {
    const {
        classes,
        secondColumnCell,
        firstColumnCell,
        type,
        handleClick,
        emptyOnEdit,
        isPrice,
        error,
        resetError,
        wrapText
    } = props;

    const [value, setValue] = useState(secondColumnCell);
    const [editableDivValue, setEditableDivValue] = useState(secondColumnCell)
    const [editableStatus, setEditableStatus] = useState(false);

    const updateProductOption = () => {
        setEditableStatus(false);

        if (value && value !== "No value") {
            resetError(firstColumnCell);
        }

        handleClick(type, value.replace("£", ""));

        if (value === "") {
            setValue("No value");
        }
    };

    const toggleEditableStatus = (status) => {
        if (emptyOnEdit && status === true) {
            setValue("");
        } else {
            setValue((prevValue) => prevValue.replace("£", ""));
        }
        setEditableDivValue(value)
        setEditableStatus(status);
    };

    useEffect(() => {
        setValue(secondColumnCell);
    }, [secondColumnCell]);

    useEffect(() => {
        if(!wrapText || !editableStatus) return

        const editableDiv = document.querySelector('#editable-div')

        const editValue = (e) => {
            setValue(e.target.innerText)
        }

        editableDiv.addEventListener('input', editValue)

        return () => {
            editableDiv.removeEventListener('input', editValue)
        }
    }, [wrapText, editableStatus])

    return (
        <Grid
            container
            key={type}
            className={`${classes.editableRow} ${error ? classes.error : ""}`}>
            <Grid item xs={4} classes={{ root: classes.root }}>
                <Text.Typography variant='body2' className={classes.firstColumn}>
                    {firstColumnCell}
                </Text.Typography>
            </Grid>
            <Grid item xs={6} md={7} classes={{ root: classes.root }}>
                {editableStatus ? (
                    <>
                        {wrapText ? (
                            <div
                                id='editable-div'
                                contentEditable
                                style={{
                                    height: "fit-content",
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "18px",
                                    border: '1px solid #ddd',
                                    padding: "0 16px"
                                }}>
                                {editableDivValue}
                            </div>
                        ) : (
                            <FormInputs.InputField
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                style={{
                                    height: "30px",
                                    borderRadius: "8px",
                                    width: "100%",
                                    fontSize: "18px"
                                }}
                            />
                        )}
                    </>
                ) : (
                    <Text.Typography variant='body2' className={classes.secondColumn}>
                        <Tooltip title={value} arrow>
                            <div
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}>
                                {isPrice && value !== "No value" ? "£" : ""}
                                {value}
                            </div>
                        </Tooltip>
                    </Text.Typography>
                )}
            </Grid>
            <Grid item xs={2} md={1} classes={{ root: classes.lastColumn }}>
                {editableStatus ? (
                    <Box className={classes.editIcon}>
                        <Text.Typography
                            variant='body2'
                            onClick={() => updateProductOption()}
                            sx={{ mr: 1, textDecoration: "underline" }}>
                            save
                        </Text.Typography>
                        <SaveIcon onClick={() => updateProductOption()} />
                    </Box>
                ) : (
                    <Box
                        style={{ cursor: "pointer", ml: 5, paddingLeft: "50px", cursor: "pointer" }}
                        className={classes.editIcon}
                        onClick={() => toggleEditableStatus(true)}>
                        <EditIcon />
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(EditableRow);
