import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo } from "react";
import Heading from "../heading";
import useDimensions from "../hooks/useMobile";
import StepItem from "./stepItem";
import { stepsData } from "./stepsData";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: "46px !important",

        "@media screen and (max-width: 1455px)": {
            fontSize: "42px !important"
        },

        "@media screen and (max-width: 1160px)": {
            fontSize: "34px !important"
        },

        [theme.breakpoints.down("md")]: {
            fontSize: "28px !important"
        }
    }
}));

function StepList() {
    const classes = useStyles();
    const { isMobile } = useDimensions();
    let location = useLocation();
    const [hashElement, setHashElement] = useState(null);

    useEffect(() => {
        let hash = location.hash;
        const removeHashCharacter = (str) => {
            const result = str.slice(1);
            return result;
        };

        if (hash) {
            let element = document.getElementById(removeHashCharacter(hash));
            setHashElement(element);
        }
    }, [location]);

    useEffect(() => {
        if (hashElement) {
            hashElement.scrollIntoView({
                behavior: "smooth",
                // block: "end",
                inline: "nearest"
            });
        }
    }, [hashElement]);

    const steps = useMemo(
        () =>
            stepsData.map((step, index) => (
                <StepItem
                    key={index}
                    title={step.title}
                    description={step.description}
                    image={isMobile ? step.imageMobile || step.image : step.image}
                    step={step.step}
                    reversed={index % 2 === 1}
                />
            )),
        [isMobile]
    );

    return (
        <>
            {/* <span id='how-it-works'>
                <Heading
                    title={
                        <>Unlimitid does all the hard work {!isMobile ? <br /> : " "} in seconds</>
                    }
                    description={
                        <>
                            Start selling immediately by messaging us on WhatsApp now!
                            {!isMobile ? (
                                <>
                                    <br />
                                    <br />
                                </>
                            ) : (
                                " "
                            )}
                            Plus, we're streamlining your resale journey with our innovative
                            purchase tracking system. Soon, you'll be able to list items directly
                            through this feature by following the steps below.
                        </>
                    }
                    margin={isMobile ? "40px" : "150px"}
                    titleClassName={classes.title}
                />
            </span>
            <Stack spacing={isMobile ? 4 : 21}>{steps}</Stack> */}
        </>
    );
}

export default StepList;
