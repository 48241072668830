import React, { useState, useRef, useMemo } from "react";
import { TableCell, TableRow, Box, Tooltip, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import OptionsListItem from "./OptionsListItem";

const styles = () => ({
    root: {
        maxHeight: 160,
        overflowY: "auto"
    }
});

function OptionsList(props) {
    const { classes, options, onSelect } = props;

    const items = useMemo(() => {
        return options.map((el, index) => (
            <OptionsListItem key={index} option={el} onSelect={onSelect} />
        ));
    }, [options, onSelect]);

    return (
        <Grid container>
            <Grid item xs={4} />
            <Grid item xs={6} mt={-2} className={classes.root}>
                {items}
            </Grid>
            <Grid item xs={2} />
        </Grid>
    );
}

export default withStyles(styles)(OptionsList);
