import React from "react";
import { Container as MuiContainer } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
    root: {
        maxWidth: "1728px",
        margin: "0 auto",
        // minHeight: "95%"
    },
    fullHeight: {
        height: "calc(100vh - 93px)",
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.down("md")]: {
            height: "fit-content"
        }
    }
});

function Container(props) {
    const { classes, children, isFullHeight, color } = props;

    return (
        <MuiContainer
            classes={{ root: classes.root }}
            className={isFullHeight ? classes.fullHeight : null}
            sx={{ background: color }}>
            {children}
        </MuiContainer>
    );
}

export default withStyles(styles)(Container);
