import React, { useEffect } from "react";
import useValidation from "@features/hooks/useValidation";
import { useRegisterMutation } from "@app/services";
import { PasswordField } from "./loginForm";
import { useDispatch } from "react-redux";
import { showSnackbarAlert } from "../notifications/popups/snackbar.slice";
import { useAuthentication } from ".";
import { Box, FormControlLabel, Checkbox, Link } from "@mui/material";
import { Wrappers, Text, FormInputs, Button, Cards } from "@features/components";
import { useCreateCustomerStripeAccountMutation } from "@app/services";

const RegisterForm = () => {
    const dispatch = useDispatch();
    const { login } = useAuthentication();

    const [username, setUsername, usernameValidated, usernameErr] = useValidation({
        minLen: 4,
        maxLen: 100
    });
    const [name, setName, nameValidated, nameErr] = useValidation({ minLen: 2, maxLen: 100 });
    const [email, setEmail, emailValidated, emailErr] = useValidation({ minLen: 6, maxLen: 500 });
    const [password, setPassword, passwordValidated, passwordErr] = useValidation(
        { minLen: 12, maxLen: 32 },
        passwordValidation
    );

    //const [confirmPassword, setConfirmPassword, confirmPasswordValidated, confirmPasswordErr] = useValidation({ minLen: 12, maxLen: 32 }, (v) => passwordMatchValidation(v, password))
    const [createCustomerStripe, { isError }] = useCreateCustomerStripeAccountMutation();

    const [
        register,
        {
            isLoading: isRegistering,
            isSuccess: registered,
            error: registerError,
            data: registerData
        }
    ] = useRegisterMutation();

    const handleRegistration = () => {
        register({ name, username, email, password });
    };

    useEffect(() => {
        if (registered && registerData?.cuid) {
            const data = {
                userId: registerData?.cuid,
                email
            };
            createCustomerStripe(data);
            dispatch(
                showSnackbarAlert({
                    message: `Welcome ${name}`
                })
            );
            login(email, password);
        }
    }, [registered, registerData?.cuid]);

    useEffect(() => {
        if (registerError) {
            console.log("error occured during attempted registration", registerError);
            let message;
            switch (registerError.status) {
                case "FETCH_ERROR":
                    message = "Sorry, we are having technically difficulties";
                    break;
                default:
                    message = registerError.error;
                    break;
            }
            if (registerError?.data) {
                message = registerError?.data?.message;
            }
            dispatch(
                showSnackbarAlert({
                    message,
                    severity: "error"
                })
            );
        }
    }, [registerError, dispatch]);

    return (
        <>
            <Box>
                <FormInputs.InputField
                    name='name'
                    onChange={(e) => setName(e.target.value)}
                    label='Your name'
                    value={name}
                    fullWidth
                    mt={2.4}
                    error={!!nameErr}
                    autoComplete='name'
                    helperText={nameErr}
                />
                <FormInputs.InputField
                    id='email_inpt'
                    name='email'
                    label='Email'
                    error={!!emailErr}
                    fullWidth
                    mt={2.4}
                    helperText={emailErr}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <FormInputs.InputField
                    name='username'
                    label='Username'
                    helperText={usernameErr}
                    error={!!usernameErr}
                    value={username}
                    fullWidth
                    mt={2.4}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <PasswordField
                    name='password'
                    autoComplete='current-password'
                    label='Password'
                    fullWidth
                    mt={2.4}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    helperText={passwordErr}
                />
                <Text.Typography variant='h5' color='gray' mt={1.6}>
                    Minimum of 12 characters
                </Text.Typography>
                <Text.Typography variant='h5' color='gray' mt={0.4} mb={3.2}>
                    Uppercase, lowercase letters and one number
                </Text.Typography>

                <FormControlLabel
                    sx={{ marginBottom: "32px" }}
                    control={<Checkbox />}
                    label={
                        <Text.Typography variant='h5'>
                            I agree to unlimitid’s <Link href='/'>Terms of Use</Link> and{" "}
                            <Link href='/'>Terms of Purchase</Link>.
                        </Text.Typography>
                    }
                />
            </Box>
            <Button
                id='register_btn'
                isLoading={isRegistering}
                disabled={
                    !nameValidated || !emailValidated || !passwordValidated || !usernameValidated
                }
                onClick={handleRegistration}
                fullWidth
                variant='contained'
                color='primary'
                size='large'
            >
                Create your account
            </Button>
        </>
    );
};

export function passwordValidation(a) {
    if (!/[A-Z]{1}/.exec(a)) return "Must contain atleast 1 captial letter";
    if (!/[0-9]{1}/.exec(a)) return "Must contain atleast 1 number";
    if (/['"]{1}/.exec(a)) return "Password cannot contain ' or \"";
    return true;
}

export function passwordMatchValidation(a, b) {
    return a === b || "Passwords do not match";
}

export default RegisterForm;
