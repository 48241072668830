import React, { useCallback, useState } from "react";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import { ReactComponent as CheckboxIcon } from "./img/checkboxIcon.svg";
import { ReactComponent as CheckedIcon } from "./img/checkedIcon.svg";

const styles = {
    checkbox: {
        [`& .MuiFormControlLabel-label`]: {
            fontSize: "14px",
            color: "#111"
        },
        [`& .MuiCheckbox-root`]: {
            // width: "24px",
            // height: "24px",
            // border: "1px solid #ddd"
        },
        [`& .Mui-checked`]: {
            color: "#111"
        }
    }
};

function CheckboxListFilter({ checkboxList, classes }) {
    const [checkedState, setCheckedState] = useState(new Array(checkboxList.length).fill(false));

    const handleChange = useCallback(
        (checkboxIndex) => {
            const updatedCheckedState = checkedState.map((el, index) =>
                index === checkboxIndex ? !el : el
            );
            console.log(checkboxIndex, updatedCheckedState, checkedState);
            setCheckedState(updatedCheckedState);
        },
        [checkedState]
    );

    const generateCheckboxList = useCallback(() => {
        const listElements = [];

        checkboxList.forEach((el, index) => {
            listElements.push(
                <FormControlLabel
                    key={index}
                    label={el}
                    className={classes.checkbox}
                    control={
                        <Checkbox
                            checked={checkedState[index]}
                            onChange={() => handleChange(index)}
                            icon={<CheckboxIcon />}
                            checkedIcon={<CheckedIcon />}
                        />
                    }
                />
            );
        });

        return listElements;
    }, [checkedState]);

    return <Stack>{generateCheckboxList()}</Stack>;
}

export default withStyles(styles)(CheckboxListFilter);
