import { withStyles } from "@mui/styles";
import { styles } from "./card.style";
import Dropzone from "react-dropzone";
import { Button } from "@features/components";
import { Box, Stack } from "@mui/material";
import Typography from "@features/components/text/typography";

const CustomDropzone = ({classes, addImage, small}) => {
    return (
        <Dropzone multiple onDrop={(file) => addImage(file[0])}>
            {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps()} className={`${classes.uploadZone} ${small ? classes.smallUploadZone : ''}`}>
                    <input {...getInputProps()} />
                    <Stack direction='column' spacing={small ? 1 : 2}>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.uploadButton} ${small ? classes.smallUploadButton : ''}`}>
                            <Typography variant='h5'>Upload Images</Typography>
                        </Button>
                        <Typography variant='h5' sx={{textAlign: "center"}}>Upload Images for Resale</Typography>
                    </Stack>
                </Box>
            )}
        </Dropzone>
    );
};

export default withStyles(styles)(CustomDropzone)