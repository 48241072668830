import React from "react";
import { Box, createSvgIcon, SvgIcon, Typography } from "@mui/material";

export const DashIcon = createSvgIcon(
    <path
        stroke='#3F48CC'
        strokeLinecap='square'
        strokeWidth='2.813'
        d='M16.572 2.101l-5.71 3-2.286-3.283-6.669 3.275'
    />,
    "Dash"
);

// Head of a MUI Card with embedded media and floating ID indicator
function DashHeader({ children, variant, color, ...rest }) {
    return (
        <Box {...rest}>
            <Typography
                variant={variant || "h6"}
                color={color || "text.secondary"}
                py={1}
                {...rest}
            >
                {children}
            </Typography>
            <SvgIcon component={DashIcon} />
        </Box>
    );
}

export default DashHeader;
