import { version } from "../../package.json";
import { utils } from "./utils";

const config = {
    version,
    web3: {
        defaultChainID: "5",
        defaultNetworkRPC: "https://goerli.infura.io/v3/fbfe679e20f643e38f07fb524157847b",
        staticNetworkRPC: process.env.REACT_APP_NETWORK_RPC || null,
        contracts: {
            fvtoken: {
                address:
                    process.env.REACT_APP_FVTOKEN_ADDRESS ||
                    "0x65c7322Eb276CA649515183542B7F709a5351EdE"
            },
            fvmarketplace: {
                address:
                    process.env.REACT_APP_FVMARKETPLACE_ADDRESS ||
                    "0x64868EeF1CbdAdC73b47e887f2f492690FC553F9"
            }
        }
    },
    googleAnalyticsId: "G-P90K5BPFNE",
    branding: {
        company: "Unlimitid",
        logo: "/static/img/logos/unlimitid-logo.png",
        footer: {
            legal: `Copyright 2020 - ${utils.date.getCurrentYear()} Unlimitid Ltd, registered in England and Wales with company number 12473990. Registered Office: Acre House, 11/15 William Road, London, United Kingdom, NW1 3ER`
        }
    },
    features: {
        enableMarketplace: process.env.REACT_APP_MARKET_PLACE === "true" || false, // if disabled no marketplace will be loaded or shown.
        enableMinting: process.env.REACT_APP_MARKET_PLACE === "true" || false, // if disabled no minting features will be loaded or shown.
        showRoyalties: process.env.REACT_APP_MARKET_PLACE === "true" || false, // if disabled no royalty page will be shown.
        /*
         * Web2 settings
         * Disable some features such as listing lazy tokens on the marketplace (if enabled)
         */
        web2: {
            allowMint: process.env.REACT_APP_WEB2_MINT === "true" || false,
            marketplace: {
                enableLazyListings: process.env.REACT_APP_WEB2_LAZY_LISTINGS === "true" || false, // if true web2 users can add lazy minted tokens to the marketplace.
                enableCustodialListings:
                    process.env.REACT_APP_WEB2_CUSTODIAL_LISTINGS === "true" || false // if true web2 users can add custodial tokens to the marketplace.
            }
        },
        /*
         * Web3 settings
         * Disable some features such as allow web3 wallets to connect and use the marketplace.
         */
        web3: {
            allowMint: process.env.REACT_APP_WEB3_MINT === "true" || false,
            allowMetamask: process.env.REACT_APP_WEB3_USERS === "true" || false, // if true users will be able to connect their metamask wallet.
            marketplace: {
                allowListing: process.env.REACT_APP_WEB3_LISTINGS === "true" || false // if enabled web3 wallet owners can add their NFTs on the marketplace.
            }
        }
    },
    server: {
        baseUrl: process.env.REACT_APP_SERVER_URL || "https://unlimitid.firmview.co.uk",
        basePath: process.env.REACT_APP_SERVER_BASEPATH || "/",
        nftSale: "https://unlimitid-api.firmview.co.uk"
    },
    services: {
        /*
         * Golang api
         */
        golang: {
            baseurl: process.env.REACT_APP_GOLANG_API_BASEURL || "https://dev-ul-api.unlimitid.io"
        },
        /*
         * Chainlist has a URL that provides a list of all networks
         * We can use this with RTK and cache the results.
         *
         * Then we can use that list to lookup human friendly names for the networkIds
         * Along with other relevent information about the network.
         */
        chainsList: {
            baseurl: "https://chainid.network"
        },
        /*
         * The ipfs is the standard way of providing NFTs with tokenURIs
         * We have a remote test server setup with basic authorization.
         * This server gives us access to the ipfs kudo api without a 3rd party provider.
         * We can use it to upload our NFTs metadata file and media.
         * Then we can pin the files on remote test server so that they remain persistent forever (as long as machine is alive).
         * ipfs will also distribute our files on the p2p (peer to peer) network.
         * However if not pinned by us or another peer
         * the files will eventually be garbage collected.
         */
        ipfs: {
            apiurl: process.env.REACT_APP_IPFS_APIURL || "https://ipfs.firmview.co.uk/api/v0", // API for uploading and pinning files.
            auth: process.env.REACT_APP_IPFS_AUTHENTICATION_HEADER || "Basic cGF1bGQ6cGF1bGQyMDAw", // API authentication header (Authozation: this)
            publicServer: process.env.REACT_APP_IPFS_PUBLIC_SERVER || "https://ipfs.io/ipfs" // Public server used to display files on client.
        },
        auth: {
            baseUrl: process.env.REACT_APP_AUTH_URL || "https://dev-ul-api.unlimitid.io"
        },
        notificationAlerts: {
            baseUrl: process.env.REACT_APP_NAM_URL || "https://next-api.firmview.co.uk"
        },
        card: {
            baseUrl: process.env.REACT_APP_UPLOADER_URL || "https://uploader.unlimitid-api.link/",
            aws_s3_bucket:
                process.env.REACT_APP_UPLOADER_AWS_URL || "https://unlimitid.s3.amazonaws.com/"
        },
        uploadCard: {
            baseUrl: process.env.REACT_APP_UPLOADER_URL || "https://uploader.unlimitid-api.link",
            cardHistory:
                process.env.REACT_APP_AUTH_URL || "https://unlimitid-api.firmview.co.uk/dev/"
        },
        forms: {
            baseUrl: process.env.REACT_APP_FORMS_URL || "https://dev-ul-api.unlimitid.io/api/v1/"
        },
        card: {
            baseUrl: process.env.REACT_APP_UPLOADER_URL || "https://uploader.unlimitid-api.link/",
            aws_s3_bucket:
                process.env.REACT_APP_UPLOADER_AWS_URL || "https://unlimitid.s3.amazonaws.com/"
        }
    },
    stripe: {
        stripeAPIOrderUrl:
            process.env.REACT_APP_API_STRIPE_URL || "https://stripe.unlimitid-api.link/",
        stripeBaseUrl: process.env.REACT_APP_STRIPE_URL || "https://stripe.unlimitid-api.link"
    }
};

export default config;
